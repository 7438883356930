import { PathObject } from 'src/types/paths';

export const bzV2PathPrefix = '/bz-v2';

export const routerBaseName =
  process.env.NODE_ENV === 'production' ? bzV2PathPrefix : process.env.PUBLIC_URL || '/';

export const absoluteRouterBaseName = routerBaseName.startsWith('http')
  ? routerBaseName
  : `${window.location.protocol}//${window.location.host}${routerBaseName}`;

const signUp = {
  root: '/signup',
  segments: {
    enterEmail: '/enter-email',
    emailSent: '/email-sent',
    enterName: '/enter-name',
    enterAccountType: '/enter-account-type',
    enterCompany: '/enter-company',
    enterSubscription: '/enter-subscription',
    enterIBAN: '/enter-iban',
    enterConsent: '/enter-consent',
    verify: '/verify',
    success: '/success',
    validateEmailAddress: '/validate-email-address',
  },
};

const signIn = {
  root: '/sign-in',
  segments: {
    enterEmail: '/enter-email',
    chooseAccount: '/choose-account',
    deregistered: '/deregistered',
    enterVerificationCode: '/enter-verification-code',
    verificationCodeSuccess: '/verification-code-success',
    verificationCodeFailed: '/verification-code-failed',
    enterPincode: '/enter-pincode',
    failedPincode: '/failed-pincode',
    forgotPincode: '/forgot-pincode',
  },
};

const selectDevice = {
  root: '/select-device',
  segments: {
    qr: '/qr',
  },
};

const deviceBinding = {
  root: '/device-binding',
  segments: {
    enter: '/enter',
    process: '/process',
  },
};

const userIdentity = {
  root: '/user-identity',
  segments: {
    identifyIDIN: '/identify-idin',
    methodError: '/method-error',
    success: '/success',
    mismatch: {
      root: '/mismatch',
      segments: {
        support: '/support',
      },
    },
  },
};

const biometrics = {
  root: '/biometrics',
  segments: {
    enter: '/enter',
    confirm: '/confirm',
  },
};

const ais = {
  root: '/ais',
  segments: {
    selectBank: '/select-bank',
    selectBankOption: '/select-bank-option',
    userBankAccounts: '/user-bank-accounts',
    provideConsent: '/provide-consent',
    connectionFailed: '/connection-failed',
    bankConnected: '/bank-connected',
    bankConnectionRequest: '/bank-connection-request',
    renewBankConsent: '/renew-bank-consent',
    verifyLegalRepStatus: '/verify-legal-rep-status',
    verifyUbo: {
      root: '/verify-ubo',
      segments: {
        info: '/info',
        details: '/details',
        overview: '/overview',
      },
    },
    verifyAccountHolder: {
      root: '/verify-account-holder',
      segments: {
        multipleBankAccounts: '/multiple-bank-accounts',
        searchAccountHolder: '/search-account-holder',
        selectAccountHolder: '/select-account-holder',
        selectAccountType: '/select-account-type',
        creatingConnection: '/creating-connection',
      },
    },
    kyc: {
      root: '/kyc',
      segments: {
        connectionSuccessful: '/connection-successful',
      },
    },
    cdd: {
      root: '/cdd',
      segments: {
        processOverview: '/process-overview',
        ubosFromAnotherCompany: '/ubos-from-another-company',
        ubosFromDirector: '/ubos-from-director',
        ubosInProgress: '/ubos-in-progress',
        uboform: '/ubo-form',
        selectAccountType: '/select-account-type',
        provideKvk: '/provide-kvk',
        provideManually: '/provide-kvk-manually',
      },
    },
    bankAccountSuccess: '/connect-accounts-success',
    selectBankAccount: '/select-bank-account',
    transactionList: '/transaction-list',
  },
};

const support = {
  root: '/support',
  segments: {
    requested: '/requested',
  },
};

const raboInvoiceFinancing = {
  root: '/app/rabo-invoice-financing',
  segments: {
    chooseSalesInvoice: '/choose-sales-invoice',
    enterAdministrationKvK: '/enter-requester-kvk',
    enterDebtorKvk: '/enter-debtor-kvk',
    completeRequest: '/complete-request',
    error: '/error',
    success: '/success',
    contactRequest: {
      root: '/contact',
      segments: {
        success: '/success',
      },
    },
  },
};

const hix = {
  root: '/app/hix',
  segments: {
    mainMenu: '/',
    connect: '/connect',
    verifyEmail: '/verify-email',
    verifySms: '/verify-sms',
    selectDomain: '/select-domain',
    successfulConnection: '/success',
  },
};

const orchestrator = {
  root: '/o',
  segments: {
    orchestratorFlowDemo: '/start-flow-demo',
    renewBankConsentDemo: '/renew-bank-consent-demo',
    startFlow: '/start-flow/:flowId',
    finishFlowAction: '/finish-action/:actionId',
    overview: '/overview/:flowId',
    success: '/success/:flowId',
    request: {
      root: '/connection-request',
      segments: {
        create: '/create/:flowId',
        handle: '/handle',
        details: '/details/:requestId',
        status: '/status/:requestId',
        linkExpired: '/link-expired/:requestId',
      },
    },
  },
};

const errorDetail = {
  root: '/error',
};

const miniApp = {
  root: '/app',
  segments: {
    connection: '/connection/:partnerId',
    introduction: '/introduction/:partnerId',
    connect: '/connect/:partnerId',
    configureConnection: '/configure-connection/:partnerId',
    getAccessToAdministrationsResult: '/get-administrations-access-result/:partnerId',
    configureEmailAddresses: '/configure-email-addresses/:partnerId',
    existingConnection: '/existing-connection/:partnerId',
    selectAccountingSystem: '/select-accounting-system',
    contactForm: '/contact/:integration',
    contactIntegrationSuccess: '/contact-integration-success/:integration',
  },
};

const home = {
  root: '/',
};

const logInV1 = {
  root: '/v1/login',
};

const auth = {
  root: '/auth',
};

const embeddedFlow = {
  root: '/embedded-flow/:flowId',
};

const Paths = {
  logInV1,
  home,
  auth,
  signUp,
  signIn,
  selectDevice,
  deviceBinding,
  userIdentity,
  biometrics,
  ais,
  support,
  raboInvoiceFinancing,
  hix,
  orchestrator,
  miniApp,
  errorDetail,
  embeddedFlow,
};

export const pathObjectsCollection = Object.values(Paths) as PathObject[];

export default Paths;
