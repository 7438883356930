import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = {
  [_ in K]?: never;
};
export type Incremental<T> =
  | T
  | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string };
  String: { input: string; output: string };
  Boolean: { input: boolean; output: boolean };
  Int: { input: number; output: number };
  Float: { input: number; output: number };
  AWSDate: { input: string; output: string };
  AWSDateTime: { input: Date; output: Date };
  AWSJSON: { input: string; output: string };
  AWSTimestamp: { input: number; output: number };
};

export type ActionData = {
  __typename?: 'ActionData';
  dependencies?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  description?: Maybe<LocalisedText>;
  id: Scalars['String']['output'];
  isFinished: Scalars['Boolean']['output'];
  requestActions?: Maybe<Array<Scalars['String']['output']>>;
  settings?: Maybe<Scalars['AWSJSON']['output']>;
  shortTitle?: Maybe<LocalisedText>;
  state?: Maybe<Scalars['AWSJSON']['output']>;
  status: FlowStatus;
  title: LocalisedText;
  triggers?: Maybe<ActionTriggers>;
  type: Scalars['String']['output'];
  url: Scalars['String']['output'];
  validationUrl?: Maybe<Scalars['String']['output']>;
};

export type ActionState = {
  state?: InputMaybe<Scalars['AWSJSON']['input']>;
  status?: InputMaybe<FlowStatus>;
};

export type ActionTrigger = {
  __typename?: 'ActionTrigger';
  actionId: Scalars['String']['output'];
  execute: Scalars['Boolean']['output'];
  mutation: Scalars['String']['output'];
};

export type ActionTriggers = {
  __typename?: 'ActionTriggers';
  post: Array<ActionTrigger>;
  pre: Array<ActionTrigger>;
};

export type Address = {
  __typename?: 'Address';
  city: Scalars['String']['output'];
  countrycode: Scalars['String']['output'];
  street: Scalars['String']['output'];
  zipcode: Scalars['String']['output'];
};

export type Administration = {
  __typename?: 'Administration';
  createdAt: Scalars['AWSDateTime']['output'];
  deleted: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  kvkNumber?: Maybe<Scalars['String']['output']>;
  mapped?: Maybe<Scalars['Boolean']['output']>;
  name: Scalars['String']['output'];
  newDocumentCount?: Maybe<Scalars['Int']['output']>;
  partners: Array<Maybe<Partner>>;
  scanEmail?: Maybe<Scalars['String']['output']>;
  sendUbl: Scalars['Boolean']['output'];
  updatedAt: Scalars['AWSDateTime']['output'];
};

export type AdministrationAccessError = NoAccessError & {
  __typename?: 'AdministrationAccessError';
  message: Scalars['String']['output'];
};

export type AdministrationRight = {
  __typename?: 'AdministrationRight';
  administrationId: Scalars['String']['output'];
  hasAdminRight: Scalars['Boolean']['output'];
  userId: Scalars['String']['output'];
};

export type AdministrationUser = {
  __typename?: 'AdministrationUser';
  displayName: Scalars['String']['output'];
  initials?: Maybe<Scalars['String']['output']>;
  lastName?: Maybe<Scalars['String']['output']>;
};

export type AmountFilter = {
  maximum?: InputMaybe<Scalars['Int']['input']>;
  minimum?: InputMaybe<Scalars['Int']['input']>;
};

export enum AndOrCondition {
  And = 'and',
  Or = 'or',
}

export type App = {
  __typename?: 'App';
  appScopes?: Maybe<Scalars['String']['output']>;
  connectionStatus?: Maybe<AppConnectionStatus>;
  description?: Maybe<Scalars['String']['output']>;
  featuredOrder?: Maybe<Scalars['Int']['output']>;
  futureScopes?: Maybe<Scalars['String']['output']>;
  icon?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  installedBy?: Maybe<Scalars['String']['output']>;
  internalName?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  tags?: Maybe<Array<Maybe<AppTag>>>;
  trustedScopes?: Maybe<Scalars['String']['output']>;
  url?: Maybe<Scalars['String']['output']>;
  urlConfiguration?: Maybe<UrlConfiguration>;
};

export enum AppConnectionStatus {
  Connected = 'connected',
  NotConnected = 'notConnected',
  Unknown = 'unknown',
}

export type AppFilter = {
  __typename?: 'AppFilter';
  condition: AndOrCondition;
  countryCode: CountryCodeType;
  tagCodes: Array<TagCodeType>;
};

export type AppTag = {
  __typename?: 'AppTag';
  color?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  logo?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
};

export type AppsResponse = {
  __typename?: 'AppsResponse';
  apps: Array<App>;
};

export type AreBetaMembersResponseItem = {
  __typename?: 'AreBetaMembersResponseItem';
  isBetaMember: Scalars['Boolean']['output'];
  memberId: Scalars['String']['output'];
  memberType: Scalars['String']['output'];
};

export type Attachment = {
  __typename?: 'Attachment';
  contents: Scalars['String']['output'];
  name: Scalars['String']['output'];
};

export type Bank = {
  __typename?: 'Bank';
  connectorGroups: Array<Scalars['String']['output']>;
  icon: Scalars['String']['output'];
  id: Scalars['String']['output'];
  title: Scalars['String']['output'];
};

export type BankAccount = {
  __typename?: 'BankAccount';
  administrationId?: Maybe<Scalars['String']['output']>;
  aisEnabled?: Maybe<Scalars['Boolean']['output']>;
  aisOnboardingUntil?: Maybe<Scalars['AWSDate']['output']>;
  balanceAmount?: Maybe<Scalars['Float']['output']>;
  balanceDate?: Maybe<Scalars['AWSDate']['output']>;
  bank?: Maybe<Connector>;
  companyAccount?: Maybe<Scalars['Boolean']['output']>;
  companyAccountManual?: Maybe<Scalars['Boolean']['output']>;
  companyCity?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['AWSDate']['output']>;
  createdBy?: Maybe<Scalars['String']['output']>;
  currency?: Maybe<Scalars['String']['output']>;
  customerId?: Maybe<Scalars['String']['output']>;
  deleted?: Maybe<Scalars['Boolean']['output']>;
  displayName?: Maybe<Scalars['String']['output']>;
  externalId?: Maybe<Scalars['String']['output']>;
  iban?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  isStaging?: Maybe<Scalars['Boolean']['output']>;
  isZombie: Scalars['Boolean']['output'];
  kvkNumber?: Maybe<Scalars['String']['output']>;
  kvkNumberManual?: Maybe<Scalars['Boolean']['output']>;
  lastBankUpdateAt?: Maybe<Scalars['AWSDate']['output']>;
  paymentLimit?: Maybe<Scalars['Float']['output']>;
  providerAccessId?: Maybe<Scalars['String']['output']>;
  userSuppliedName?: Maybe<Scalars['String']['output']>;
  validUntil?: Maybe<Scalars['AWSDate']['output']>;
  verifiedByUser?: Maybe<Scalars['Boolean']['output']>;
  verifiedByUserAt?: Maybe<Scalars['AWSDate']['output']>;
  verifiedName?: Maybe<Scalars['String']['output']>;
};

export type BankAccountAccessError = NoAccessError & {
  __typename?: 'BankAccountAccessError';
  code: Scalars['String']['output'];
  message: Scalars['String']['output'];
};

export type BankAccountBic = {
  __typename?: 'BankAccountBic';
  active: Scalars['Boolean']['output'];
  bic: Scalars['String']['output'];
  createdAt: Scalars['String']['output'];
  iban: Scalars['String']['output'];
  updatedAt?: Maybe<Scalars['String']['output']>;
};

export type BankAccountInput = {
  currency: Scalars['String']['input'];
  externalId: Scalars['ID']['input'];
  iban: Scalars['String']['input'];
  id: Scalars['ID']['input'];
  pisProviderAccessId?: InputMaybe<Scalars['String']['input']>;
  providerAccessId?: InputMaybe<Scalars['String']['input']>;
};

export type BankBic = {
  __typename?: 'BankBic';
  active: Scalars['Boolean']['output'];
  bankIdentifier: Scalars['String']['output'];
  bic: Scalars['String']['output'];
  createdAt: Scalars['String']['output'];
  updatedAt?: Maybe<Scalars['String']['output']>;
};

export type BankConnector = {
  __typename?: 'BankConnector';
  description?: Maybe<Scalars['String']['output']>;
  groups: Array<Scalars['String']['output']>;
  icon: Scalars['String']['output'];
  id: Scalars['String']['output'];
  pricing?: Maybe<Scalars['Float']['output']>;
  recommended?: Maybe<Scalars['Boolean']['output']>;
  title: Scalars['String']['output'];
};

export type BankConnectorsDetails = {
  __typename?: 'BankConnectorsDetails';
  bankId: Scalars['String']['output'];
  bankName: Scalars['String']['output'];
  connectors: Array<ConnectorDetails>;
  icon: Scalars['String']['output'];
  order: Scalars['Int']['output'];
};

export type BankDetails = {
  __typename?: 'BankDetails';
  bankId: Scalars['String']['output'];
  bankName: Scalars['String']['output'];
  icon: Scalars['String']['output'];
  order: Scalars['AWSJSON']['output'];
};

export type BankTransaction = {
  __typename?: 'BankTransaction';
  amount: Scalars['Float']['output'];
  bankAccountId?: Maybe<Scalars['ID']['output']>;
  bic?: Maybe<Scalars['String']['output']>;
  bookingDate?: Maybe<Scalars['AWSDateTime']['output']>;
  calculatedId?: Maybe<Scalars['String']['output']>;
  correctedByStatementLineId?: Maybe<Scalars['String']['output']>;
  correctionForStatementLineId?: Maybe<Scalars['String']['output']>;
  currency: Scalars['String']['output'];
  date: Scalars['AWSDateTime']['output'];
  description?: Maybe<Scalars['String']['output']>;
  documentId?: Maybe<Scalars['String']['output']>;
  endToEndId?: Maybe<Scalars['String']['output']>;
  iban?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  instructedAmount?: Maybe<Scalars['Float']['output']>;
  instructedAmountCurrency?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  paymentId?: Maybe<Scalars['ID']['output']>;
  paymentInformationId?: Maybe<Scalars['String']['output']>;
  paymentReference?: Maybe<Scalars['String']['output']>;
  position?: Maybe<Scalars['Int']['output']>;
  rawTransaction?: Maybe<Scalars['AWSJSON']['output']>;
  remittanceInformationUnstructured?: Maybe<Scalars['String']['output']>;
  reversalIndicator?: Maybe<Scalars['Boolean']['output']>;
  sequenceNumber?: Maybe<Scalars['Int']['output']>;
  statementId?: Maybe<Scalars['String']['output']>;
};

export type BankTransactionsResponse = {
  __typename?: 'BankTransactionsResponse';
  bankAccount: BankAccount;
  transactions: Array<BankTransaction>;
};

export type BaseError = {
  message: Scalars['String']['output'];
};

export type BetaMemberInput = {
  memberId: Scalars['String']['input'];
  memberType: BetaProgramMemberType;
};

export type BetaProgram = {
  __typename?: 'BetaProgram';
  betaProgramId: Scalars['String']['output'];
  title: Scalars['String']['output'];
};

export enum BetaProgramMemberType {
  Administration = 'administration',
  BankAccount = 'bankAccount',
  Partner = 'partner',
  User = 'user',
}

export enum BillingType {
  Partner = 'partner',
  StandardSubscription = 'standard_subscription',
  User = 'user',
}

export type CheckCompanyCddResponse = {
  __typename?: 'CheckCompanyCddResponse';
  customerId?: Maybe<Scalars['String']['output']>;
  kvkNumber?: Maybe<Scalars['String']['output']>;
};

export type CheckPersonCddResponse = {
  __typename?: 'CheckPersonCddResponse';
  customerId?: Maybe<Scalars['String']['output']>;
};

export type Companies = {
  __typename?: 'Companies';
  items: Array<Maybe<CompanyItem>>;
  itemsPerPage: Scalars['Int']['output'];
  page: Scalars['Int']['output'];
  totalItems: Scalars['Int']['output'];
};

export type CompanyItem = {
  __typename?: 'CompanyItem';
  branchNumber?: Maybe<Scalars['String']['output']>;
  city?: Maybe<Scalars['String']['output']>;
  houseNumber?: Maybe<Scalars['Int']['output']>;
  houseNumberAddition?: Maybe<Scalars['String']['output']>;
  kvkNumber: Scalars['String']['output'];
  links: Array<Maybe<CompanyItemLink>>;
  postalCode?: Maybe<Scalars['String']['output']>;
  street?: Maybe<Scalars['String']['output']>;
  tradeName: Scalars['String']['output'];
  type: Scalars['String']['output'];
};

export type CompanyItemLink = {
  __typename?: 'CompanyItemLink';
  href?: Maybe<Scalars['String']['output']>;
  rel?: Maybe<Scalars['String']['output']>;
};

export type ConnectAccountInput = {
  bankAccountId?: InputMaybe<Scalars['String']['input']>;
  betaTester?: InputMaybe<Scalars['Boolean']['input']>;
  clientIp?: InputMaybe<Scalars['String']['input']>;
  code: Scalars['String']['input'];
  options?: InputMaybe<Scalars['AWSJSON']['input']>;
  orchestratorRequestId?: InputMaybe<Scalars['String']['input']>;
  scope?: InputMaybe<Scalars['String']['input']>;
  state: Scalars['String']['input'];
  userId: Scalars['String']['input'];
};

export type ConnectAccountResponse = {
  __typename?: 'ConnectAccountResponse';
  bank_account_ids: Array<Scalars['String']['output']>;
  redirect_uri?: Maybe<Scalars['String']['output']>;
  skipped_bank_accounts: Scalars['Int']['output'];
  unexpected_ibans?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
};

export enum ConnectAccountScope {
  Ais = 'AIS',
  Pis = 'PIS',
}

export enum Connector {
  Abn = 'abn',
  Asn = 'asn',
  AsnCommercial = 'asn_commercial',
  Bunq = 'bunq',
  Ing = 'ing',
  IngWb = 'ing_wb',
  Knab = 'knab',
  Openapi = 'openapi',
  Rabo = 'rabo',
  RaboCommercial = 'rabo_commercial',
  RaboRdc = 'rabo_rdc',
  Regio = 'regio',
  RegioCommercial = 'regio_commercial',
  Sns = 'sns',
  SnsCommercial = 'sns_commercial',
  Triodos = 'triodos',
}

export type ConnectorDetails = {
  __typename?: 'ConnectorDetails';
  bankId: Scalars['String']['output'];
  connectorId: Connector;
  connectorName: Scalars['String']['output'];
  connectorType: Scalars['String']['output'];
  country: Array<Scalars['String']['output']>;
  descriptionKey?: Maybe<Scalars['String']['output']>;
  icon: Scalars['String']['output'];
  meteringPlan: Scalars['String']['output'];
  paymentTypes: Array<Scalars['String']['output']>;
  recommended?: Maybe<Scalars['Boolean']['output']>;
  tags?: Maybe<Array<Scalars['String']['output']>>;
};

export type ContactForm = {
  __typename?: 'ContactForm';
  missingIntegration?: Maybe<Array<Scalars['String']['output']>>;
};

export type ContactInvoice = {
  amount: Scalars['Float']['input'];
  customerName: Scalars['String']['input'];
  expirationDate: Scalars['AWSDate']['input'];
  invoiceDate: Scalars['AWSDate']['input'];
  invoiceName: Scalars['String']['input'];
  invoiceNumber: Scalars['String']['input'];
  kvkNumber: Scalars['String']['input'];
};

export type CounterAccount = {
  __typename?: 'CounterAccount';
  bankName: Scalars['String']['output'];
  bic: Scalars['String']['output'];
  iban: Scalars['String']['output'];
};

export enum CountryCodeType {
  Ad = 'AD',
  Ae = 'AE',
  Af = 'AF',
  Ag = 'AG',
  Ai = 'AI',
  Al = 'AL',
  Am = 'AM',
  Ao = 'AO',
  Aq = 'AQ',
  Ar = 'AR',
  As = 'AS',
  At = 'AT',
  Au = 'AU',
  Aw = 'AW',
  Ax = 'AX',
  Az = 'AZ',
  Ba = 'BA',
  Bb = 'BB',
  Bd = 'BD',
  Be = 'BE',
  Bf = 'BF',
  Bg = 'BG',
  Bh = 'BH',
  Bi = 'BI',
  Bj = 'BJ',
  Bl = 'BL',
  Bm = 'BM',
  Bn = 'BN',
  Bo = 'BO',
  Bq = 'BQ',
  Br = 'BR',
  Bs = 'BS',
  Bt = 'BT',
  Bv = 'BV',
  Bw = 'BW',
  By = 'BY',
  Bz = 'BZ',
  Ca = 'CA',
  Cc = 'CC',
  Cd = 'CD',
  Cf = 'CF',
  Cg = 'CG',
  Ch = 'CH',
  Ci = 'CI',
  Ck = 'CK',
  Cl = 'CL',
  Cm = 'CM',
  Cn = 'CN',
  Co = 'CO',
  Cr = 'CR',
  Cu = 'CU',
  Cv = 'CV',
  Cw = 'CW',
  Cx = 'CX',
  Cy = 'CY',
  Cz = 'CZ',
  De = 'DE',
  Dj = 'DJ',
  Dk = 'DK',
  Dm = 'DM',
  Do = 'DO',
  Dz = 'DZ',
  Ec = 'EC',
  Ee = 'EE',
  Eg = 'EG',
  Eh = 'EH',
  Er = 'ER',
  Es = 'ES',
  Et = 'ET',
  Fi = 'FI',
  Fj = 'FJ',
  Fk = 'FK',
  Fm = 'FM',
  Fo = 'FO',
  Fr = 'FR',
  Ga = 'GA',
  Gb = 'GB',
  Gd = 'GD',
  Ge = 'GE',
  Gf = 'GF',
  Gg = 'GG',
  Gh = 'GH',
  Gi = 'GI',
  Gl = 'GL',
  Gm = 'GM',
  Gn = 'GN',
  Gp = 'GP',
  Gq = 'GQ',
  Gr = 'GR',
  Gs = 'GS',
  Gt = 'GT',
  Gu = 'GU',
  Gw = 'GW',
  Gy = 'GY',
  Hk = 'HK',
  Hm = 'HM',
  Hn = 'HN',
  Hr = 'HR',
  Ht = 'HT',
  Hu = 'HU',
  Id = 'ID',
  Ie = 'IE',
  Il = 'IL',
  Im = 'IM',
  In = 'IN',
  Io = 'IO',
  Iq = 'IQ',
  Ir = 'IR',
  Is = 'IS',
  It = 'IT',
  Je = 'JE',
  Jm = 'JM',
  Jo = 'JO',
  Jp = 'JP',
  Ke = 'KE',
  Kg = 'KG',
  Kh = 'KH',
  Ki = 'KI',
  Km = 'KM',
  Kn = 'KN',
  Kp = 'KP',
  Kr = 'KR',
  Kw = 'KW',
  Ky = 'KY',
  Kz = 'KZ',
  La = 'LA',
  Lb = 'LB',
  Lc = 'LC',
  Li = 'LI',
  Lk = 'LK',
  Lr = 'LR',
  Ls = 'LS',
  Lt = 'LT',
  Lu = 'LU',
  Lv = 'LV',
  Ly = 'LY',
  Ma = 'MA',
  Mc = 'MC',
  Md = 'MD',
  Me = 'ME',
  Mf = 'MF',
  Mg = 'MG',
  Mh = 'MH',
  Mk = 'MK',
  Ml = 'ML',
  Mm = 'MM',
  Mn = 'MN',
  Mo = 'MO',
  Mp = 'MP',
  Mq = 'MQ',
  Mr = 'MR',
  Ms = 'MS',
  Mt = 'MT',
  Mu = 'MU',
  Mv = 'MV',
  Mw = 'MW',
  Mx = 'MX',
  My = 'MY',
  Mz = 'MZ',
  Na = 'NA',
  Nc = 'NC',
  Ne = 'NE',
  Nf = 'NF',
  Ng = 'NG',
  Ni = 'NI',
  Nl = 'NL',
  No = 'NO',
  Np = 'NP',
  Nr = 'NR',
  Nu = 'NU',
  Nz = 'NZ',
  Om = 'OM',
  Pa = 'PA',
  Pe = 'PE',
  Pf = 'PF',
  Pg = 'PG',
  Ph = 'PH',
  Pk = 'PK',
  Pl = 'PL',
  Pm = 'PM',
  Pn = 'PN',
  Pr = 'PR',
  Ps = 'PS',
  Pt = 'PT',
  Pw = 'PW',
  Py = 'PY',
  Qa = 'QA',
  Re = 'RE',
  Ro = 'RO',
  Rs = 'RS',
  Ru = 'RU',
  Rw = 'RW',
  Sa = 'SA',
  Sb = 'SB',
  Sc = 'SC',
  Sd = 'SD',
  Se = 'SE',
  Sg = 'SG',
  Sh = 'SH',
  Si = 'SI',
  Sj = 'SJ',
  Sk = 'SK',
  Sl = 'SL',
  Sm = 'SM',
  Sn = 'SN',
  So = 'SO',
  Sr = 'SR',
  Ss = 'SS',
  St = 'ST',
  Sv = 'SV',
  Sx = 'SX',
  Sy = 'SY',
  Sz = 'SZ',
  Tc = 'TC',
  Td = 'TD',
  Tf = 'TF',
  Tg = 'TG',
  Th = 'TH',
  Tj = 'TJ',
  Tk = 'TK',
  Tl = 'TL',
  Tm = 'TM',
  Tn = 'TN',
  To = 'TO',
  Tr = 'TR',
  Tt = 'TT',
  Tv = 'TV',
  Tw = 'TW',
  Tz = 'TZ',
  Ua = 'UA',
  Ug = 'UG',
  Um = 'UM',
  Us = 'US',
  Uy = 'UY',
  Uz = 'UZ',
  Va = 'VA',
  Vc = 'VC',
  Ve = 'VE',
  Vg = 'VG',
  Vi = 'VI',
  Vn = 'VN',
  Vu = 'VU',
  Wf = 'WF',
  Ws = 'WS',
  Ye = 'YE',
  Yt = 'YT',
  Za = 'ZA',
  Zm = 'ZM',
  Zw = 'ZW',
}

export type CreateBankAuthUrlArgs = {
  connectorId: Scalars['String']['input'];
  errorUrl?: InputMaybe<Scalars['String']['input']>;
  orchestrated?: InputMaybe<Scalars['Boolean']['input']>;
  orchestratorRequestId?: InputMaybe<Scalars['String']['input']>;
  paymentBatchId?: InputMaybe<Scalars['String']['input']>;
  scopes?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  successUrl?: InputMaybe<Scalars['String']['input']>;
};

export type CreateBankAuthUrlResponse = {
  __typename?: 'CreateBankAuthUrlResponse';
  authUrl: Scalars['String']['output'];
};

export type CreateRequestError = BaseError & {
  __typename?: 'CreateRequestError';
  key: Scalars['String']['output'];
  message: Scalars['String']['output'];
};

export type CreateRequestResponse = {
  __typename?: 'CreateRequestResponse';
  data?: Maybe<RequestData>;
  error?: Maybe<CreateRequestError>;
  result: Scalars['Boolean']['output'];
};

export enum CreatedThrough {
  Request = 'request',
}

export type Customer = {
  __typename?: 'Customer';
  agentDeterminedRiskCategory?: Maybe<GeneratedRiskCategory>;
  aisEnabled: Scalars['Boolean']['output'];
  approved?: Maybe<Scalars['Boolean']['output']>;
  approvedByTheSystem?: Maybe<Scalars['Boolean']['output']>;
  createdAt?: Maybe<Scalars['AWSDateTime']['output']>;
  generatedRiskCategory: GeneratedRiskCategory;
  handledAt?: Maybe<Scalars['AWSDateTime']['output']>;
  handledBy?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  kvkNumber?: Maybe<Scalars['String']['output']>;
  lastCheckedAt?: Maybe<Scalars['AWSDateTime']['output']>;
  legalType?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  onSanctionList?: Maybe<Scalars['Boolean']['output']>;
  pepLevel?: Maybe<Scalars['Int']['output']>;
  pisEnabled: Scalars['Boolean']['output'];
  reviewedAt?: Maybe<Scalars['AWSDateTime']['output']>;
  reviewedBy?: Maybe<Scalars['String']['output']>;
  sbi?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  transactionProfile?: Maybe<Scalars['AWSJSON']['output']>;
  updatedAt?: Maybe<Scalars['AWSDateTime']['output']>;
  userId?: Maybe<Scalars['String']['output']>;
};

export type DateFilter = {
  after?: InputMaybe<Scalars['AWSDate']['input']>;
  before?: InputMaybe<Scalars['AWSDate']['input']>;
};

export type DeleteHixAccountResponse = {
  __typename?: 'DeleteHixAccountResponse';
  isDeleted?: Maybe<Scalars['Boolean']['output']>;
};

export type DetermineCompanyKvkResponse = {
  __typename?: 'DetermineCompanyKvkResponse';
  businessName?: Maybe<Scalars['String']['output']>;
  customerId?: Maybe<Scalars['String']['output']>;
  kvkNumber?: Maybe<Scalars['String']['output']>;
};

export enum EmailTypes {
  ContactDetails = 'ContactDetails',
  ContactRequest = 'ContactRequest',
}

export type FieldError = {
  __typename?: 'FieldError';
  code?: Maybe<Scalars['String']['output']>;
  field?: Maybe<Scalars['String']['output']>;
  rejectedValue?: Maybe<Scalars['String']['output']>;
};

export type FinancedInvoice = {
  __typename?: 'FinancedInvoice';
  administrationId: Scalars['String']['output'];
  debtorKvk: Scalars['String']['output'];
  dueDate: Scalars['AWSDate']['output'];
  duration: Scalars['Int']['output'];
  interestCost: Scalars['Float']['output'];
  interestRate: Scalars['Float']['output'];
  invoiceAmount: Scalars['Float']['output'];
  invoiceExtId?: Maybe<Scalars['String']['output']>;
  invoiceFinancingOfferId: Scalars['String']['output'];
  invoiceId: Scalars['String']['output'];
  issueDate: Scalars['AWSDate']['output'];
  loanAmount: Scalars['Float']['output'];
  partnerId?: Maybe<Scalars['String']['output']>;
  requestDate: Scalars['AWSDateTime']['output'];
  userId: Scalars['String']['output'];
};

export type FinancedInvoiceInput = {
  administrationId: Scalars['String']['input'];
  debtorKvk: Scalars['String']['input'];
  dueDate: Scalars['AWSDate']['input'];
  duration: Scalars['Int']['input'];
  interestCost: Scalars['Float']['input'];
  interestRate: Scalars['Float']['input'];
  invoiceAmount: Scalars['Float']['input'];
  invoiceExtId?: InputMaybe<Scalars['String']['input']>;
  invoiceFinancingOfferId: Scalars['String']['input'];
  invoiceId: Scalars['String']['input'];
  issueDate: Scalars['AWSDate']['input'];
  loanAmount: Scalars['Float']['input'];
  partnerId?: InputMaybe<Scalars['String']['input']>;
};

export type FinishConnectingHixAccountResponse = {
  __typename?: 'FinishConnectingHixAccountResponse';
  frontendUrl?: Maybe<Scalars['String']['output']>;
  isAccountConnected: Scalars['Boolean']['output'];
};

export type FlowActionData = ActionData | FlowData;

export type FlowData = {
  __typename?: 'FlowData';
  actions: Array<FlowActionData>;
  contactForm?: Maybe<ContactForm>;
  dependencies?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  description?: Maybe<LocalisedText>;
  flowType: FlowType;
  id: Scalars['String']['output'];
  isFinished: Scalars['Boolean']['output'];
  onFinishUrl?: Maybe<Scalars['String']['output']>;
  redirectUrl?: Maybe<Scalars['String']['output']>;
  requests: Array<Maybe<RequestData>>;
  shortTitle?: Maybe<LocalisedText>;
  skipOverview?: Maybe<Scalars['Boolean']['output']>;
  status: FlowStatus;
  title: LocalisedText;
  type: Scalars['String']['output'];
};

export enum FlowStatus {
  Available = 'available',
  Blocked = 'blocked',
  Completed = 'completed',
  Declined = 'declined',
  Expired = 'expired',
  Finalized = 'finalized',
  Waiting = 'waiting',
}

export enum FlowType {
  Freeform = 'freeform',
  Linear = 'linear',
}

export enum GeneratedRiskCategory {
  High = 'high',
  Low = 'low',
  Medium = 'medium',
  Unknown = 'unknown',
}

export type GetAppsError = BaseError & {
  __typename?: 'GetAppsError';
  message: Scalars['String']['output'];
};

export type GetAppsQueryArgs = {
  condition?: InputMaybe<AndOrCondition>;
  countryCode?: InputMaybe<CountryCodeType>;
  flowId: Scalars['String']['input'];
  requestId?: InputMaybe<Scalars['String']['input']>;
  tagCodes?: InputMaybe<Array<Scalars['String']['input']>>;
  tagIds?: InputMaybe<Array<Scalars['String']['input']>>;
  urlConfiguration?: InputMaybe<Scalars['String']['input']>;
};

export type GetAppsResponse = AppsResponse | GetAppsError;

export type GetAuthUrlInput = {
  bankAccountId?: InputMaybe<Scalars['String']['input']>;
  clientIp?: InputMaybe<Scalars['String']['input']>;
  errorUrl?: InputMaybe<Scalars['String']['input']>;
  fromNative?: InputMaybe<Scalars['Boolean']['input']>;
  options?: InputMaybe<Scalars['AWSJSON']['input']>;
  orchestrated?: InputMaybe<Scalars['Boolean']['input']>;
  orchestratorRequestId?: InputMaybe<Scalars['String']['input']>;
  paymentBatchId?: InputMaybe<Scalars['String']['input']>;
  scopes: Array<ConnectAccountScope>;
  successUrl?: InputMaybe<Scalars['String']['input']>;
  userId: Scalars['String']['input'];
};

export type GetAuthUrlResponse = {
  __typename?: 'GetAuthUrlResponse';
  url: Scalars['String']['output'];
};

export type GetBankConnectorsFilterArgs = {
  connectorIds?: InputMaybe<Array<Connector>>;
  countries?: InputMaybe<Array<Scalars['String']['input']>>;
  tags?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type GetBankTransactionsArgs = {
  bankAccountId: Scalars['ID']['input'];
  limit: Scalars['Int']['input'];
  offset: Scalars['Int']['input'];
};

export type GetBankTransactionsResponse = BankAccountAccessError | BankTransactionsResponse;

export type GetBicResponse = {
  __typename?: 'GetBicResponse';
  bic: Scalars['String']['output'];
};

export type GetHixAccountResponse = {
  __typename?: 'GetHixAccountResponse';
  email?: Maybe<Scalars['String']['output']>;
  isSmsSent?: Maybe<Scalars['Boolean']['output']>;
  isVerificationCodeSent?: Maybe<Scalars['Boolean']['output']>;
};

export type GetHixAccountsResponse = {
  __typename?: 'GetHixAccountsResponse';
  accounts: Array<Maybe<HixAccount>>;
};

export type GetHixUserStatusResponse = {
  __typename?: 'GetHixUserStatusResponse';
  frontendUrl?: Maybe<Scalars['String']['output']>;
  isAccountConnected: Scalars['Boolean']['output'];
  isHixEmailVerified: Scalars['Boolean']['output'];
};

export type GetInvoiceFinanceOffersResponseListItem = {
  __typename?: 'GetInvoiceFinanceOffersResponseListItem';
  id: Scalars['ID']['output'];
  interestPercentage: Scalars['Float']['output'];
  loanAmount: Scalars['Float']['output'];
  totalInterestCost: Scalars['Float']['output'];
};

export type GetPartnerResponse = {
  __typename?: 'GetPartnerResponse';
  partner: Partner;
};

export type GetPartnersResponse = {
  __typename?: 'GetPartnersResponse';
  partners?: Maybe<Array<Partner>>;
};

export type GetPaymentStatusResponse = {
  __typename?: 'GetPaymentStatusResponse';
  status?: Maybe<PaymentStatus>;
};

export type GetPaymentsFilter = {
  status?: InputMaybe<Array<PaymentStatus>>;
};

export type GetRequestError = BaseError & {
  __typename?: 'GetRequestError';
  key: Scalars['String']['output'];
  message: Scalars['String']['output'];
};

export type GetRequestResponse = GetRequestError | RequestData;

export type GetUbosResponse = {
  __typename?: 'GetUbosResponse';
  dateOfBirth?: Maybe<PersonFunctionaryDate>;
  dateSince?: Maybe<PersonFunctionaryDate>;
  firstName?: Maybe<Scalars['String']['output']>;
  fullName?: Maybe<Scalars['String']['output']>;
  functionType?: Maybe<Scalars['String']['output']>;
  initials?: Maybe<Scalars['String']['output']>;
  lastName?: Maybe<Scalars['String']['output']>;
};

export type HasHixAccountResponse = {
  __typename?: 'HasHixAccountResponse';
  hasValidAccount?: Maybe<Scalars['Boolean']['output']>;
};

export type HixAccount = {
  __typename?: 'HixAccount';
  clientDomain: Scalars['String']['output'];
  email: Scalars['String']['output'];
  error?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  setupToken?: Maybe<Scalars['String']['output']>;
};

export type HybridPartner = NonExistingPartner | Partner;

export type HybridPartnerResponse = {
  __typename?: 'HybridPartnerResponse';
  partner?: Maybe<HybridPartner>;
};

export type HybridPartnersResponse = {
  __typename?: 'HybridPartnersResponse';
  partners?: Maybe<Array<HybridPartner>>;
};

export type IbanBic = {
  __typename?: 'IbanBic';
  bic: Scalars['String']['output'];
  iban: Scalars['String']['output'];
};

export enum IdentificationLevel {
  Email = 'email',
  Idin = 'idin',
  None = 'none',
}

export type InitiateHixAccountConnectionResponse = {
  __typename?: 'InitiateHixAccountConnectionResponse';
  isInitiationSuccessful?: Maybe<Scalars['Boolean']['output']>;
};

export type Invoice = {
  __typename?: 'Invoice';
  administration?: Maybe<Administration>;
  administrationId: Scalars['String']['output'];
  amount: Scalars['Float']['output'];
  date: Scalars['AWSDate']['output'];
  docType: InvoiceDocType;
  dueDate?: Maybe<Scalars['AWSDate']['output']>;
  duplicate: Scalars['Boolean']['output'];
  filename: Scalars['String']['output'];
  iban: Scalars['String']['output'];
  id: Scalars['String']['output'];
  invoiceNumber: Scalars['String']['output'];
  name: Scalars['String']['output'];
  partner?: Maybe<InvoicePartner>;
  status: Scalars['String']['output'];
};

export type InvoiceAccessError = NoAccessError & {
  __typename?: 'InvoiceAccessError';
  message: Scalars['String']['output'];
};

export enum InvoiceDocType {
  Other = 'other',
  Purchase = 'purchase',
  Receipt = 'receipt',
  Sales = 'sales',
}

export type InvoiceDocumentResponse = {
  __typename?: 'InvoiceDocumentResponse';
  documentUrl: Scalars['String']['output'];
  filename?: Maybe<Scalars['String']['output']>;
  mimeType?: Maybe<Scalars['String']['output']>;
};

export type InvoiceFinanceState = {
  __typename?: 'InvoiceFinanceState';
  completedAmount: Scalars['Int']['output'];
  createdAt: Scalars['AWSDateTime']['output'];
  emailAddress: Scalars['String']['output'];
  firstName: Scalars['String']['output'];
  lastName: Scalars['String']['output'];
  phoneNumber: Scalars['String']['output'];
  updatedAt: Scalars['AWSDateTime']['output'];
};

export type InvoiceFinanceStateUpdateInput = {
  completedAmount?: InputMaybe<Scalars['Int']['input']>;
  emailAddress?: InputMaybe<Scalars['String']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
};

export type InvoiceFinanceToken = {
  __typename?: 'InvoiceFinanceToken';
  accessToken: Scalars['String']['output'];
  caseId?: Maybe<Scalars['String']['output']>;
};

export type InvoicePartner = {
  __typename?: 'InvoicePartner';
  logo?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  partnerId: Scalars['String']['output'];
};

export type InvoiceResponse = AdministrationAccessError | Invoice | InvoiceAccessError;

export enum InvoiceStatus {
  Approved = 'approved',
  Archived = 'archived',
  New = 'new',
  Paid = 'paid',
  Sent = 'sent',
  Transacted = 'transacted',
}

export type IsBetaMemberResponse = {
  __typename?: 'IsBetaMemberResponse';
  isBetaMember: Scalars['Boolean']['output'];
};

export type ListAdministrationsFilter = {
  deleted?: InputMaybe<Scalars['Boolean']['input']>;
  newDocumentCount?: InputMaybe<AmountFilter>;
};

export type ListInvoicesFilter = {
  amount?: InputMaybe<AmountFilter>;
  date?: InputMaybe<DateFilter>;
  status?: InputMaybe<Array<InvoiceStatus>>;
};

export type ListPartnerInvoicesFilter = {
  administrationId?: InputMaybe<Scalars['String']['input']>;
  amount?: InputMaybe<AmountFilter>;
  date?: InputMaybe<DateFilter>;
  partnerId?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<Array<InvoiceStatus>>;
};

export type LocalisedText = {
  __typename?: 'LocalisedText';
  translationKey: Scalars['String']['output'];
  translationParams?: Maybe<Array<Maybe<LocalisedTextTranslationParams>>>;
};

export type LocalisedTextTranslationParams = {
  __typename?: 'LocalisedTextTranslationParams';
  name: Scalars['String']['output'];
  value: Scalars['String']['output'];
};

export type Mutation = {
  __typename?: 'Mutation';
  addBetaMemberToPrograms: Scalars['Boolean']['output'];
  createAdministrationsBasedOnAccountHolders: Scalars['Boolean']['output'];
  createBankAuthUrl?: Maybe<CreateBankAuthUrlResponse>;
  createPartnerAuthorization?: Maybe<PartnerAuthorizationResponse>;
  createRequest: CreateRequestResponse;
  deleteBankAccount?: Maybe<Scalars['Boolean']['output']>;
  deleteHixAccount?: Maybe<DeleteHixAccountResponse>;
  finishFlowAction?: Maybe<FlowData>;
  processBankTransactions?: Maybe<Scalars['Boolean']['output']>;
  processCddUpdates: ProcessCddUpdatesResponse;
  processPayments: ProcessPaymentsResponse;
  putFinancedInvoice?: Maybe<FinancedInvoice>;
  putInvoiceFinanceState?: Maybe<InvoiceFinanceState>;
  sendContactRequest?: Maybe<SendContactRequestResponse>;
  sendForm?: Maybe<Scalars['Boolean']['output']>;
  sendPreliminaryOffer?: Maybe<ValidatedSendPreliminaryOfferResponse>;
  sendRequest?: Maybe<Scalars['Boolean']['output']>;
  startFlow?: Maybe<FlowData>;
  uninstallPartnerApp?: Maybe<UninstallPartnerAppResponse>;
  updateActionState?: Maybe<UpdateActionStateResponse>;
  updateAdministration?: Maybe<Administration>;
  updateAdministrations: Array<Administration>;
  updateInvoiceFinanceState?: Maybe<InvoiceFinanceState>;
  updateRequest: UpdateRequestResponse;
  updateUser?: Maybe<User>;
};

export type MutationAddBetaMemberToProgramsArgs = {
  betaProgramIds: Array<Scalars['String']['input']>;
  memberId: Scalars['String']['input'];
  memberType: BetaProgramMemberType;
};

export type MutationCreateBankAuthUrlArgs = {
  createBankAuthUrlArgs: CreateBankAuthUrlArgs;
};

export type MutationCreatePartnerAuthorizationArgs = {
  autoConsent?: InputMaybe<Scalars['Boolean']['input']>;
  codeChallenge?: InputMaybe<Scalars['String']['input']>;
  consent: PartnerConsent;
  partnerId: Scalars['String']['input'];
  partnerReference?: InputMaybe<Scalars['String']['input']>;
  requestId?: InputMaybe<Scalars['String']['input']>;
};

export type MutationCreateRequestArgs = {
  request: RequestInput;
};

export type MutationDeleteBankAccountArgs = {
  bankAccountId: Scalars['ID']['input'];
  requestId?: InputMaybe<Scalars['ID']['input']>;
};

export type MutationFinishFlowActionArgs = {
  actionId: Scalars['String']['input'];
  flowId: Scalars['String']['input'];
  requestId?: InputMaybe<Scalars['String']['input']>;
  state?: InputMaybe<Scalars['AWSJSON']['input']>;
};

export type MutationProcessBankTransactionsArgs = {
  processBankTransactionsArgs: ProcessBankTransactionsArgs;
};

export type MutationProcessCddUpdatesArgs = {
  connectorId: Scalars['String']['input'];
  userId: Scalars['String']['input'];
};

export type MutationProcessPaymentsArgs = {
  bankAccounts: Array<ProcessPaymentsInput>;
  connector: Connector;
};

export type MutationPutFinancedInvoiceArgs = {
  putFinancedInvoiceArgs: FinancedInvoiceInput;
};

export type MutationSendContactRequestArgs = {
  sendContactRequestArgs: SendContactRequestArgs;
};

export type MutationSendFormArgs = {
  fields?: InputMaybe<Scalars['AWSJSON']['input']>;
};

export type MutationSendPreliminaryOfferArgs = {
  preliminaryOffer: SendPreliminaryOfferArgs;
};

export type MutationSendRequestArgs = {
  requestId: Scalars['String']['input'];
};

export type MutationStartFlowArgs = {
  flowId: Scalars['String']['input'];
  redirectUrl?: InputMaybe<Scalars['String']['input']>;
  requestId?: InputMaybe<Scalars['String']['input']>;
};

export type MutationUninstallPartnerAppArgs = {
  partnerId: Scalars['String']['input'];
  requestId?: InputMaybe<Scalars['String']['input']>;
};

export type MutationUpdateActionStateArgs = {
  actionId: Scalars['String']['input'];
  flowId: Scalars['String']['input'];
  requestId?: InputMaybe<Scalars['String']['input']>;
  state: ActionState;
};

export type MutationUpdateAdministrationArgs = {
  administration: UpdateAdministrationArgs;
};

export type MutationUpdateAdministrationsArgs = {
  administrations?: InputMaybe<Array<UpdateAdministrationArgs>>;
};

export type MutationUpdateInvoiceFinanceStateArgs = {
  invoiceFinanceState: InvoiceFinanceStateUpdateInput;
};

export type MutationUpdateRequestArgs = {
  reminderDateTime?: InputMaybe<Scalars['AWSTimestamp']['input']>;
  requestId: Scalars['String']['input'];
  status: RequestStatus;
};

export type MutationUpdateUserArgs = {
  updateUserArgs?: InputMaybe<UpdateUserArgs>;
};

export type NoAccessError = {
  message: Scalars['String']['output'];
};

export type NonExistingPartner = {
  __typename?: 'NonExistingPartner';
  id: Scalars['String']['output'];
  isValid?: Maybe<Scalars['Boolean']['output']>;
};

export enum OcrQuality {
  Fast = 'fast',
  Full = 'full',
}

export type OrderBy = {
  field: Scalars['String']['input'];
  order?: InputMaybe<OrderByOrder>;
};

export enum OrderByOrder {
  Asc = 'ASC',
  Desc = 'DESC',
}

export type Partner = {
  __typename?: 'Partner';
  appName: Scalars['String']['output'];
  appScopes?: Maybe<Scalars['String']['output']>;
  billingType?: Maybe<BillingType>;
  defaultAccountType?: Maybe<PartnerAccountType>;
  futureScopes?: Maybe<Scalars['String']['output']>;
  icon?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  internalName?: Maybe<Scalars['String']['output']>;
  isValid?: Maybe<Scalars['Boolean']['output']>;
  name: Scalars['String']['output'];
  relation?: Maybe<PartnerRelation>;
  supportEmail?: Maybe<Scalars['String']['output']>;
  theme?: Maybe<Theme>;
  trustTermsAndConditions?: Maybe<TrustTermsAndConditions>;
  trustedScopes?: Maybe<Scalars['String']['output']>;
  urlConfiguration?: Maybe<UrlConfiguration>;
};

export enum PartnerAccountType {
  Business = 'business',
  NoPreference = 'no_preference',
  Private = 'private',
}

export type PartnerAuthorizationBaseResponse = {
  __typename?: 'PartnerAuthorizationBaseResponse';
  accessToken: Scalars['String']['output'];
  code: Scalars['String']['output'];
  idToken: Scalars['String']['output'];
  refreshToken?: Maybe<Scalars['String']['output']>;
};

export type PartnerAuthorizationRefreshTokenBaseResponse = {
  __typename?: 'PartnerAuthorizationRefreshTokenBaseResponse';
  refreshToken: Scalars['String']['output'];
};

export type PartnerAuthorizationRefreshTokenResponse =
  | PartnerAuthorizationRefreshTokenBaseResponse
  | PartnerBaseError;

export type PartnerAuthorizationResponse = PartnerAuthorizationBaseResponse | PartnerBaseError;

export type PartnerBaseError = BaseError & {
  __typename?: 'PartnerBaseError';
  message: Scalars['String']['output'];
};

export type PartnerConsent = {
  administrations: Array<Scalars['String']['input']>;
  bankAccounts: Array<Scalars['String']['input']>;
  scopes: Array<PartnerScopes>;
};

export enum PartnerRelation {
  Bizcuit = 'bizcuit',
  BizcuitLabel = 'bizcuit_label',
  WhiteLabel = 'white_label',
}

export enum PartnerScopes {
  AccountInformation = 'account_information',
  Administrations = 'administrations',
  Email = 'email',
  ManageAdministrations = 'manage_administrations',
  ManageBankAccounts = 'manage_bank_accounts',
  Openid = 'openid',
  PaymentInitiation = 'payment_initiation',
  Profile = 'profile',
}

export enum PartnerType {
  Api = 'api',
  App = 'app',
  Unknown = 'unknown',
  Webview = 'webview',
}

export type PartnerUrlConfiguration = {
  __typename?: 'PartnerUrlConfiguration';
  flow?: Maybe<PartnerUrlConfigurationFlow>;
};

export type PartnerUrlConfigurationFlow = {
  __typename?: 'PartnerUrlConfigurationFlow';
  connectAccountingSystem?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  default?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
};

export type PartnerUrls = {
  __typename?: 'PartnerUrls';
  configure?: Maybe<Scalars['String']['output']>;
  connection?: Maybe<Scalars['String']['output']>;
  exportTransactions?: Maybe<Scalars['String']['output']>;
};

export type Payment = {
  __typename?: 'Payment';
  allowPartialPayment: Scalars['Boolean']['output'];
  amount?: Maybe<Scalars['Float']['output']>;
  bankAccount?: Maybe<BankAccount>;
  bankAccountId?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['AWSDateTime']['output']>;
  date?: Maybe<Scalars['AWSDate']['output']>;
  errorMessage?: Maybe<Scalars['String']['output']>;
  externalReference?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  internal: Scalars['Boolean']['output'];
  status: PaymentStatus;
  type: PaymentType;
};

export enum PaymentLineStatus {
  Approved = 'approved',
  Blacklisted = 'blacklisted',
  Declined = 'declined',
  New = 'new',
}

export enum PaymentStatus {
  Done = 'done',
  Error = 'error',
  New = 'new',
  Sent = 'sent',
  Signed = 'signed',
}

export enum PaymentType {
  CreditTransfer = 'credit_transfer',
  DirectDebit = 'direct_debit',
}

export type PersonFunctionaryDate = {
  __typename?: 'PersonFunctionaryDate';
  day?: Maybe<Scalars['String']['output']>;
  month?: Maybe<Scalars['String']['output']>;
  year?: Maybe<Scalars['String']['output']>;
};

export type Pricing = {
  __typename?: 'Pricing';
  administrationDocumentUnitsIncluded?: Maybe<Scalars['Int']['output']>;
  rabobankPremiumBankIntegration?: Maybe<Scalars['Float']['output']>;
  standardBankIntegrationUnitsIncluded?: Maybe<Scalars['Int']['output']>;
  standardSoftwareIntegrationUnitsIncluded?: Maybe<Scalars['Int']['output']>;
  standardSubscriptionPrice?: Maybe<Scalars['Float']['output']>;
};

export type ProcessAccountInput = {
  bankAccountId: Scalars['ID']['input'];
  currency: Scalars['String']['input'];
  externalId: Scalars['ID']['input'];
  iban: Scalars['String']['input'];
  pisProviderAccessId?: InputMaybe<Scalars['String']['input']>;
  providerAccessId?: InputMaybe<Scalars['String']['input']>;
};

export type ProcessAccounts = {
  __typename?: 'ProcessAccounts';
  message: Scalars['String']['output'];
};

export type ProcessAccountsError = BaseError & {
  __typename?: 'ProcessAccountsError';
  message: Scalars['String']['output'];
};

export type ProcessAccountsResponse = ProcessAccounts | ProcessAccountsError;

export type ProcessBankTransactionsArgs = {
  bankAccountId: Scalars['ID']['input'];
  connectorId: Scalars['String']['input'];
};

export type ProcessCddUpdatesResponse = {
  __typename?: 'ProcessCddUpdatesResponse';
  bankAccountIds: Array<Scalars['String']['output']>;
};

export type ProcessPaymentsInput = {
  bankAccountId: Scalars['ID']['input'];
  providerAccessId: Scalars['ID']['input'];
};

export type ProcessPaymentsResponse = {
  __typename?: 'ProcessPaymentsResponse';
  message: Scalars['String']['output'];
};

export type Query = {
  __typename?: 'Query';
  areBetaMembers: Array<AreBetaMembersResponseItem>;
  authorizeAdminEmbeddedFlowsTester?: Maybe<Scalars['Boolean']['output']>;
  checkCompanyCdd?: Maybe<CheckCompanyCddResponse>;
  checkPersonCdd?: Maybe<CheckPersonCddResponse>;
  connectAccount: ConnectAccountResponse;
  determineCompanyKvk?: Maybe<DetermineCompanyKvkResponse>;
  finishConnectingHixAccount?: Maybe<FinishConnectingHixAccountResponse>;
  getActiveBetaPrograms: Array<Maybe<BetaProgram>>;
  getActiveFlow?: Maybe<FlowData>;
  getApps?: Maybe<GetAppsResponse>;
  getAuthUrl: GetAuthUrlResponse;
  getBankAccountBic?: Maybe<GetBicResponse>;
  getBankAccounts?: Maybe<Array<BankAccount>>;
  getBankBic?: Maybe<GetBicResponse>;
  getBankConnectorsDetails?: Maybe<Array<Maybe<BankConnectorsDetails>>>;
  getBankTransactions?: Maybe<GetBankTransactionsResponse>;
  getBicsBatch?: Maybe<Array<Maybe<IbanBic>>>;
  getFinancedInvoice?: Maybe<FinancedInvoice>;
  getFlow?: Maybe<FlowData>;
  getHixAccounts?: Maybe<GetHixAccountsResponse>;
  getHixUser?: Maybe<GetHixAccountResponse>;
  getHixUserStatus?: Maybe<GetHixUserStatusResponse>;
  getInvoice?: Maybe<InvoiceResponse>;
  getInvoiceDocument?: Maybe<InvoiceDocumentResponse>;
  getInvoiceFinanceState?: Maybe<InvoiceFinanceState>;
  getInvoiceFinanceToken?: Maybe<ValidatedInvoiceFinanceToken>;
  getPartner?: Maybe<HybridPartnerResponse>;
  getPartnerAuthorizationRefreshToken?: Maybe<PartnerAuthorizationRefreshTokenResponse>;
  getPartners?: Maybe<HybridPartnersResponse>;
  getPaymentStatus?: Maybe<GetPaymentStatusResponse>;
  getPayments?: Maybe<Array<Maybe<Payment>>>;
  getPricing?: Maybe<Pricing>;
  getReceivableInvoices?: Maybe<Array<Maybe<ReceivableInvoice>>>;
  getRequest?: Maybe<GetRequestResponse>;
  getSingleBankAccount: BankAccount;
  getUbos?: Maybe<GetUbosResponse>;
  getUser?: Maybe<User>;
  getUserSession?: Maybe<UserSession>;
  hasHixAccount?: Maybe<HasHixAccountResponse>;
  initiateHixAccountConnection?: Maybe<InitiateHixAccountConnectionResponse>;
  isBetaMember: IsBetaMemberResponse;
  listAdministrations?: Maybe<Array<Maybe<Administration>>>;
  listInvoices?: Maybe<Array<Maybe<Invoice>>>;
  listPartnerAdministrations: Array<Administration>;
  listPartnerInvoices?: Maybe<Array<Maybe<Invoice>>>;
  listUserApps?: Maybe<Array<Maybe<UserApps>>>;
  processAccounts: ProcessAccountsResponse;
  searchCompanies?: Maybe<Companies>;
  sendHixAccountVerificationEmail?: Maybe<SendHixAccountVerificationEmailResponse>;
  sendHixAccountVerificationSms?: Maybe<SendHixAccountVerificationSmsResponse>;
  sendPayment: SendPaymentResponse;
  verifyAccountNameAndType?: Maybe<VerifyAccountNameAndTypeResponse>;
  verifyHixEmail?: Maybe<VerifyHixEmailResponse>;
};

export type QueryAreBetaMembersArgs = {
  betaProgramId: Scalars['String']['input'];
  entities: Array<BetaMemberInput>;
};

export type QueryCheckCompanyCddArgs = {
  customerId: Scalars['String']['input'];
  kvkNumber: Scalars['String']['input'];
};

export type QueryCheckPersonCddArgs = {
  country?: InputMaybe<Scalars['String']['input']>;
  customerId: Scalars['String']['input'];
  name: Scalars['String']['input'];
};

export type QueryConnectAccountArgs = {
  connectAccountInput: ConnectAccountInput;
  connector: Connector;
};

export type QueryDetermineCompanyKvkArgs = {
  businessName: Scalars['String']['input'];
  city?: InputMaybe<Scalars['String']['input']>;
  customerId: Scalars['String']['input'];
};

export type QueryFinishConnectingHixAccountArgs = {
  otp: Scalars['String']['input'];
};

export type QueryGetActiveFlowArgs = {
  requestId?: InputMaybe<Scalars['String']['input']>;
};

export type QueryGetAppsArgs = {
  getAppsQueryArgs: GetAppsQueryArgs;
};

export type QueryGetAuthUrlArgs = {
  connector: Connector;
  getAuthInput: GetAuthUrlInput;
};

export type QueryGetBankAccountBicArgs = {
  iban: Scalars['String']['input'];
};

export type QueryGetBankAccountsArgs = {
  administrationId?: InputMaybe<Scalars['String']['input']>;
  aisEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  bank?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  includeUnassigned?: InputMaybe<Scalars['Boolean']['input']>;
  requestId?: InputMaybe<Scalars['String']['input']>;
};

export type QueryGetBankBicArgs = {
  bankIdentifier: Scalars['String']['input'];
};

export type QueryGetBankConnectorsDetailsArgs = {
  bankIds?: InputMaybe<Array<Scalars['String']['input']>>;
  filters?: InputMaybe<GetBankConnectorsFilterArgs>;
};

export type QueryGetBankTransactionsArgs = {
  getBankTransactionsArgs: GetBankTransactionsArgs;
};

export type QueryGetBicsBatchArgs = {
  ibans: Array<Scalars['String']['input']>;
};

export type QueryGetFinancedInvoiceArgs = {
  administrationId: Scalars['String']['input'];
  invoiceId: Scalars['String']['input'];
  partnerId?: InputMaybe<Scalars['String']['input']>;
};

export type QueryGetFlowArgs = {
  flowId: Scalars['String']['input'];
  requestId?: InputMaybe<Scalars['String']['input']>;
};

export type QueryGetHixAccountsArgs = {
  email: Scalars['String']['input'];
};

export type QueryGetInvoiceArgs = {
  administrationId: Scalars['String']['input'];
  invoiceId: Scalars['String']['input'];
  partnerId?: InputMaybe<Scalars['String']['input']>;
};

export type QueryGetInvoiceDocumentArgs = {
  administrationId: Scalars['String']['input'];
  invoiceId: Scalars['String']['input'];
  isNewFile?: InputMaybe<Scalars['Boolean']['input']>;
  partnerId?: InputMaybe<Scalars['String']['input']>;
};

export type QueryGetPartnerArgs = {
  brandingPartnerId?: InputMaybe<Scalars['String']['input']>;
  partnerId?: InputMaybe<Scalars['String']['input']>;
};

export type QueryGetPartnerAuthorizationRefreshTokenArgs = {
  partnerId: Scalars['String']['input'];
};

export type QueryGetPartnersArgs = {
  partnerIds?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type QueryGetPaymentStatusArgs = {
  connector: Connector;
  paymentBatchId: Scalars['String']['input'];
};

export type QueryGetPaymentsArgs = {
  filter?: InputMaybe<GetPaymentsFilter>;
  limit: Scalars['Int']['input'];
  offset: Scalars['Int']['input'];
};

export type QueryGetRequestArgs = {
  requestId: Scalars['String']['input'];
};

export type QueryGetSingleBankAccountArgs = {
  administrationId?: InputMaybe<Scalars['String']['input']>;
  aisEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  bank?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  id: Scalars['String']['input'];
  includeUnassigned?: InputMaybe<Scalars['Boolean']['input']>;
};

export type QueryGetUbosArgs = {
  customerId: Scalars['String']['input'];
  kvkNumber: Scalars['String']['input'];
};

export type QueryGetUserArgs = {
  userId?: InputMaybe<Scalars['String']['input']>;
};

export type QueryHasHixAccountArgs = {
  email: Scalars['String']['input'];
};

export type QueryInitiateHixAccountConnectionArgs = {
  clientDomain: Scalars['String']['input'];
  setupToken: Scalars['String']['input'];
};

export type QueryIsBetaMemberArgs = {
  betaProgramId: Scalars['String']['input'];
  memberId: Scalars['String']['input'];
  memberType: BetaProgramMemberType;
};

export type QueryListAdministrationsArgs = {
  filter?: InputMaybe<ListAdministrationsFilter>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  requestId?: InputMaybe<Scalars['String']['input']>;
};

export type QueryListInvoicesArgs = {
  filter?: InputMaybe<ListInvoicesFilter>;
  limit: Scalars['Int']['input'];
  offset: Scalars['Int']['input'];
  orderBy?: InputMaybe<OrderBy>;
};

export type QueryListPartnerAdministrationsArgs = {
  partnerId: Scalars['String']['input'];
};

export type QueryListPartnerInvoicesArgs = {
  filter?: InputMaybe<ListPartnerInvoicesFilter>;
  limit: Scalars['Int']['input'];
  offset: Scalars['Int']['input'];
  orderBy?: InputMaybe<OrderBy>;
};

export type QueryProcessAccountsArgs = {
  bankAccounts: Array<ProcessAccountInput>;
  connector: Connector;
};

export type QuerySearchCompaniesArgs = {
  kvkNumber?: InputMaybe<Scalars['String']['input']>;
  tradeName?: InputMaybe<Scalars['String']['input']>;
};

export type QuerySendHixAccountVerificationEmailArgs = {
  email: Scalars['String']['input'];
};

export type QuerySendPaymentArgs = {
  connector: Connector;
  payment: SendPaymentInput;
};

export type QueryVerifyAccountNameAndTypeArgs = {
  country?: InputMaybe<Scalars['String']['input']>;
  iban: Scalars['String']['input'];
  kvkNumber?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
};

export type QueryVerifyHixEmailArgs = {
  verificationCode: Scalars['String']['input'];
};

export type RaboValidationError = {
  __typename?: 'RaboValidationError';
  code?: Maybe<Scalars['String']['output']>;
  errorCode: Scalars['String']['output'];
  invalidFields?: Maybe<Array<Maybe<FieldError>>>;
  message?: Maybe<Scalars['String']['output']>;
};

export type ReceivableInvoice = {
  __typename?: 'ReceivableInvoice';
  administrationId: Scalars['String']['output'];
  amountInCents: Scalars['Int']['output'];
  attachments: Array<Maybe<Attachment>>;
  counterAccount: CounterAccount;
  counterName?: Maybe<Scalars['String']['output']>;
  date: Scalars['String']['output'];
  dueDate: Scalars['String']['output'];
  hasDocuments: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  isDirectDebit: Scalars['Boolean']['output'];
  number: Scalars['String']['output'];
  paidAmountInCents: Scalars['Int']['output'];
  paymentAllowed: Scalars['Boolean']['output'];
  paymentReference: Scalars['String']['output'];
  relation: Relation;
};

export type Relation = {
  __typename?: 'Relation';
  account?: Maybe<CounterAccount>;
  address: Address;
  name: Scalars['String']['output'];
  number: Scalars['String']['output'];
};

export enum RequestChannel {
  Email = 'email',
}

export enum RequestConnectionType {
  ConnectAccountingSystem = 'connectAccountingSystem',
  ConnectBank = 'connectBank',
}

export type RequestData = {
  __typename?: 'RequestData';
  actions?: Maybe<Array<Scalars['String']['output']>>;
  approver?: Maybe<UserInfo>;
  channel?: Maybe<RequestChannel>;
  connectionType?: Maybe<RequestConnectionType>;
  createdAt: Scalars['AWSTimestamp']['output'];
  expirationDateTime?: Maybe<Scalars['AWSTimestamp']['output']>;
  flow?: Maybe<FlowData>;
  from?: Maybe<Scalars['String']['output']>;
  message: Scalars['String']['output'];
  partner?: Maybe<Partner>;
  periodicalType?: Maybe<RequestPeriodicalType>;
  reminderDateTimes?: Maybe<Array<Scalars['AWSTimestamp']['output']>>;
  requestId: Scalars['String']['output'];
  requester?: Maybe<UserInfo>;
  status?: Maybe<RequestStatus>;
  to?: Maybe<Scalars['String']['output']>;
};

export type RequestInput = {
  actions?: InputMaybe<Array<Scalars['String']['input']>>;
  channel: RequestChannel;
  connectionType: RequestConnectionType;
  expirationDateTime: Scalars['AWSTimestamp']['input'];
  flowId: Scalars['String']['input'];
  from?: InputMaybe<Scalars['String']['input']>;
  message?: InputMaybe<Scalars['String']['input']>;
  periodicalType: RequestPeriodicalType;
  to: Scalars['String']['input'];
};

export enum RequestPeriodicalType {
  OneTime = 'oneTime',
  Ongoing = 'ongoing',
}

export enum RequestStatus {
  Available = 'available',
  Completed = 'completed',
  Declined = 'declined',
  Expired = 'expired',
  Revoked = 'revoked',
  Waiting = 'waiting',
}

export type SendContactRequestArgs = {
  companyName?: InputMaybe<Scalars['String']['input']>;
  contactPerson?: InputMaybe<Scalars['String']['input']>;
  email: Scalars['String']['input'];
  emailType: EmailTypes;
  firstName?: InputMaybe<Scalars['String']['input']>;
  invoice?: InputMaybe<ContactInvoice>;
  kvkNumber?: InputMaybe<Scalars['String']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  phoneNumber: Scalars['String']['input'];
  request?: InputMaybe<Scalars['String']['input']>;
};

export type SendContactRequestResponse = {
  __typename?: 'SendContactRequestResponse';
  status: Scalars['String']['output'];
};

export type SendHixAccountVerificationEmailResponse = {
  __typename?: 'SendHixAccountVerificationEmailResponse';
  isEmailSent?: Maybe<Scalars['Boolean']['output']>;
};

export type SendHixAccountVerificationSmsResponse = {
  __typename?: 'SendHixAccountVerificationSmsResponse';
  isSmsSent?: Maybe<Scalars['Boolean']['output']>;
};

export type SendPaymentInput = {
  clientIp: Scalars['String']['input'];
  fromNative?: InputMaybe<Scalars['Boolean']['input']>;
  id: Scalars['ID']['input'];
  userId: Scalars['ID']['input'];
};

export type SendPaymentResponse = {
  __typename?: 'SendPaymentResponse';
  external?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  redirect?: Maybe<Scalars['String']['output']>;
  status?: Maybe<Scalars['String']['output']>;
};

export type SendPreliminaryOfferArgs = {
  amount: Scalars['Int']['input'];
  paymentTerm: Scalars['Int']['input'];
};

export type SendPreliminaryOfferResponse = {
  __typename?: 'SendPreliminaryOfferResponse';
  message?: Maybe<Scalars['String']['output']>;
  offer?: Maybe<SendPreliminaryOfferResponseOffer>;
};

export type SendPreliminaryOfferResponseOffer = {
  __typename?: 'SendPreliminaryOfferResponseOffer';
  duration: Scalars['Int']['output'];
  interestCost: Scalars['Float']['output'];
  interestRate: Scalars['Float']['output'];
  invoiceAmount: Scalars['Float']['output'];
  loanAmount: Scalars['Float']['output'];
};

export enum TagCodeType {
  Exportinvoices = 'exportinvoices',
  Sendbanktransactions = 'sendbanktransactions',
}

export type Theme = {
  __typename?: 'Theme';
  color?: Maybe<Scalars['String']['output']>;
  icon?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

export enum TrustTermsAndConditions {
  Partner = 'partner',
  SubProcessorAgreement = 'sub_processor_agreement',
}

export type UninstallPartnerAppBaseResponse = {
  __typename?: 'UninstallPartnerAppBaseResponse';
  result: Scalars['Boolean']['output'];
};

export type UninstallPartnerAppResponse = PartnerBaseError | UninstallPartnerAppBaseResponse;

export type UpdateActionStateResponse = {
  __typename?: 'UpdateActionStateResponse';
  flowData: FlowData;
  result: Scalars['Boolean']['output'];
};

export type UpdateAdministrationArgs = {
  id: Scalars['ID']['input'];
  kvkNumber?: InputMaybe<Scalars['String']['input']>;
  scanEmail?: InputMaybe<Scalars['String']['input']>;
  sendUbl?: InputMaybe<Scalars['Boolean']['input']>;
};

export type UpdateRequestResponse = {
  __typename?: 'UpdateRequestResponse';
  error?: Maybe<CreateRequestError>;
  result: Scalars['Boolean']['output'];
};

export type UpdateUserArgs = {
  billingBic?: InputMaybe<Scalars['String']['input']>;
  billingIban?: InputMaybe<Scalars['String']['input']>;
  billingName?: InputMaybe<Scalars['String']['input']>;
  language?: InputMaybe<Scalars['String']['input']>;
  termsApproved?: InputMaybe<Scalars['Boolean']['input']>;
  termsPaymentsApproved?: InputMaybe<Scalars['Boolean']['input']>;
};

export type UrlConfiguration = {
  __typename?: 'UrlConfiguration';
  config?: Maybe<PartnerUrlConfiguration>;
  url?: Maybe<PartnerUrls>;
};

export type User = {
  __typename?: 'User';
  active: Scalars['Boolean']['output'];
  betaTester: Scalars['Boolean']['output'];
  billingAccepted: Scalars['Boolean']['output'];
  billingBic?: Maybe<Scalars['String']['output']>;
  billingCity?: Maybe<Scalars['String']['output']>;
  billingIban?: Maybe<Scalars['String']['output']>;
  billingName?: Maybe<Scalars['String']['output']>;
  brandingPartnerId?: Maybe<Scalars['ID']['output']>;
  codeFailCount: Scalars['Int']['output'];
  createdThrough?: Maybe<CreatedThrough>;
  dateOfBirth?: Maybe<Scalars['AWSDateTime']['output']>;
  deactivatedAt?: Maybe<Scalars['AWSDateTime']['output']>;
  deactivatedBy?: Maybe<Scalars['ID']['output']>;
  defaultAdministrationId?: Maybe<Scalars['ID']['output']>;
  displayName?: Maybe<Scalars['String']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  emailSettings: Scalars['AWSJSON']['output'];
  embeddedUser: Scalars['Boolean']['output'];
  firstName?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  identificationLevel: IdentificationLevel;
  initials?: Maybe<Scalars['String']['output']>;
  language?: Maybe<Scalars['String']['output']>;
  lastName?: Maybe<Scalars['String']['output']>;
  ocrQuality: OcrQuality;
  partnerId?: Maybe<Scalars['ID']['output']>;
  partnerReference?: Maybe<Scalars['String']['output']>;
  partnerVerifiedUser: Scalars['Boolean']['output'];
  phoneNumber?: Maybe<Scalars['String']['output']>;
  phoneVerified?: Maybe<Scalars['Boolean']['output']>;
  privateAdministrationId?: Maybe<Scalars['ID']['output']>;
  starredApps?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  termsApproved: Scalars['Boolean']['output'];
  termsApprovedAt?: Maybe<Scalars['AWSDateTime']['output']>;
  termsPaymentsApproved: Scalars['Boolean']['output'];
  termsPaymentsApprovedAt?: Maybe<Scalars['AWSDateTime']['output']>;
  verificationMethod?: Maybe<VerificationMethod>;
};

export type UserApps = {
  __typename?: 'UserApps';
  appBeta: Scalars['Boolean']['output'];
  appScopes?: Maybe<Scalars['String']['output']>;
  appUrl?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  icon?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  installed: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  type: PartnerType;
};

export type UserInfo = {
  __typename?: 'UserInfo';
  displayName?: Maybe<Scalars['String']['output']>;
  email: Scalars['String']['output'];
  id: Scalars['ID']['output'];
};

export enum UserSecurityRoles {
  Admin = 'admin',
  CddApprover = 'cdd_approver',
  DataExporter = 'data_exporter',
  EmbeddedFlowsTester = 'embedded_flows_tester',
  MonitoringAgent = 'monitoring_agent',
  PartnerManager = 'partner_manager',
  Superuser = 'superuser',
  UserAccountManager = 'user_account_manager',
}

export type UserSession = {
  __typename?: 'UserSession';
  accessTokenCreatedAt: Scalars['String']['output'];
  createdAt: Scalars['String']['output'];
  deviceName?: Maybe<Scalars['String']['output']>;
  flowId?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  idinMerchantReference?: Maybe<Scalars['String']['output']>;
  idleTimeoutSeconds?: Maybe<Scalars['Int']['output']>;
  lastUsedAt: Scalars['String']['output'];
  lastUsedIp: Scalars['String']['output'];
  message?: Maybe<Scalars['String']['output']>;
  method: UserSessionMethod;
  signatureVerified: Scalars['Boolean']['output'];
  updatedAt: Scalars['String']['output'];
  userId: Scalars['ID']['output'];
};

export enum UserSessionMethod {
  Embedded = 'embedded',
  None = 'none',
  Password = 'password',
  Reset = 'reset',
}

export type ValidatedInvoiceFinanceToken = InvoiceFinanceToken | RaboValidationError;

export type ValidatedSendPreliminaryOfferResponse =
  | RaboValidationError
  | SendPreliminaryOfferResponse;

export enum VerificationMethod {
  Email = 'email',
  Phone = 'phone',
}

export type VerifyAccountNameAndTypeResponse = {
  __typename?: 'VerifyAccountNameAndTypeResponse';
  city?: Maybe<Scalars['String']['output']>;
  isCompany?: Maybe<Scalars['Boolean']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  nameSuggestion?: Maybe<Scalars['String']['output']>;
  raiseCddAlert?: Maybe<Scalars['Boolean']['output']>;
};

export type VerifyHixEmailResponse = {
  __typename?: 'VerifyHixEmailResponse';
  isEmailVerified?: Maybe<Scalars['Boolean']['output']>;
};

export type ActionDataFragment = {
  __typename: 'ActionData';
  id: string;
  isFinished: boolean;
  status: FlowStatus;
  state?: string | null;
  dependencies?: Array<string | null> | null;
  requestActions?: Array<string> | null;
  type: string;
  url: string;
  validationUrl?: string | null;
  settings?: string | null;
  title: {
    __typename?: 'LocalisedText';
    translationKey: string;
    translationParams?: Array<{
      __typename?: 'LocalisedTextTranslationParams';
      name: string;
      value: string;
    } | null> | null;
  };
  shortTitle?: {
    __typename?: 'LocalisedText';
    translationKey: string;
    translationParams?: Array<{
      __typename?: 'LocalisedTextTranslationParams';
      name: string;
      value: string;
    } | null> | null;
  } | null;
  description?: {
    __typename?: 'LocalisedText';
    translationKey: string;
    translationParams?: Array<{
      __typename?: 'LocalisedTextTranslationParams';
      name: string;
      value: string;
    } | null> | null;
  } | null;
  triggers?: {
    __typename?: 'ActionTriggers';
    post: Array<{
      __typename?: 'ActionTrigger';
      actionId: string;
      mutation: string;
      execute: boolean;
    }>;
    pre: Array<{
      __typename?: 'ActionTrigger';
      actionId: string;
      mutation: string;
      execute: boolean;
    }>;
  } | null;
};

export type FlowDataFragment = {
  __typename: 'FlowData';
  id: string;
  isFinished: boolean;
  status: FlowStatus;
  redirectUrl?: string | null;
  dependencies?: Array<string | null> | null;
  type: string;
  flowType: FlowType;
  skipOverview?: boolean | null;
  onFinishUrl?: string | null;
  contactForm?: { __typename?: 'ContactForm'; missingIntegration?: Array<string> | null } | null;
  requests: Array<{
    __typename?: 'RequestData';
    requestId: string;
    status?: RequestStatus | null;
    actions?: Array<string> | null;
    expirationDateTime?: number | null;
    createdAt: number;
    flow?: { __typename?: 'FlowData'; id: string } | null;
  } | null>;
  title: {
    __typename?: 'LocalisedText';
    translationKey: string;
    translationParams?: Array<{
      __typename?: 'LocalisedTextTranslationParams';
      name: string;
      value: string;
    } | null> | null;
  };
  description?: {
    __typename?: 'LocalisedText';
    translationKey: string;
    translationParams?: Array<{
      __typename?: 'LocalisedTextTranslationParams';
      name: string;
      value: string;
    } | null> | null;
  } | null;
  shortTitle?: {
    __typename?: 'LocalisedText';
    translationKey: string;
    translationParams?: Array<{
      __typename?: 'LocalisedTextTranslationParams';
      name: string;
      value: string;
    } | null> | null;
  } | null;
};

export type GetUserFragment = {
  __typename?: 'User';
  id: string;
  displayName?: string | null;
  firstName?: string | null;
  lastName?: string | null;
  email?: string | null;
  partnerId?: string | null;
  betaTester: boolean;
  billingIban?: string | null;
  billingAccepted: boolean;
  active: boolean;
  partnerReference?: string | null;
  starredApps?: Array<string | null> | null;
  termsApproved: boolean;
  termsPaymentsApproved: boolean;
  identificationLevel: IdentificationLevel;
  phoneNumber?: string | null;
  language?: string | null;
};

export type AddBetaMemberToProgramsVariables = Exact<{
  betaProgramIds: Array<Scalars['String']['input']> | Scalars['String']['input'];
  memberId: Scalars['String']['input'];
  memberType: BetaProgramMemberType;
}>;

export type AddBetaMemberToPrograms = { __typename?: 'Mutation'; addBetaMemberToPrograms: boolean };

export type CreateBankAuthUrlVariables = Exact<{
  createBankAuthUrlArgs: CreateBankAuthUrlArgs;
}>;

export type CreateBankAuthUrl = {
  __typename?: 'Mutation';
  createBankAuthUrl?: { __typename?: 'CreateBankAuthUrlResponse'; authUrl: string } | null;
};

export type CreatePartnerAuthorizationVariables = Exact<{
  partnerId: Scalars['String']['input'];
  consent: PartnerConsent;
  autoConsent?: InputMaybe<Scalars['Boolean']['input']>;
  codeChallenge?: InputMaybe<Scalars['String']['input']>;
  partnerReference?: InputMaybe<Scalars['String']['input']>;
  requestId?: InputMaybe<Scalars['String']['input']>;
}>;

export type CreatePartnerAuthorization = {
  __typename?: 'Mutation';
  createPartnerAuthorization?:
    | { __typename: 'PartnerAuthorizationBaseResponse'; code: string; refreshToken?: string | null }
    | { __typename: 'PartnerBaseError'; message: string }
    | null;
};

export type CreateRequestVariables = Exact<{
  request: RequestInput;
}>;

export type CreateRequest = {
  __typename?: 'Mutation';
  createRequest: {
    __typename?: 'CreateRequestResponse';
    result: boolean;
    data?: {
      __typename?: 'RequestData';
      requestId: string;
      status?: RequestStatus | null;
      message: string;
      channel?: RequestChannel | null;
      from?: string | null;
      to?: string | null;
      actions?: Array<string> | null;
      periodicalType?: RequestPeriodicalType | null;
      expirationDateTime?: number | null;
      connectionType?: RequestConnectionType | null;
      partner?: {
        __typename?: 'Partner';
        id: string;
        name: string;
        supportEmail?: string | null;
        icon?: string | null;
        relation?: PartnerRelation | null;
        trustTermsAndConditions?: TrustTermsAndConditions | null;
        theme?: {
          __typename?: 'Theme';
          name?: string | null;
          color?: string | null;
          icon?: string | null;
        } | null;
      } | null;
      approver?: {
        __typename?: 'UserInfo';
        id: string;
        displayName?: string | null;
        email: string;
      } | null;
      requester?: {
        __typename?: 'UserInfo';
        id: string;
        displayName?: string | null;
        email: string;
      } | null;
      flow?: { __typename?: 'FlowData'; id: string } | null;
    } | null;
    error?: { __typename?: 'CreateRequestError'; key: string; message: string } | null;
  };
};

export type DeleteBankAccountVariables = Exact<{
  bankAccountId: Scalars['ID']['input'];
}>;

export type DeleteBankAccount = { __typename?: 'Mutation'; deleteBankAccount?: boolean | null };

export type FinishFlowActionVariables = Exact<{
  flowId: Scalars['String']['input'];
  actionId: Scalars['String']['input'];
  state?: InputMaybe<Scalars['AWSJSON']['input']>;
  requestId?: InputMaybe<Scalars['String']['input']>;
}>;

export type FinishFlowAction = {
  __typename?: 'Mutation';
  finishFlowAction?: {
    __typename: 'FlowData';
    id: string;
    isFinished: boolean;
    status: FlowStatus;
    redirectUrl?: string | null;
    dependencies?: Array<string | null> | null;
    type: string;
    flowType: FlowType;
    skipOverview?: boolean | null;
    onFinishUrl?: string | null;
    actions: Array<
      | {
          __typename: 'ActionData';
          id: string;
          isFinished: boolean;
          status: FlowStatus;
          state?: string | null;
          dependencies?: Array<string | null> | null;
          requestActions?: Array<string> | null;
          type: string;
          url: string;
          validationUrl?: string | null;
          settings?: string | null;
          title: {
            __typename?: 'LocalisedText';
            translationKey: string;
            translationParams?: Array<{
              __typename?: 'LocalisedTextTranslationParams';
              name: string;
              value: string;
            } | null> | null;
          };
          shortTitle?: {
            __typename?: 'LocalisedText';
            translationKey: string;
            translationParams?: Array<{
              __typename?: 'LocalisedTextTranslationParams';
              name: string;
              value: string;
            } | null> | null;
          } | null;
          description?: {
            __typename?: 'LocalisedText';
            translationKey: string;
            translationParams?: Array<{
              __typename?: 'LocalisedTextTranslationParams';
              name: string;
              value: string;
            } | null> | null;
          } | null;
          triggers?: {
            __typename?: 'ActionTriggers';
            post: Array<{
              __typename?: 'ActionTrigger';
              actionId: string;
              mutation: string;
              execute: boolean;
            }>;
            pre: Array<{
              __typename?: 'ActionTrigger';
              actionId: string;
              mutation: string;
              execute: boolean;
            }>;
          } | null;
        }
      | {
          __typename: 'FlowData';
          id: string;
          isFinished: boolean;
          status: FlowStatus;
          redirectUrl?: string | null;
          dependencies?: Array<string | null> | null;
          type: string;
          flowType: FlowType;
          skipOverview?: boolean | null;
          onFinishUrl?: string | null;
          actions: Array<
            | {
                __typename: 'ActionData';
                id: string;
                isFinished: boolean;
                status: FlowStatus;
                state?: string | null;
                dependencies?: Array<string | null> | null;
                requestActions?: Array<string> | null;
                type: string;
                url: string;
                validationUrl?: string | null;
                settings?: string | null;
                title: {
                  __typename?: 'LocalisedText';
                  translationKey: string;
                  translationParams?: Array<{
                    __typename?: 'LocalisedTextTranslationParams';
                    name: string;
                    value: string;
                  } | null> | null;
                };
                shortTitle?: {
                  __typename?: 'LocalisedText';
                  translationKey: string;
                  translationParams?: Array<{
                    __typename?: 'LocalisedTextTranslationParams';
                    name: string;
                    value: string;
                  } | null> | null;
                } | null;
                description?: {
                  __typename?: 'LocalisedText';
                  translationKey: string;
                  translationParams?: Array<{
                    __typename?: 'LocalisedTextTranslationParams';
                    name: string;
                    value: string;
                  } | null> | null;
                } | null;
                triggers?: {
                  __typename?: 'ActionTriggers';
                  post: Array<{
                    __typename?: 'ActionTrigger';
                    actionId: string;
                    mutation: string;
                    execute: boolean;
                  }>;
                  pre: Array<{
                    __typename?: 'ActionTrigger';
                    actionId: string;
                    mutation: string;
                    execute: boolean;
                  }>;
                } | null;
              }
            | {
                __typename: 'FlowData';
                id: string;
                isFinished: boolean;
                status: FlowStatus;
                redirectUrl?: string | null;
                dependencies?: Array<string | null> | null;
                type: string;
                flowType: FlowType;
                skipOverview?: boolean | null;
                onFinishUrl?: string | null;
                actions: Array<
                  | {
                      __typename: 'ActionData';
                      id: string;
                      isFinished: boolean;
                      status: FlowStatus;
                      state?: string | null;
                      dependencies?: Array<string | null> | null;
                      requestActions?: Array<string> | null;
                      type: string;
                      url: string;
                      validationUrl?: string | null;
                      settings?: string | null;
                      title: {
                        __typename?: 'LocalisedText';
                        translationKey: string;
                        translationParams?: Array<{
                          __typename?: 'LocalisedTextTranslationParams';
                          name: string;
                          value: string;
                        } | null> | null;
                      };
                      shortTitle?: {
                        __typename?: 'LocalisedText';
                        translationKey: string;
                        translationParams?: Array<{
                          __typename?: 'LocalisedTextTranslationParams';
                          name: string;
                          value: string;
                        } | null> | null;
                      } | null;
                      description?: {
                        __typename?: 'LocalisedText';
                        translationKey: string;
                        translationParams?: Array<{
                          __typename?: 'LocalisedTextTranslationParams';
                          name: string;
                          value: string;
                        } | null> | null;
                      } | null;
                      triggers?: {
                        __typename?: 'ActionTriggers';
                        post: Array<{
                          __typename?: 'ActionTrigger';
                          actionId: string;
                          mutation: string;
                          execute: boolean;
                        }>;
                        pre: Array<{
                          __typename?: 'ActionTrigger';
                          actionId: string;
                          mutation: string;
                          execute: boolean;
                        }>;
                      } | null;
                    }
                  | { __typename?: 'FlowData' }
                >;
                contactForm?: {
                  __typename?: 'ContactForm';
                  missingIntegration?: Array<string> | null;
                } | null;
                requests: Array<{
                  __typename?: 'RequestData';
                  requestId: string;
                  status?: RequestStatus | null;
                  actions?: Array<string> | null;
                  expirationDateTime?: number | null;
                  createdAt: number;
                  flow?: { __typename?: 'FlowData'; id: string } | null;
                } | null>;
                title: {
                  __typename?: 'LocalisedText';
                  translationKey: string;
                  translationParams?: Array<{
                    __typename?: 'LocalisedTextTranslationParams';
                    name: string;
                    value: string;
                  } | null> | null;
                };
                description?: {
                  __typename?: 'LocalisedText';
                  translationKey: string;
                  translationParams?: Array<{
                    __typename?: 'LocalisedTextTranslationParams';
                    name: string;
                    value: string;
                  } | null> | null;
                } | null;
                shortTitle?: {
                  __typename?: 'LocalisedText';
                  translationKey: string;
                  translationParams?: Array<{
                    __typename?: 'LocalisedTextTranslationParams';
                    name: string;
                    value: string;
                  } | null> | null;
                } | null;
              }
          >;
          contactForm?: {
            __typename?: 'ContactForm';
            missingIntegration?: Array<string> | null;
          } | null;
          requests: Array<{
            __typename?: 'RequestData';
            requestId: string;
            status?: RequestStatus | null;
            actions?: Array<string> | null;
            expirationDateTime?: number | null;
            createdAt: number;
            flow?: { __typename?: 'FlowData'; id: string } | null;
          } | null>;
          title: {
            __typename?: 'LocalisedText';
            translationKey: string;
            translationParams?: Array<{
              __typename?: 'LocalisedTextTranslationParams';
              name: string;
              value: string;
            } | null> | null;
          };
          description?: {
            __typename?: 'LocalisedText';
            translationKey: string;
            translationParams?: Array<{
              __typename?: 'LocalisedTextTranslationParams';
              name: string;
              value: string;
            } | null> | null;
          } | null;
          shortTitle?: {
            __typename?: 'LocalisedText';
            translationKey: string;
            translationParams?: Array<{
              __typename?: 'LocalisedTextTranslationParams';
              name: string;
              value: string;
            } | null> | null;
          } | null;
        }
    >;
    contactForm?: { __typename?: 'ContactForm'; missingIntegration?: Array<string> | null } | null;
    requests: Array<{
      __typename?: 'RequestData';
      requestId: string;
      status?: RequestStatus | null;
      actions?: Array<string> | null;
      expirationDateTime?: number | null;
      createdAt: number;
      flow?: { __typename?: 'FlowData'; id: string } | null;
    } | null>;
    title: {
      __typename?: 'LocalisedText';
      translationKey: string;
      translationParams?: Array<{
        __typename?: 'LocalisedTextTranslationParams';
        name: string;
        value: string;
      } | null> | null;
    };
    description?: {
      __typename?: 'LocalisedText';
      translationKey: string;
      translationParams?: Array<{
        __typename?: 'LocalisedTextTranslationParams';
        name: string;
        value: string;
      } | null> | null;
    } | null;
    shortTitle?: {
      __typename?: 'LocalisedText';
      translationKey: string;
      translationParams?: Array<{
        __typename?: 'LocalisedTextTranslationParams';
        name: string;
        value: string;
      } | null> | null;
    } | null;
  } | null;
};

export type ProcessBankTransactionsVariables = Exact<{
  processBankTransactionsArgs: ProcessBankTransactionsArgs;
}>;

export type ProcessBankTransactions = {
  __typename?: 'Mutation';
  processBankTransactions?: boolean | null;
};

export type PutFinancedInvoiceVariables = Exact<{
  putFinancedInvoiceArgs: FinancedInvoiceInput;
  updateInvoiceFinanceStateArgs: InvoiceFinanceStateUpdateInput;
}>;

export type PutFinancedInvoice = {
  __typename?: 'Mutation';
  putFinancedInvoice?: {
    __typename?: 'FinancedInvoice';
    invoiceId: string;
    administrationId: string;
    partnerId?: string | null;
  } | null;
  updateInvoiceFinanceState?: {
    __typename?: 'InvoiceFinanceState';
    firstName: string;
    lastName: string;
    emailAddress: string;
    phoneNumber: string;
    completedAmount: number;
    createdAt: Date;
    updatedAt: Date;
  } | null;
};

export type PutInvoiceFinanceStateVariables = Exact<{ [key: string]: never }>;

export type PutInvoiceFinanceState = {
  __typename?: 'Mutation';
  putInvoiceFinanceState?: {
    __typename?: 'InvoiceFinanceState';
    completedAmount: number;
    createdAt: Date;
    updatedAt: Date;
  } | null;
};

export type SendContactRequestVariables = Exact<{
  sendContactRequestArgs: SendContactRequestArgs;
}>;

export type SendContactRequest = {
  __typename?: 'Mutation';
  sendContactRequest?: { __typename?: 'SendContactRequestResponse'; status: string } | null;
};

export type SendFormVariables = Exact<{
  fields?: InputMaybe<Scalars['AWSJSON']['input']>;
}>;

export type SendForm = { __typename?: 'Mutation'; sendForm?: boolean | null };

export type SendPreliminaryOfferVariables = Exact<{
  preliminaryOffer: SendPreliminaryOfferArgs;
}>;

export type SendPreliminaryOffer = {
  __typename?: 'Mutation';
  sendPreliminaryOffer?:
    | {
        __typename: 'RaboValidationError';
        code?: string | null;
        errorCode: string;
        message?: string | null;
        invalidFields?: Array<{
          __typename?: 'FieldError';
          code?: string | null;
          field?: string | null;
          rejectedValue?: string | null;
        } | null> | null;
      }
    | {
        __typename: 'SendPreliminaryOfferResponse';
        message?: string | null;
        offer?: {
          __typename?: 'SendPreliminaryOfferResponseOffer';
          duration: number;
          interestCost: number;
          interestRate: number;
          invoiceAmount: number;
          loanAmount: number;
        } | null;
      }
    | null;
};

export type SendRequestVariables = Exact<{
  requestId: Scalars['String']['input'];
}>;

export type SendRequest = { __typename?: 'Mutation'; sendRequest?: boolean | null };

export type StartFlowVariables = Exact<{
  flowId: Scalars['String']['input'];
  requestId?: InputMaybe<Scalars['String']['input']>;
  redirectUrl?: InputMaybe<Scalars['String']['input']>;
}>;

export type StartFlow = {
  __typename?: 'Mutation';
  startFlow?: {
    __typename: 'FlowData';
    id: string;
    isFinished: boolean;
    status: FlowStatus;
    redirectUrl?: string | null;
    dependencies?: Array<string | null> | null;
    type: string;
    flowType: FlowType;
    skipOverview?: boolean | null;
    onFinishUrl?: string | null;
    actions: Array<
      | {
          __typename: 'ActionData';
          id: string;
          isFinished: boolean;
          status: FlowStatus;
          state?: string | null;
          dependencies?: Array<string | null> | null;
          requestActions?: Array<string> | null;
          type: string;
          url: string;
          validationUrl?: string | null;
          settings?: string | null;
          title: {
            __typename?: 'LocalisedText';
            translationKey: string;
            translationParams?: Array<{
              __typename?: 'LocalisedTextTranslationParams';
              name: string;
              value: string;
            } | null> | null;
          };
          shortTitle?: {
            __typename?: 'LocalisedText';
            translationKey: string;
            translationParams?: Array<{
              __typename?: 'LocalisedTextTranslationParams';
              name: string;
              value: string;
            } | null> | null;
          } | null;
          description?: {
            __typename?: 'LocalisedText';
            translationKey: string;
            translationParams?: Array<{
              __typename?: 'LocalisedTextTranslationParams';
              name: string;
              value: string;
            } | null> | null;
          } | null;
          triggers?: {
            __typename?: 'ActionTriggers';
            post: Array<{
              __typename?: 'ActionTrigger';
              actionId: string;
              mutation: string;
              execute: boolean;
            }>;
            pre: Array<{
              __typename?: 'ActionTrigger';
              actionId: string;
              mutation: string;
              execute: boolean;
            }>;
          } | null;
        }
      | {
          __typename: 'FlowData';
          id: string;
          isFinished: boolean;
          status: FlowStatus;
          redirectUrl?: string | null;
          dependencies?: Array<string | null> | null;
          type: string;
          flowType: FlowType;
          skipOverview?: boolean | null;
          onFinishUrl?: string | null;
          actions: Array<
            | {
                __typename: 'ActionData';
                id: string;
                isFinished: boolean;
                status: FlowStatus;
                state?: string | null;
                dependencies?: Array<string | null> | null;
                requestActions?: Array<string> | null;
                type: string;
                url: string;
                validationUrl?: string | null;
                settings?: string | null;
                title: {
                  __typename?: 'LocalisedText';
                  translationKey: string;
                  translationParams?: Array<{
                    __typename?: 'LocalisedTextTranslationParams';
                    name: string;
                    value: string;
                  } | null> | null;
                };
                shortTitle?: {
                  __typename?: 'LocalisedText';
                  translationKey: string;
                  translationParams?: Array<{
                    __typename?: 'LocalisedTextTranslationParams';
                    name: string;
                    value: string;
                  } | null> | null;
                } | null;
                description?: {
                  __typename?: 'LocalisedText';
                  translationKey: string;
                  translationParams?: Array<{
                    __typename?: 'LocalisedTextTranslationParams';
                    name: string;
                    value: string;
                  } | null> | null;
                } | null;
                triggers?: {
                  __typename?: 'ActionTriggers';
                  post: Array<{
                    __typename?: 'ActionTrigger';
                    actionId: string;
                    mutation: string;
                    execute: boolean;
                  }>;
                  pre: Array<{
                    __typename?: 'ActionTrigger';
                    actionId: string;
                    mutation: string;
                    execute: boolean;
                  }>;
                } | null;
              }
            | {
                __typename: 'FlowData';
                id: string;
                isFinished: boolean;
                status: FlowStatus;
                redirectUrl?: string | null;
                dependencies?: Array<string | null> | null;
                type: string;
                flowType: FlowType;
                skipOverview?: boolean | null;
                onFinishUrl?: string | null;
                actions: Array<
                  | {
                      __typename: 'ActionData';
                      id: string;
                      isFinished: boolean;
                      status: FlowStatus;
                      state?: string | null;
                      dependencies?: Array<string | null> | null;
                      requestActions?: Array<string> | null;
                      type: string;
                      url: string;
                      validationUrl?: string | null;
                      settings?: string | null;
                      title: {
                        __typename?: 'LocalisedText';
                        translationKey: string;
                        translationParams?: Array<{
                          __typename?: 'LocalisedTextTranslationParams';
                          name: string;
                          value: string;
                        } | null> | null;
                      };
                      shortTitle?: {
                        __typename?: 'LocalisedText';
                        translationKey: string;
                        translationParams?: Array<{
                          __typename?: 'LocalisedTextTranslationParams';
                          name: string;
                          value: string;
                        } | null> | null;
                      } | null;
                      description?: {
                        __typename?: 'LocalisedText';
                        translationKey: string;
                        translationParams?: Array<{
                          __typename?: 'LocalisedTextTranslationParams';
                          name: string;
                          value: string;
                        } | null> | null;
                      } | null;
                      triggers?: {
                        __typename?: 'ActionTriggers';
                        post: Array<{
                          __typename?: 'ActionTrigger';
                          actionId: string;
                          mutation: string;
                          execute: boolean;
                        }>;
                        pre: Array<{
                          __typename?: 'ActionTrigger';
                          actionId: string;
                          mutation: string;
                          execute: boolean;
                        }>;
                      } | null;
                    }
                  | { __typename?: 'FlowData' }
                >;
                contactForm?: {
                  __typename?: 'ContactForm';
                  missingIntegration?: Array<string> | null;
                } | null;
                requests: Array<{
                  __typename?: 'RequestData';
                  requestId: string;
                  status?: RequestStatus | null;
                  actions?: Array<string> | null;
                  expirationDateTime?: number | null;
                  createdAt: number;
                  flow?: { __typename?: 'FlowData'; id: string } | null;
                } | null>;
                title: {
                  __typename?: 'LocalisedText';
                  translationKey: string;
                  translationParams?: Array<{
                    __typename?: 'LocalisedTextTranslationParams';
                    name: string;
                    value: string;
                  } | null> | null;
                };
                description?: {
                  __typename?: 'LocalisedText';
                  translationKey: string;
                  translationParams?: Array<{
                    __typename?: 'LocalisedTextTranslationParams';
                    name: string;
                    value: string;
                  } | null> | null;
                } | null;
                shortTitle?: {
                  __typename?: 'LocalisedText';
                  translationKey: string;
                  translationParams?: Array<{
                    __typename?: 'LocalisedTextTranslationParams';
                    name: string;
                    value: string;
                  } | null> | null;
                } | null;
              }
          >;
          contactForm?: {
            __typename?: 'ContactForm';
            missingIntegration?: Array<string> | null;
          } | null;
          requests: Array<{
            __typename?: 'RequestData';
            requestId: string;
            status?: RequestStatus | null;
            actions?: Array<string> | null;
            expirationDateTime?: number | null;
            createdAt: number;
            flow?: { __typename?: 'FlowData'; id: string } | null;
          } | null>;
          title: {
            __typename?: 'LocalisedText';
            translationKey: string;
            translationParams?: Array<{
              __typename?: 'LocalisedTextTranslationParams';
              name: string;
              value: string;
            } | null> | null;
          };
          description?: {
            __typename?: 'LocalisedText';
            translationKey: string;
            translationParams?: Array<{
              __typename?: 'LocalisedTextTranslationParams';
              name: string;
              value: string;
            } | null> | null;
          } | null;
          shortTitle?: {
            __typename?: 'LocalisedText';
            translationKey: string;
            translationParams?: Array<{
              __typename?: 'LocalisedTextTranslationParams';
              name: string;
              value: string;
            } | null> | null;
          } | null;
        }
    >;
    contactForm?: { __typename?: 'ContactForm'; missingIntegration?: Array<string> | null } | null;
    requests: Array<{
      __typename?: 'RequestData';
      requestId: string;
      status?: RequestStatus | null;
      actions?: Array<string> | null;
      expirationDateTime?: number | null;
      createdAt: number;
      flow?: { __typename?: 'FlowData'; id: string } | null;
    } | null>;
    title: {
      __typename?: 'LocalisedText';
      translationKey: string;
      translationParams?: Array<{
        __typename?: 'LocalisedTextTranslationParams';
        name: string;
        value: string;
      } | null> | null;
    };
    description?: {
      __typename?: 'LocalisedText';
      translationKey: string;
      translationParams?: Array<{
        __typename?: 'LocalisedTextTranslationParams';
        name: string;
        value: string;
      } | null> | null;
    } | null;
    shortTitle?: {
      __typename?: 'LocalisedText';
      translationKey: string;
      translationParams?: Array<{
        __typename?: 'LocalisedTextTranslationParams';
        name: string;
        value: string;
      } | null> | null;
    } | null;
  } | null;
};

export type UninstallPartnerAppVariables = Exact<{
  partnerId: Scalars['String']['input'];
  requestId?: InputMaybe<Scalars['String']['input']>;
}>;

export type UninstallPartnerApp = {
  __typename?: 'Mutation';
  uninstallPartnerApp?:
    | { __typename?: 'PartnerBaseError'; message: string }
    | { __typename?: 'UninstallPartnerAppBaseResponse'; result: boolean }
    | null;
};

export type UpdateActionStateVariables = Exact<{
  actionId: Scalars['String']['input'];
  flowId: Scalars['String']['input'];
  state: ActionState;
  requestId?: InputMaybe<Scalars['String']['input']>;
}>;

export type UpdateActionState = {
  __typename?: 'Mutation';
  updateActionState?: {
    __typename?: 'UpdateActionStateResponse';
    result: boolean;
    flowData: {
      __typename: 'FlowData';
      id: string;
      isFinished: boolean;
      status: FlowStatus;
      redirectUrl?: string | null;
      dependencies?: Array<string | null> | null;
      type: string;
      flowType: FlowType;
      skipOverview?: boolean | null;
      onFinishUrl?: string | null;
      actions: Array<
        | {
            __typename: 'ActionData';
            id: string;
            isFinished: boolean;
            status: FlowStatus;
            state?: string | null;
            dependencies?: Array<string | null> | null;
            requestActions?: Array<string> | null;
            type: string;
            url: string;
            validationUrl?: string | null;
            settings?: string | null;
            title: {
              __typename?: 'LocalisedText';
              translationKey: string;
              translationParams?: Array<{
                __typename?: 'LocalisedTextTranslationParams';
                name: string;
                value: string;
              } | null> | null;
            };
            shortTitle?: {
              __typename?: 'LocalisedText';
              translationKey: string;
              translationParams?: Array<{
                __typename?: 'LocalisedTextTranslationParams';
                name: string;
                value: string;
              } | null> | null;
            } | null;
            description?: {
              __typename?: 'LocalisedText';
              translationKey: string;
              translationParams?: Array<{
                __typename?: 'LocalisedTextTranslationParams';
                name: string;
                value: string;
              } | null> | null;
            } | null;
            triggers?: {
              __typename?: 'ActionTriggers';
              post: Array<{
                __typename?: 'ActionTrigger';
                actionId: string;
                mutation: string;
                execute: boolean;
              }>;
              pre: Array<{
                __typename?: 'ActionTrigger';
                actionId: string;
                mutation: string;
                execute: boolean;
              }>;
            } | null;
          }
        | {
            __typename: 'FlowData';
            id: string;
            isFinished: boolean;
            status: FlowStatus;
            redirectUrl?: string | null;
            dependencies?: Array<string | null> | null;
            type: string;
            flowType: FlowType;
            skipOverview?: boolean | null;
            onFinishUrl?: string | null;
            actions: Array<
              | {
                  __typename: 'ActionData';
                  id: string;
                  isFinished: boolean;
                  status: FlowStatus;
                  state?: string | null;
                  dependencies?: Array<string | null> | null;
                  requestActions?: Array<string> | null;
                  type: string;
                  url: string;
                  validationUrl?: string | null;
                  settings?: string | null;
                  title: {
                    __typename?: 'LocalisedText';
                    translationKey: string;
                    translationParams?: Array<{
                      __typename?: 'LocalisedTextTranslationParams';
                      name: string;
                      value: string;
                    } | null> | null;
                  };
                  shortTitle?: {
                    __typename?: 'LocalisedText';
                    translationKey: string;
                    translationParams?: Array<{
                      __typename?: 'LocalisedTextTranslationParams';
                      name: string;
                      value: string;
                    } | null> | null;
                  } | null;
                  description?: {
                    __typename?: 'LocalisedText';
                    translationKey: string;
                    translationParams?: Array<{
                      __typename?: 'LocalisedTextTranslationParams';
                      name: string;
                      value: string;
                    } | null> | null;
                  } | null;
                  triggers?: {
                    __typename?: 'ActionTriggers';
                    post: Array<{
                      __typename?: 'ActionTrigger';
                      actionId: string;
                      mutation: string;
                      execute: boolean;
                    }>;
                    pre: Array<{
                      __typename?: 'ActionTrigger';
                      actionId: string;
                      mutation: string;
                      execute: boolean;
                    }>;
                  } | null;
                }
              | {
                  __typename: 'FlowData';
                  id: string;
                  isFinished: boolean;
                  status: FlowStatus;
                  redirectUrl?: string | null;
                  dependencies?: Array<string | null> | null;
                  type: string;
                  flowType: FlowType;
                  skipOverview?: boolean | null;
                  onFinishUrl?: string | null;
                  actions: Array<
                    | {
                        __typename: 'ActionData';
                        id: string;
                        isFinished: boolean;
                        status: FlowStatus;
                        state?: string | null;
                        dependencies?: Array<string | null> | null;
                        requestActions?: Array<string> | null;
                        type: string;
                        url: string;
                        validationUrl?: string | null;
                        settings?: string | null;
                        title: {
                          __typename?: 'LocalisedText';
                          translationKey: string;
                          translationParams?: Array<{
                            __typename?: 'LocalisedTextTranslationParams';
                            name: string;
                            value: string;
                          } | null> | null;
                        };
                        shortTitle?: {
                          __typename?: 'LocalisedText';
                          translationKey: string;
                          translationParams?: Array<{
                            __typename?: 'LocalisedTextTranslationParams';
                            name: string;
                            value: string;
                          } | null> | null;
                        } | null;
                        description?: {
                          __typename?: 'LocalisedText';
                          translationKey: string;
                          translationParams?: Array<{
                            __typename?: 'LocalisedTextTranslationParams';
                            name: string;
                            value: string;
                          } | null> | null;
                        } | null;
                        triggers?: {
                          __typename?: 'ActionTriggers';
                          post: Array<{
                            __typename?: 'ActionTrigger';
                            actionId: string;
                            mutation: string;
                            execute: boolean;
                          }>;
                          pre: Array<{
                            __typename?: 'ActionTrigger';
                            actionId: string;
                            mutation: string;
                            execute: boolean;
                          }>;
                        } | null;
                      }
                    | { __typename?: 'FlowData' }
                  >;
                  contactForm?: {
                    __typename?: 'ContactForm';
                    missingIntegration?: Array<string> | null;
                  } | null;
                  requests: Array<{
                    __typename?: 'RequestData';
                    requestId: string;
                    status?: RequestStatus | null;
                    actions?: Array<string> | null;
                    expirationDateTime?: number | null;
                    createdAt: number;
                    flow?: { __typename?: 'FlowData'; id: string } | null;
                  } | null>;
                  title: {
                    __typename?: 'LocalisedText';
                    translationKey: string;
                    translationParams?: Array<{
                      __typename?: 'LocalisedTextTranslationParams';
                      name: string;
                      value: string;
                    } | null> | null;
                  };
                  description?: {
                    __typename?: 'LocalisedText';
                    translationKey: string;
                    translationParams?: Array<{
                      __typename?: 'LocalisedTextTranslationParams';
                      name: string;
                      value: string;
                    } | null> | null;
                  } | null;
                  shortTitle?: {
                    __typename?: 'LocalisedText';
                    translationKey: string;
                    translationParams?: Array<{
                      __typename?: 'LocalisedTextTranslationParams';
                      name: string;
                      value: string;
                    } | null> | null;
                  } | null;
                }
            >;
            contactForm?: {
              __typename?: 'ContactForm';
              missingIntegration?: Array<string> | null;
            } | null;
            requests: Array<{
              __typename?: 'RequestData';
              requestId: string;
              status?: RequestStatus | null;
              actions?: Array<string> | null;
              expirationDateTime?: number | null;
              createdAt: number;
              flow?: { __typename?: 'FlowData'; id: string } | null;
            } | null>;
            title: {
              __typename?: 'LocalisedText';
              translationKey: string;
              translationParams?: Array<{
                __typename?: 'LocalisedTextTranslationParams';
                name: string;
                value: string;
              } | null> | null;
            };
            description?: {
              __typename?: 'LocalisedText';
              translationKey: string;
              translationParams?: Array<{
                __typename?: 'LocalisedTextTranslationParams';
                name: string;
                value: string;
              } | null> | null;
            } | null;
            shortTitle?: {
              __typename?: 'LocalisedText';
              translationKey: string;
              translationParams?: Array<{
                __typename?: 'LocalisedTextTranslationParams';
                name: string;
                value: string;
              } | null> | null;
            } | null;
          }
      >;
      contactForm?: {
        __typename?: 'ContactForm';
        missingIntegration?: Array<string> | null;
      } | null;
      requests: Array<{
        __typename?: 'RequestData';
        requestId: string;
        status?: RequestStatus | null;
        actions?: Array<string> | null;
        expirationDateTime?: number | null;
        createdAt: number;
        flow?: { __typename?: 'FlowData'; id: string } | null;
      } | null>;
      title: {
        __typename?: 'LocalisedText';
        translationKey: string;
        translationParams?: Array<{
          __typename?: 'LocalisedTextTranslationParams';
          name: string;
          value: string;
        } | null> | null;
      };
      description?: {
        __typename?: 'LocalisedText';
        translationKey: string;
        translationParams?: Array<{
          __typename?: 'LocalisedTextTranslationParams';
          name: string;
          value: string;
        } | null> | null;
      } | null;
      shortTitle?: {
        __typename?: 'LocalisedText';
        translationKey: string;
        translationParams?: Array<{
          __typename?: 'LocalisedTextTranslationParams';
          name: string;
          value: string;
        } | null> | null;
      } | null;
    };
  } | null;
};

export type UpdateAdministrationVariables = Exact<{
  updateAdministrationArgs: UpdateAdministrationArgs;
}>;

export type UpdateAdministration = {
  __typename?: 'Mutation';
  updateAdministration?: {
    __typename?: 'Administration';
    id: string;
    deleted: boolean;
    kvkNumber?: string | null;
    name: string;
    newDocumentCount?: number | null;
    scanEmail?: string | null;
    sendUbl: boolean;
  } | null;
};

export type UpdateAdministrationsVariables = Exact<{
  updateAdministrationsArgs?: InputMaybe<
    Array<UpdateAdministrationArgs> | UpdateAdministrationArgs
  >;
}>;

export type UpdateAdministrations = {
  __typename?: 'Mutation';
  updateAdministrations: Array<{
    __typename?: 'Administration';
    id: string;
    deleted: boolean;
    kvkNumber?: string | null;
    name: string;
    newDocumentCount?: number | null;
    scanEmail?: string | null;
    sendUbl: boolean;
  }>;
};

export type UpdateRequestVariables = Exact<{
  requestId: Scalars['String']['input'];
  status: RequestStatus;
  reminderDateTime?: InputMaybe<Scalars['AWSTimestamp']['input']>;
}>;

export type UpdateRequest = {
  __typename?: 'Mutation';
  updateRequest: {
    __typename?: 'UpdateRequestResponse';
    result: boolean;
    error?: { __typename?: 'CreateRequestError'; key: string; message: string } | null;
  };
};

export type UpdateUserVariables = Exact<{
  updateUserArgs: UpdateUserArgs;
}>;

export type UpdateUser = {
  __typename?: 'Mutation';
  updateUser?: {
    __typename?: 'User';
    id: string;
    displayName?: string | null;
    firstName?: string | null;
    lastName?: string | null;
    email?: string | null;
    partnerId?: string | null;
    betaTester: boolean;
    billingIban?: string | null;
    billingAccepted: boolean;
    active: boolean;
    partnerReference?: string | null;
    starredApps?: Array<string | null> | null;
    termsApproved: boolean;
    termsPaymentsApproved: boolean;
    identificationLevel: IdentificationLevel;
    phoneNumber?: string | null;
    language?: string | null;
  } | null;
};

export type AuthorizeAdminEmbeddedFlowsTesterVariables = Exact<{ [key: string]: never }>;

export type AuthorizeAdminEmbeddedFlowsTester = {
  __typename?: 'Query';
  authorizeAdminEmbeddedFlowsTester?: boolean | null;
};

export type InitiateHixAccountConnectionVariables = Exact<{
  setupToken: Scalars['String']['input'];
  clientDomain: Scalars['String']['input'];
}>;

export type InitiateHixAccountConnection = {
  __typename?: 'Query';
  initiateHixAccountConnection?: {
    __typename?: 'InitiateHixAccountConnectionResponse';
    isInitiationSuccessful?: boolean | null;
  } | null;
};

export type FinishConnectingHixAccountVariables = Exact<{
  otp: Scalars['String']['input'];
}>;

export type FinishConnectingHixAccount = {
  __typename?: 'Query';
  finishConnectingHixAccount?: {
    __typename?: 'FinishConnectingHixAccountResponse';
    isAccountConnected: boolean;
    frontendUrl?: string | null;
  } | null;
};

export type GetActiveBetaProgramsVariables = Exact<{ [key: string]: never }>;

export type GetActiveBetaPrograms = {
  __typename?: 'Query';
  getActiveBetaPrograms: Array<{
    __typename?: 'BetaProgram';
    betaProgramId: string;
    title: string;
  } | null>;
};

export type GetActiveFlowVariables = Exact<{
  requestId?: InputMaybe<Scalars['String']['input']>;
}>;

export type GetActiveFlow = {
  __typename?: 'Query';
  getActiveFlow?: {
    __typename: 'FlowData';
    id: string;
    isFinished: boolean;
    status: FlowStatus;
    redirectUrl?: string | null;
    dependencies?: Array<string | null> | null;
    type: string;
    flowType: FlowType;
    skipOverview?: boolean | null;
    onFinishUrl?: string | null;
    actions: Array<
      | {
          __typename: 'ActionData';
          id: string;
          isFinished: boolean;
          status: FlowStatus;
          state?: string | null;
          dependencies?: Array<string | null> | null;
          requestActions?: Array<string> | null;
          type: string;
          url: string;
          validationUrl?: string | null;
          settings?: string | null;
          title: {
            __typename?: 'LocalisedText';
            translationKey: string;
            translationParams?: Array<{
              __typename?: 'LocalisedTextTranslationParams';
              name: string;
              value: string;
            } | null> | null;
          };
          shortTitle?: {
            __typename?: 'LocalisedText';
            translationKey: string;
            translationParams?: Array<{
              __typename?: 'LocalisedTextTranslationParams';
              name: string;
              value: string;
            } | null> | null;
          } | null;
          description?: {
            __typename?: 'LocalisedText';
            translationKey: string;
            translationParams?: Array<{
              __typename?: 'LocalisedTextTranslationParams';
              name: string;
              value: string;
            } | null> | null;
          } | null;
          triggers?: {
            __typename?: 'ActionTriggers';
            post: Array<{
              __typename?: 'ActionTrigger';
              actionId: string;
              mutation: string;
              execute: boolean;
            }>;
            pre: Array<{
              __typename?: 'ActionTrigger';
              actionId: string;
              mutation: string;
              execute: boolean;
            }>;
          } | null;
        }
      | {
          __typename: 'FlowData';
          id: string;
          isFinished: boolean;
          status: FlowStatus;
          redirectUrl?: string | null;
          dependencies?: Array<string | null> | null;
          type: string;
          flowType: FlowType;
          skipOverview?: boolean | null;
          onFinishUrl?: string | null;
          actions: Array<
            | {
                __typename: 'ActionData';
                id: string;
                isFinished: boolean;
                status: FlowStatus;
                state?: string | null;
                dependencies?: Array<string | null> | null;
                requestActions?: Array<string> | null;
                type: string;
                url: string;
                validationUrl?: string | null;
                settings?: string | null;
                title: {
                  __typename?: 'LocalisedText';
                  translationKey: string;
                  translationParams?: Array<{
                    __typename?: 'LocalisedTextTranslationParams';
                    name: string;
                    value: string;
                  } | null> | null;
                };
                shortTitle?: {
                  __typename?: 'LocalisedText';
                  translationKey: string;
                  translationParams?: Array<{
                    __typename?: 'LocalisedTextTranslationParams';
                    name: string;
                    value: string;
                  } | null> | null;
                } | null;
                description?: {
                  __typename?: 'LocalisedText';
                  translationKey: string;
                  translationParams?: Array<{
                    __typename?: 'LocalisedTextTranslationParams';
                    name: string;
                    value: string;
                  } | null> | null;
                } | null;
                triggers?: {
                  __typename?: 'ActionTriggers';
                  post: Array<{
                    __typename?: 'ActionTrigger';
                    actionId: string;
                    mutation: string;
                    execute: boolean;
                  }>;
                  pre: Array<{
                    __typename?: 'ActionTrigger';
                    actionId: string;
                    mutation: string;
                    execute: boolean;
                  }>;
                } | null;
              }
            | {
                __typename: 'FlowData';
                id: string;
                isFinished: boolean;
                status: FlowStatus;
                redirectUrl?: string | null;
                dependencies?: Array<string | null> | null;
                type: string;
                flowType: FlowType;
                skipOverview?: boolean | null;
                onFinishUrl?: string | null;
                actions: Array<
                  | {
                      __typename: 'ActionData';
                      id: string;
                      isFinished: boolean;
                      status: FlowStatus;
                      state?: string | null;
                      dependencies?: Array<string | null> | null;
                      requestActions?: Array<string> | null;
                      type: string;
                      url: string;
                      validationUrl?: string | null;
                      settings?: string | null;
                      title: {
                        __typename?: 'LocalisedText';
                        translationKey: string;
                        translationParams?: Array<{
                          __typename?: 'LocalisedTextTranslationParams';
                          name: string;
                          value: string;
                        } | null> | null;
                      };
                      shortTitle?: {
                        __typename?: 'LocalisedText';
                        translationKey: string;
                        translationParams?: Array<{
                          __typename?: 'LocalisedTextTranslationParams';
                          name: string;
                          value: string;
                        } | null> | null;
                      } | null;
                      description?: {
                        __typename?: 'LocalisedText';
                        translationKey: string;
                        translationParams?: Array<{
                          __typename?: 'LocalisedTextTranslationParams';
                          name: string;
                          value: string;
                        } | null> | null;
                      } | null;
                      triggers?: {
                        __typename?: 'ActionTriggers';
                        post: Array<{
                          __typename?: 'ActionTrigger';
                          actionId: string;
                          mutation: string;
                          execute: boolean;
                        }>;
                        pre: Array<{
                          __typename?: 'ActionTrigger';
                          actionId: string;
                          mutation: string;
                          execute: boolean;
                        }>;
                      } | null;
                    }
                  | { __typename?: 'FlowData' }
                >;
                contactForm?: {
                  __typename?: 'ContactForm';
                  missingIntegration?: Array<string> | null;
                } | null;
                requests: Array<{
                  __typename?: 'RequestData';
                  requestId: string;
                  status?: RequestStatus | null;
                  actions?: Array<string> | null;
                  expirationDateTime?: number | null;
                  createdAt: number;
                  flow?: { __typename?: 'FlowData'; id: string } | null;
                } | null>;
                title: {
                  __typename?: 'LocalisedText';
                  translationKey: string;
                  translationParams?: Array<{
                    __typename?: 'LocalisedTextTranslationParams';
                    name: string;
                    value: string;
                  } | null> | null;
                };
                description?: {
                  __typename?: 'LocalisedText';
                  translationKey: string;
                  translationParams?: Array<{
                    __typename?: 'LocalisedTextTranslationParams';
                    name: string;
                    value: string;
                  } | null> | null;
                } | null;
                shortTitle?: {
                  __typename?: 'LocalisedText';
                  translationKey: string;
                  translationParams?: Array<{
                    __typename?: 'LocalisedTextTranslationParams';
                    name: string;
                    value: string;
                  } | null> | null;
                } | null;
              }
          >;
          contactForm?: {
            __typename?: 'ContactForm';
            missingIntegration?: Array<string> | null;
          } | null;
          requests: Array<{
            __typename?: 'RequestData';
            requestId: string;
            status?: RequestStatus | null;
            actions?: Array<string> | null;
            expirationDateTime?: number | null;
            createdAt: number;
            flow?: { __typename?: 'FlowData'; id: string } | null;
          } | null>;
          title: {
            __typename?: 'LocalisedText';
            translationKey: string;
            translationParams?: Array<{
              __typename?: 'LocalisedTextTranslationParams';
              name: string;
              value: string;
            } | null> | null;
          };
          description?: {
            __typename?: 'LocalisedText';
            translationKey: string;
            translationParams?: Array<{
              __typename?: 'LocalisedTextTranslationParams';
              name: string;
              value: string;
            } | null> | null;
          } | null;
          shortTitle?: {
            __typename?: 'LocalisedText';
            translationKey: string;
            translationParams?: Array<{
              __typename?: 'LocalisedTextTranslationParams';
              name: string;
              value: string;
            } | null> | null;
          } | null;
        }
    >;
    contactForm?: { __typename?: 'ContactForm'; missingIntegration?: Array<string> | null } | null;
    requests: Array<{
      __typename?: 'RequestData';
      requestId: string;
      status?: RequestStatus | null;
      actions?: Array<string> | null;
      expirationDateTime?: number | null;
      createdAt: number;
      flow?: { __typename?: 'FlowData'; id: string } | null;
    } | null>;
    title: {
      __typename?: 'LocalisedText';
      translationKey: string;
      translationParams?: Array<{
        __typename?: 'LocalisedTextTranslationParams';
        name: string;
        value: string;
      } | null> | null;
    };
    description?: {
      __typename?: 'LocalisedText';
      translationKey: string;
      translationParams?: Array<{
        __typename?: 'LocalisedTextTranslationParams';
        name: string;
        value: string;
      } | null> | null;
    } | null;
    shortTitle?: {
      __typename?: 'LocalisedText';
      translationKey: string;
      translationParams?: Array<{
        __typename?: 'LocalisedTextTranslationParams';
        name: string;
        value: string;
      } | null> | null;
    } | null;
  } | null;
};

export type GetAppsVariables = Exact<{
  getAppsQueryArgs: GetAppsQueryArgs;
}>;

export type GetApps = {
  __typename?: 'Query';
  getApps?:
    | {
        __typename?: 'AppsResponse';
        apps: Array<{
          __typename?: 'App';
          id: string;
          name?: string | null;
          connectionStatus?: AppConnectionStatus | null;
          featuredOrder?: number | null;
          internalName?: string | null;
          description?: string | null;
          icon?: string | null;
          trustedScopes?: string | null;
          futureScopes?: string | null;
          urlConfiguration?: {
            __typename?: 'UrlConfiguration';
            url?: {
              __typename?: 'PartnerUrls';
              connection?: string | null;
              configure?: string | null;
              exportTransactions?: string | null;
            } | null;
            config?: {
              __typename?: 'PartnerUrlConfiguration';
              flow?: {
                __typename?: 'PartnerUrlConfigurationFlow';
                default?: Array<string | null> | null;
                connectAccountingSystem?: Array<string | null> | null;
              } | null;
            } | null;
          } | null;
        }>;
      }
    | { __typename?: 'GetAppsError'; message: string }
    | null;
};

export type GetAuthConsentListVariables = Exact<{
  requestId?: InputMaybe<Scalars['String']['input']>;
}>;

export type GetAuthConsentList = {
  __typename?: 'Query';
  getBankAccounts?: Array<{
    __typename?: 'BankAccount';
    id: string;
    iban?: string | null;
    administrationId?: string | null;
    displayName?: string | null;
    bank?: Connector | null;
    aisEnabled?: boolean | null;
    isZombie: boolean;
    validUntil?: string | null;
    companyAccount?: boolean | null;
  }> | null;
  listAdministrations?: Array<{
    __typename?: 'Administration';
    id: string;
    deleted: boolean;
    kvkNumber?: string | null;
    name: string;
    newDocumentCount?: number | null;
    partners: Array<{ __typename?: 'Partner'; id: string } | null>;
  } | null> | null;
};

export type GetBankAccountsVariables = Exact<{
  aisEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  bank?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>> | InputMaybe<Scalars['String']['input']>
  >;
  administrationId?: InputMaybe<Scalars['String']['input']>;
  includeUnassigned?: InputMaybe<Scalars['Boolean']['input']>;
}>;

export type GetBankAccounts = {
  __typename?: 'Query';
  getBankAccounts?: Array<{
    __typename?: 'BankAccount';
    id: string;
    iban?: string | null;
    administrationId?: string | null;
    displayName?: string | null;
    bank?: Connector | null;
    aisEnabled?: boolean | null;
    verifiedName?: string | null;
    isZombie: boolean;
  }> | null;
};

export type GetBankConnectorsDetailsVariables = Exact<{
  bankIds?: InputMaybe<Array<Scalars['String']['input']> | Scalars['String']['input']>;
  filters?: InputMaybe<GetBankConnectorsFilterArgs>;
}>;

export type GetBankConnectorsDetails = {
  __typename?: 'Query';
  getBankConnectorsDetails?: Array<{
    __typename?: 'BankConnectorsDetails';
    bankId: string;
    bankName: string;
    icon: string;
    order: number;
    connectors: Array<{
      __typename?: 'ConnectorDetails';
      connectorId: Connector;
      connectorName: string;
      bankId: string;
      connectorType: string;
      country: Array<string>;
      tags?: Array<string> | null;
      icon: string;
      recommended?: boolean | null;
      paymentTypes: Array<string>;
      meteringPlan: string;
      descriptionKey?: string | null;
    }>;
  } | null> | null;
};

export type GetBankTransactionsVariables = Exact<{
  getBankTransactionsArgs: GetBankTransactionsArgs;
}>;

export type GetBankTransactions = {
  __typename?: 'Query';
  getBankTransactions?:
    | { __typename?: 'BankAccountAccessError'; message: string; code: string }
    | {
        __typename?: 'BankTransactionsResponse';
        bankAccount: {
          __typename?: 'BankAccount';
          id: string;
          iban?: string | null;
          displayName?: string | null;
        };
        transactions: Array<{
          __typename?: 'BankTransaction';
          id: string;
          iban?: string | null;
          name?: string | null;
          amount: number;
          currency: string;
          date: Date;
          description?: string | null;
          paymentId?: string | null;
          documentId?: string | null;
        }>;
      }
    | null;
};

export type GetRequestVariables = Exact<{
  requestId: Scalars['String']['input'];
}>;

export type GetRequest = {
  __typename?: 'Query';
  getRequest?:
    | { __typename?: 'GetRequestError'; key: string; message: string }
    | {
        __typename?: 'RequestData';
        requestId: string;
        status?: RequestStatus | null;
        message: string;
        channel?: RequestChannel | null;
        from?: string | null;
        to?: string | null;
        actions?: Array<string> | null;
        periodicalType?: RequestPeriodicalType | null;
        expirationDateTime?: number | null;
        connectionType?: RequestConnectionType | null;
        createdAt: number;
        reminderDateTimes?: Array<number> | null;
        partner?: {
          __typename?: 'Partner';
          id: string;
          name: string;
          supportEmail?: string | null;
          icon?: string | null;
          relation?: PartnerRelation | null;
          trustTermsAndConditions?: TrustTermsAndConditions | null;
          appName: string;
          theme?: {
            __typename?: 'Theme';
            name?: string | null;
            color?: string | null;
            icon?: string | null;
          } | null;
        } | null;
        approver?: {
          __typename?: 'UserInfo';
          id: string;
          displayName?: string | null;
          email: string;
        } | null;
        requester?: {
          __typename?: 'UserInfo';
          id: string;
          displayName?: string | null;
          email: string;
        } | null;
        flow?: { __typename?: 'FlowData'; id: string } | null;
      }
    | null;
};

export type GetFinancedInvoiceVariables = Exact<{
  partnerId?: InputMaybe<Scalars['String']['input']>;
  administrationId: Scalars['String']['input'];
  invoiceId: Scalars['String']['input'];
}>;

export type GetFinancedInvoice = {
  __typename?: 'Query';
  getFinancedInvoice?: {
    __typename?: 'FinancedInvoice';
    invoiceId: string;
    administrationId: string;
    partnerId?: string | null;
  } | null;
};

export type GetFlowVariables = Exact<{
  flowId: Scalars['String']['input'];
  requestId?: InputMaybe<Scalars['String']['input']>;
}>;

export type GetFlow = {
  __typename?: 'Query';
  getFlow?: {
    __typename: 'FlowData';
    id: string;
    isFinished: boolean;
    status: FlowStatus;
    redirectUrl?: string | null;
    dependencies?: Array<string | null> | null;
    type: string;
    flowType: FlowType;
    skipOverview?: boolean | null;
    onFinishUrl?: string | null;
    actions: Array<
      | {
          __typename: 'ActionData';
          id: string;
          isFinished: boolean;
          status: FlowStatus;
          state?: string | null;
          dependencies?: Array<string | null> | null;
          requestActions?: Array<string> | null;
          type: string;
          url: string;
          validationUrl?: string | null;
          settings?: string | null;
          title: {
            __typename?: 'LocalisedText';
            translationKey: string;
            translationParams?: Array<{
              __typename?: 'LocalisedTextTranslationParams';
              name: string;
              value: string;
            } | null> | null;
          };
          shortTitle?: {
            __typename?: 'LocalisedText';
            translationKey: string;
            translationParams?: Array<{
              __typename?: 'LocalisedTextTranslationParams';
              name: string;
              value: string;
            } | null> | null;
          } | null;
          description?: {
            __typename?: 'LocalisedText';
            translationKey: string;
            translationParams?: Array<{
              __typename?: 'LocalisedTextTranslationParams';
              name: string;
              value: string;
            } | null> | null;
          } | null;
          triggers?: {
            __typename?: 'ActionTriggers';
            post: Array<{
              __typename?: 'ActionTrigger';
              actionId: string;
              mutation: string;
              execute: boolean;
            }>;
            pre: Array<{
              __typename?: 'ActionTrigger';
              actionId: string;
              mutation: string;
              execute: boolean;
            }>;
          } | null;
        }
      | {
          __typename: 'FlowData';
          id: string;
          isFinished: boolean;
          status: FlowStatus;
          redirectUrl?: string | null;
          dependencies?: Array<string | null> | null;
          type: string;
          flowType: FlowType;
          skipOverview?: boolean | null;
          onFinishUrl?: string | null;
          actions: Array<
            | {
                __typename: 'ActionData';
                id: string;
                isFinished: boolean;
                status: FlowStatus;
                state?: string | null;
                dependencies?: Array<string | null> | null;
                requestActions?: Array<string> | null;
                type: string;
                url: string;
                validationUrl?: string | null;
                settings?: string | null;
                title: {
                  __typename?: 'LocalisedText';
                  translationKey: string;
                  translationParams?: Array<{
                    __typename?: 'LocalisedTextTranslationParams';
                    name: string;
                    value: string;
                  } | null> | null;
                };
                shortTitle?: {
                  __typename?: 'LocalisedText';
                  translationKey: string;
                  translationParams?: Array<{
                    __typename?: 'LocalisedTextTranslationParams';
                    name: string;
                    value: string;
                  } | null> | null;
                } | null;
                description?: {
                  __typename?: 'LocalisedText';
                  translationKey: string;
                  translationParams?: Array<{
                    __typename?: 'LocalisedTextTranslationParams';
                    name: string;
                    value: string;
                  } | null> | null;
                } | null;
                triggers?: {
                  __typename?: 'ActionTriggers';
                  post: Array<{
                    __typename?: 'ActionTrigger';
                    actionId: string;
                    mutation: string;
                    execute: boolean;
                  }>;
                  pre: Array<{
                    __typename?: 'ActionTrigger';
                    actionId: string;
                    mutation: string;
                    execute: boolean;
                  }>;
                } | null;
              }
            | {
                __typename: 'FlowData';
                id: string;
                isFinished: boolean;
                status: FlowStatus;
                redirectUrl?: string | null;
                dependencies?: Array<string | null> | null;
                type: string;
                flowType: FlowType;
                skipOverview?: boolean | null;
                onFinishUrl?: string | null;
                actions: Array<
                  | {
                      __typename: 'ActionData';
                      id: string;
                      isFinished: boolean;
                      status: FlowStatus;
                      state?: string | null;
                      dependencies?: Array<string | null> | null;
                      requestActions?: Array<string> | null;
                      type: string;
                      url: string;
                      validationUrl?: string | null;
                      settings?: string | null;
                      title: {
                        __typename?: 'LocalisedText';
                        translationKey: string;
                        translationParams?: Array<{
                          __typename?: 'LocalisedTextTranslationParams';
                          name: string;
                          value: string;
                        } | null> | null;
                      };
                      shortTitle?: {
                        __typename?: 'LocalisedText';
                        translationKey: string;
                        translationParams?: Array<{
                          __typename?: 'LocalisedTextTranslationParams';
                          name: string;
                          value: string;
                        } | null> | null;
                      } | null;
                      description?: {
                        __typename?: 'LocalisedText';
                        translationKey: string;
                        translationParams?: Array<{
                          __typename?: 'LocalisedTextTranslationParams';
                          name: string;
                          value: string;
                        } | null> | null;
                      } | null;
                      triggers?: {
                        __typename?: 'ActionTriggers';
                        post: Array<{
                          __typename?: 'ActionTrigger';
                          actionId: string;
                          mutation: string;
                          execute: boolean;
                        }>;
                        pre: Array<{
                          __typename?: 'ActionTrigger';
                          actionId: string;
                          mutation: string;
                          execute: boolean;
                        }>;
                      } | null;
                    }
                  | { __typename?: 'FlowData' }
                >;
                contactForm?: {
                  __typename?: 'ContactForm';
                  missingIntegration?: Array<string> | null;
                } | null;
                requests: Array<{
                  __typename?: 'RequestData';
                  requestId: string;
                  status?: RequestStatus | null;
                  actions?: Array<string> | null;
                  expirationDateTime?: number | null;
                  createdAt: number;
                  flow?: { __typename?: 'FlowData'; id: string } | null;
                } | null>;
                title: {
                  __typename?: 'LocalisedText';
                  translationKey: string;
                  translationParams?: Array<{
                    __typename?: 'LocalisedTextTranslationParams';
                    name: string;
                    value: string;
                  } | null> | null;
                };
                description?: {
                  __typename?: 'LocalisedText';
                  translationKey: string;
                  translationParams?: Array<{
                    __typename?: 'LocalisedTextTranslationParams';
                    name: string;
                    value: string;
                  } | null> | null;
                } | null;
                shortTitle?: {
                  __typename?: 'LocalisedText';
                  translationKey: string;
                  translationParams?: Array<{
                    __typename?: 'LocalisedTextTranslationParams';
                    name: string;
                    value: string;
                  } | null> | null;
                } | null;
              }
          >;
          contactForm?: {
            __typename?: 'ContactForm';
            missingIntegration?: Array<string> | null;
          } | null;
          requests: Array<{
            __typename?: 'RequestData';
            requestId: string;
            status?: RequestStatus | null;
            actions?: Array<string> | null;
            expirationDateTime?: number | null;
            createdAt: number;
            flow?: { __typename?: 'FlowData'; id: string } | null;
          } | null>;
          title: {
            __typename?: 'LocalisedText';
            translationKey: string;
            translationParams?: Array<{
              __typename?: 'LocalisedTextTranslationParams';
              name: string;
              value: string;
            } | null> | null;
          };
          description?: {
            __typename?: 'LocalisedText';
            translationKey: string;
            translationParams?: Array<{
              __typename?: 'LocalisedTextTranslationParams';
              name: string;
              value: string;
            } | null> | null;
          } | null;
          shortTitle?: {
            __typename?: 'LocalisedText';
            translationKey: string;
            translationParams?: Array<{
              __typename?: 'LocalisedTextTranslationParams';
              name: string;
              value: string;
            } | null> | null;
          } | null;
        }
    >;
    contactForm?: { __typename?: 'ContactForm'; missingIntegration?: Array<string> | null } | null;
    requests: Array<{
      __typename?: 'RequestData';
      requestId: string;
      status?: RequestStatus | null;
      actions?: Array<string> | null;
      expirationDateTime?: number | null;
      createdAt: number;
      flow?: { __typename?: 'FlowData'; id: string } | null;
    } | null>;
    title: {
      __typename?: 'LocalisedText';
      translationKey: string;
      translationParams?: Array<{
        __typename?: 'LocalisedTextTranslationParams';
        name: string;
        value: string;
      } | null> | null;
    };
    description?: {
      __typename?: 'LocalisedText';
      translationKey: string;
      translationParams?: Array<{
        __typename?: 'LocalisedTextTranslationParams';
        name: string;
        value: string;
      } | null> | null;
    } | null;
    shortTitle?: {
      __typename?: 'LocalisedText';
      translationKey: string;
      translationParams?: Array<{
        __typename?: 'LocalisedTextTranslationParams';
        name: string;
        value: string;
      } | null> | null;
    } | null;
  } | null;
};

export type GetInvoiceVariables = Exact<{
  invoiceId: Scalars['String']['input'];
  administrationId: Scalars['String']['input'];
  partnerId?: InputMaybe<Scalars['String']['input']>;
}>;

export type GetInvoice = {
  __typename?: 'Query';
  getInvoice?:
    | { __typename: 'AdministrationAccessError'; message: string }
    | {
        __typename: 'Invoice';
        id: string;
        status: string;
        name: string;
        filename: string;
        docType: InvoiceDocType;
        amount: number;
        date: string;
        dueDate?: string | null;
        invoiceNumber: string;
        administrationId: string;
        administration?: {
          __typename?: 'Administration';
          id: string;
          name: string;
          kvkNumber?: string | null;
        } | null;
        partner?: {
          __typename?: 'InvoicePartner';
          partnerId: string;
          name: string;
          logo?: string | null;
        } | null;
      }
    | { __typename: 'InvoiceAccessError'; message: string }
    | null;
};

export type GetInvoiceDocumentVariables = Exact<{
  invoiceId: Scalars['String']['input'];
  administrationId: Scalars['String']['input'];
  partnerId?: InputMaybe<Scalars['String']['input']>;
  isNewFile?: InputMaybe<Scalars['Boolean']['input']>;
}>;

export type GetInvoiceDocument = {
  __typename?: 'Query';
  getInvoiceDocument?: {
    __typename?: 'InvoiceDocumentResponse';
    documentUrl: string;
    filename?: string | null;
    mimeType?: string | null;
  } | null;
};

export type GetInvoiceFinanceStateVariables = Exact<{ [key: string]: never }>;

export type GetInvoiceFinanceState = {
  __typename?: 'Query';
  getInvoiceFinanceState?: {
    __typename?: 'InvoiceFinanceState';
    firstName: string;
    lastName: string;
    emailAddress: string;
    phoneNumber: string;
    completedAmount: number;
    createdAt: Date;
    updatedAt: Date;
  } | null;
};

export type GetInvoiceFinanceTokenVariables = Exact<{ [key: string]: never }>;

export type GetInvoiceFinanceToken = {
  __typename?: 'Query';
  getInvoiceFinanceToken?:
    | { __typename: 'InvoiceFinanceToken'; accessToken: string; caseId?: string | null }
    | {
        __typename: 'RaboValidationError';
        code?: string | null;
        errorCode: string;
        message?: string | null;
        invalidFields?: Array<{
          __typename?: 'FieldError';
          code?: string | null;
          field?: string | null;
          rejectedValue?: string | null;
        } | null> | null;
      }
    | null;
};

export type GetPartnerVariables = Exact<{
  partnerId?: InputMaybe<Scalars['String']['input']>;
  brandingPartnerId?: InputMaybe<Scalars['String']['input']>;
}>;

export type GetPartner = {
  __typename?: 'Query';
  getPartner?: {
    __typename?: 'HybridPartnerResponse';
    partner?:
      | { __typename?: 'NonExistingPartner'; id: string; isValid?: boolean | null }
      | {
          __typename?: 'Partner';
          id: string;
          name: string;
          appName: string;
          billingType?: BillingType | null;
          appScopes?: string | null;
          trustedScopes?: string | null;
          futureScopes?: string | null;
          defaultAccountType?: PartnerAccountType | null;
          supportEmail?: string | null;
          icon?: string | null;
          relation?: PartnerRelation | null;
          isValid?: boolean | null;
          trustTermsAndConditions?: TrustTermsAndConditions | null;
          internalName?: string | null;
          urlConfiguration?: {
            __typename?: 'UrlConfiguration';
            url?: {
              __typename?: 'PartnerUrls';
              configure?: string | null;
              connection?: string | null;
              exportTransactions?: string | null;
            } | null;
            config?: {
              __typename?: 'PartnerUrlConfiguration';
              flow?: {
                __typename?: 'PartnerUrlConfigurationFlow';
                default?: Array<string | null> | null;
                connectAccountingSystem?: Array<string | null> | null;
              } | null;
            } | null;
          } | null;
          theme?: {
            __typename?: 'Theme';
            icon?: string | null;
            name?: string | null;
            color?: string | null;
          } | null;
        }
      | null;
  } | null;
};

export type GetPartnerAuthorizationRefreshTokenVariables = Exact<{
  partnerId: Scalars['String']['input'];
}>;

export type GetPartnerAuthorizationRefreshToken = {
  __typename?: 'Query';
  getPartnerAuthorizationRefreshToken?:
    | { __typename?: 'PartnerAuthorizationRefreshTokenBaseResponse'; refreshToken: string }
    | { __typename?: 'PartnerBaseError'; message: string }
    | null;
};

export type GetPaymentsVariables = Exact<{
  offset: Scalars['Int']['input'];
  limit: Scalars['Int']['input'];
  filter?: InputMaybe<GetPaymentsFilter>;
}>;

export type GetPayments = {
  __typename?: 'Query';
  getPayments?: Array<{ __typename?: 'Payment'; id: string; status: PaymentStatus } | null> | null;
};

export type GetHixAccountsVariables = Exact<{
  email: Scalars['String']['input'];
}>;

export type GetHixAccounts = {
  __typename?: 'Query';
  getHixAccounts?: {
    __typename?: 'GetHixAccountsResponse';
    accounts: Array<{
      __typename?: 'HixAccount';
      email: string;
      name: string;
      setupToken?: string | null;
      clientDomain: string;
      error?: string | null;
    } | null>;
  } | null;
};

export type GetHixUserVariables = Exact<{ [key: string]: never }>;

export type GetHixUser = {
  __typename?: 'Query';
  getHixUser?: {
    __typename?: 'GetHixAccountResponse';
    email?: string | null;
    isSmsSent?: boolean | null;
    isVerificationCodeSent?: boolean | null;
  } | null;
};

export type GetHixUserStatusVariables = Exact<{ [key: string]: never }>;

export type GetHixUserStatus = {
  __typename?: 'Query';
  getHixUserStatus?: {
    __typename?: 'GetHixUserStatusResponse';
    isAccountConnected: boolean;
    isHixEmailVerified: boolean;
    frontendUrl?: string | null;
  } | null;
};

export type GetSingleBankAccountVariables = Exact<{
  id: Scalars['String']['input'];
  aisEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  bank?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>> | InputMaybe<Scalars['String']['input']>
  >;
  administrationId?: InputMaybe<Scalars['String']['input']>;
  includeUnassigned?: InputMaybe<Scalars['Boolean']['input']>;
}>;

export type GetSingleBankAccount = {
  __typename?: 'Query';
  getSingleBankAccount: {
    __typename?: 'BankAccount';
    id: string;
    iban?: string | null;
    administrationId?: string | null;
    displayName?: string | null;
    bank?: Connector | null;
    aisEnabled?: boolean | null;
    verifiedName?: string | null;
    balanceAmount?: number | null;
    lastBankUpdateAt?: string | null;
  };
};

export type GetUserVariables = Exact<{ [key: string]: never }>;

export type GetUser = {
  __typename?: 'Query';
  getUser?: {
    __typename?: 'User';
    id: string;
    displayName?: string | null;
    firstName?: string | null;
    lastName?: string | null;
    email?: string | null;
    partnerId?: string | null;
    betaTester: boolean;
    billingIban?: string | null;
    billingAccepted: boolean;
    active: boolean;
    partnerReference?: string | null;
    starredApps?: Array<string | null> | null;
    termsApproved: boolean;
    termsPaymentsApproved: boolean;
    identificationLevel: IdentificationLevel;
    phoneNumber?: string | null;
    language?: string | null;
  } | null;
};

export type ListUserAppsVariables = Exact<{ [key: string]: never }>;

export type ListUserApps = {
  __typename?: 'Query';
  listUserApps?: Array<{
    __typename?: 'UserApps';
    id: string;
    name: string;
    icon?: string | null;
    appUrl?: string | null;
  } | null> | null;
};

export type GetUserSessionVariables = Exact<{ [key: string]: never }>;

export type GetUserSession = {
  __typename?: 'Query';
  getUserSession?: {
    __typename?: 'UserSession';
    id: string;
    lastUsedAt: string;
    method: UserSessionMethod;
  } | null;
};

export type HasHixAccountVariables = Exact<{
  email: Scalars['String']['input'];
}>;

export type HasHixAccount = {
  __typename?: 'Query';
  hasHixAccount?: { __typename?: 'HasHixAccountResponse'; hasValidAccount?: boolean | null } | null;
};

export type ListInvoicesVariables = Exact<{
  limit: Scalars['Int']['input'];
  offset: Scalars['Int']['input'];
  order?: InputMaybe<OrderBy>;
  filter?: InputMaybe<ListInvoicesFilter>;
}>;

export type ListInvoices = {
  __typename?: 'Query';
  listInvoices?: Array<{
    __typename?: 'Invoice';
    id: string;
    status: string;
    name: string;
    amount: number;
    invoiceNumber: string;
    date: string;
    dueDate?: string | null;
    administrationId: string;
    docType: InvoiceDocType;
    partner?: {
      __typename?: 'InvoicePartner';
      logo?: string | null;
      name: string;
      partnerId: string;
    } | null;
  } | null> | null;
};

export type ListPartnerAdministrationsVariables = Exact<{
  partnerId: Scalars['String']['input'];
}>;

export type ListPartnerAdministrations = {
  __typename?: 'Query';
  listPartnerAdministrations: Array<{
    __typename?: 'Administration';
    id: string;
    deleted: boolean;
    kvkNumber?: string | null;
    name: string;
    scanEmail?: string | null;
    sendUbl: boolean;
    mapped?: boolean | null;
  }>;
};

export type ListPartnerInvoicesVariables = Exact<{
  order?: InputMaybe<OrderBy>;
  limit: Scalars['Int']['input'];
  offset: Scalars['Int']['input'];
  filter?: InputMaybe<ListPartnerInvoicesFilter>;
}>;

export type ListPartnerInvoices = {
  __typename?: 'Query';
  listPartnerInvoices?: Array<{
    __typename?: 'Invoice';
    id: string;
    status: string;
    name: string;
    amount: number;
    invoiceNumber: string;
    date: string;
    dueDate?: string | null;
    administrationId: string;
    docType: InvoiceDocType;
    partner?: {
      __typename?: 'InvoicePartner';
      logo?: string | null;
      name: string;
      partnerId: string;
    } | null;
  } | null> | null;
};

export type ListAdministrationsVariables = Exact<{ [key: string]: never }>;

export type ListAdministrations = {
  __typename?: 'Query';
  listAdministrations?: Array<{
    __typename?: 'Administration';
    id: string;
    deleted: boolean;
    kvkNumber?: string | null;
    name: string;
    scanEmail?: string | null;
    sendUbl: boolean;
    newDocumentCount?: number | null;
    partners: Array<{ __typename?: 'Partner'; id: string } | null>;
  } | null> | null;
};

export type SearchCompaniesVariables = Exact<{
  search?: InputMaybe<Scalars['String']['input']>;
  kvkNumber?: InputMaybe<Scalars['String']['input']>;
}>;

export type SearchCompanies = {
  __typename?: 'Query';
  searchCompanies?: {
    __typename?: 'Companies';
    items: Array<{
      __typename?: 'CompanyItem';
      kvkNumber: string;
      branchNumber?: string | null;
      tradeName: string;
      street?: string | null;
      houseNumber?: number | null;
      houseNumberAddition?: string | null;
      postalCode?: string | null;
      city?: string | null;
      type: string;
      links: Array<{
        __typename?: 'CompanyItemLink';
        href?: string | null;
        rel?: string | null;
      } | null>;
    } | null>;
  } | null;
};

export type SendHixAccountVerificationEmailVariables = Exact<{
  email: Scalars['String']['input'];
}>;

export type SendHixAccountVerificationEmail = {
  __typename?: 'Query';
  sendHixAccountVerificationEmail?: {
    __typename?: 'SendHixAccountVerificationEmailResponse';
    isEmailSent?: boolean | null;
  } | null;
};

export type SendHixAccountVerificationSmsVariables = Exact<{ [key: string]: never }>;

export type SendHixAccountVerificationSms = {
  __typename?: 'Query';
  sendHixAccountVerificationSms?: {
    __typename?: 'SendHixAccountVerificationSmsResponse';
    isSmsSent?: boolean | null;
  } | null;
};

export type VerifyHixEmailVariables = Exact<{
  verificationCode: Scalars['String']['input'];
}>;

export type VerifyHixEmail = {
  __typename?: 'Query';
  verifyHixEmail?: {
    __typename?: 'VerifyHixEmailResponse';
    isEmailVerified?: boolean | null;
  } | null;
};

export const ActionDataFragment = gql`
  fragment ActionDataFragment on ActionData {
    __typename
    id
    isFinished
    status
    state
    dependencies
    requestActions
    type
    url
    validationUrl
    state
    settings
    title {
      translationKey
      translationParams {
        name
        value
      }
    }
    shortTitle {
      translationKey
      translationParams {
        name
        value
      }
    }
    description {
      translationKey
      translationParams {
        name
        value
      }
    }
    triggers {
      post {
        actionId
        mutation
        execute
      }
      pre {
        actionId
        mutation
        execute
      }
    }
  }
`;
export const FlowDataFragment = gql`
  fragment FlowDataFragment on FlowData {
    __typename
    id
    isFinished
    status
    redirectUrl
    contactForm {
      missingIntegration
    }
    requests {
      ... on RequestData {
        requestId
        status
        actions
        expirationDateTime
        createdAt
      }
      flow {
        id
      }
    }
    dependencies
    type
    flowType
    skipOverview
    onFinishUrl
    title {
      translationKey
      translationParams {
        name
        value
      }
    }
    description {
      translationKey
      translationParams {
        name
        value
      }
    }
    shortTitle {
      translationKey
      translationParams {
        name
        value
      }
    }
  }
`;
export const GetUserFragment = gql`
  fragment GetUserFragment on User {
    id
    displayName
    firstName
    lastName
    email
    partnerId
    betaTester
    billingIban
    billingAccepted
    active
    partnerReference
    starredApps
    termsApproved
    termsPaymentsApproved
    identificationLevel
    phoneNumber
    language
  }
`;
export const AddBetaMemberToProgramsDocument = gql`
  mutation addBetaMemberToPrograms(
    $betaProgramIds: [String!]!
    $memberId: String!
    $memberType: BetaProgramMemberType!
  ) {
    addBetaMemberToPrograms(
      betaProgramIds: $betaProgramIds
      memberId: $memberId
      memberType: $memberType
    )
  }
`;
export type AddBetaMemberToProgramsMutationFn = Apollo.MutationFunction<
  AddBetaMemberToPrograms,
  AddBetaMemberToProgramsVariables
>;

/**
 * __useAddBetaMemberToPrograms__
 *
 * To run a mutation, you first call `useAddBetaMemberToPrograms` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddBetaMemberToPrograms` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addBetaMemberToPrograms, { data, loading, error }] = useAddBetaMemberToPrograms({
 *   variables: {
 *      betaProgramIds: // value for 'betaProgramIds'
 *      memberId: // value for 'memberId'
 *      memberType: // value for 'memberType'
 *   },
 * });
 */
export function useAddBetaMemberToPrograms(
  baseOptions?: Apollo.MutationHookOptions<
    AddBetaMemberToPrograms,
    AddBetaMemberToProgramsVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<AddBetaMemberToPrograms, AddBetaMemberToProgramsVariables>(
    AddBetaMemberToProgramsDocument,
    options,
  );
}
export type AddBetaMemberToProgramsHookResult = ReturnType<typeof useAddBetaMemberToPrograms>;
export type AddBetaMemberToProgramsMutationResult = Apollo.MutationResult<AddBetaMemberToPrograms>;
export type AddBetaMemberToProgramsMutationOptions = Apollo.BaseMutationOptions<
  AddBetaMemberToPrograms,
  AddBetaMemberToProgramsVariables
>;
export const CreateBankAuthUrlDocument = gql`
  mutation createBankAuthUrl($createBankAuthUrlArgs: CreateBankAuthUrlArgs!) {
    createBankAuthUrl(createBankAuthUrlArgs: $createBankAuthUrlArgs) {
      authUrl
    }
  }
`;
export type CreateBankAuthUrlMutationFn = Apollo.MutationFunction<
  CreateBankAuthUrl,
  CreateBankAuthUrlVariables
>;

/**
 * __useCreateBankAuthUrl__
 *
 * To run a mutation, you first call `useCreateBankAuthUrl` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateBankAuthUrl` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createBankAuthUrl, { data, loading, error }] = useCreateBankAuthUrl({
 *   variables: {
 *      createBankAuthUrlArgs: // value for 'createBankAuthUrlArgs'
 *   },
 * });
 */
export function useCreateBankAuthUrl(
  baseOptions?: Apollo.MutationHookOptions<CreateBankAuthUrl, CreateBankAuthUrlVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateBankAuthUrl, CreateBankAuthUrlVariables>(
    CreateBankAuthUrlDocument,
    options,
  );
}
export type CreateBankAuthUrlHookResult = ReturnType<typeof useCreateBankAuthUrl>;
export type CreateBankAuthUrlMutationResult = Apollo.MutationResult<CreateBankAuthUrl>;
export type CreateBankAuthUrlMutationOptions = Apollo.BaseMutationOptions<
  CreateBankAuthUrl,
  CreateBankAuthUrlVariables
>;
export const CreatePartnerAuthorizationDocument = gql`
  mutation createPartnerAuthorization(
    $partnerId: String!
    $consent: PartnerConsent!
    $autoConsent: Boolean
    $codeChallenge: String
    $partnerReference: String
    $requestId: String
  ) {
    createPartnerAuthorization(
      partnerId: $partnerId
      autoConsent: $autoConsent
      consent: $consent
      codeChallenge: $codeChallenge
      partnerReference: $partnerReference
      requestId: $requestId
    ) {
      ... on PartnerAuthorizationBaseResponse {
        __typename
        code
        refreshToken
      }
      ... on PartnerBaseError {
        __typename
        message
      }
    }
  }
`;
export type CreatePartnerAuthorizationMutationFn = Apollo.MutationFunction<
  CreatePartnerAuthorization,
  CreatePartnerAuthorizationVariables
>;

/**
 * __useCreatePartnerAuthorization__
 *
 * To run a mutation, you first call `useCreatePartnerAuthorization` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePartnerAuthorization` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPartnerAuthorization, { data, loading, error }] = useCreatePartnerAuthorization({
 *   variables: {
 *      partnerId: // value for 'partnerId'
 *      consent: // value for 'consent'
 *      autoConsent: // value for 'autoConsent'
 *      codeChallenge: // value for 'codeChallenge'
 *      partnerReference: // value for 'partnerReference'
 *      requestId: // value for 'requestId'
 *   },
 * });
 */
export function useCreatePartnerAuthorization(
  baseOptions?: Apollo.MutationHookOptions<
    CreatePartnerAuthorization,
    CreatePartnerAuthorizationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreatePartnerAuthorization, CreatePartnerAuthorizationVariables>(
    CreatePartnerAuthorizationDocument,
    options,
  );
}
export type CreatePartnerAuthorizationHookResult = ReturnType<typeof useCreatePartnerAuthorization>;
export type CreatePartnerAuthorizationMutationResult =
  Apollo.MutationResult<CreatePartnerAuthorization>;
export type CreatePartnerAuthorizationMutationOptions = Apollo.BaseMutationOptions<
  CreatePartnerAuthorization,
  CreatePartnerAuthorizationVariables
>;
export const CreateRequestDocument = gql`
  mutation createRequest($request: RequestInput!) {
    createRequest(request: $request) {
      result
      data {
        requestId
        status
        message
        channel
        from
        to
        actions
        periodicalType
        expirationDateTime
        connectionType
        partner {
          id
          name
          supportEmail
          icon
          relation
          trustTermsAndConditions
          theme {
            name
            color
            icon
          }
        }
        approver {
          id
          displayName
          email
        }
        requester {
          id
          displayName
          email
        }
        flow {
          id
        }
      }
      error {
        key
        message
      }
    }
  }
`;
export type CreateRequestMutationFn = Apollo.MutationFunction<
  CreateRequest,
  CreateRequestVariables
>;

/**
 * __useCreateRequest__
 *
 * To run a mutation, you first call `useCreateRequest` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateRequest` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createRequest, { data, loading, error }] = useCreateRequest({
 *   variables: {
 *      request: // value for 'request'
 *   },
 * });
 */
export function useCreateRequest(
  baseOptions?: Apollo.MutationHookOptions<CreateRequest, CreateRequestVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateRequest, CreateRequestVariables>(CreateRequestDocument, options);
}
export type CreateRequestHookResult = ReturnType<typeof useCreateRequest>;
export type CreateRequestMutationResult = Apollo.MutationResult<CreateRequest>;
export type CreateRequestMutationOptions = Apollo.BaseMutationOptions<
  CreateRequest,
  CreateRequestVariables
>;
export const DeleteBankAccountDocument = gql`
  mutation deleteBankAccount($bankAccountId: ID!) {
    deleteBankAccount(bankAccountId: $bankAccountId)
  }
`;
export type DeleteBankAccountMutationFn = Apollo.MutationFunction<
  DeleteBankAccount,
  DeleteBankAccountVariables
>;

/**
 * __useDeleteBankAccount__
 *
 * To run a mutation, you first call `useDeleteBankAccount` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteBankAccount` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteBankAccount, { data, loading, error }] = useDeleteBankAccount({
 *   variables: {
 *      bankAccountId: // value for 'bankAccountId'
 *   },
 * });
 */
export function useDeleteBankAccount(
  baseOptions?: Apollo.MutationHookOptions<DeleteBankAccount, DeleteBankAccountVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteBankAccount, DeleteBankAccountVariables>(
    DeleteBankAccountDocument,
    options,
  );
}
export type DeleteBankAccountHookResult = ReturnType<typeof useDeleteBankAccount>;
export type DeleteBankAccountMutationResult = Apollo.MutationResult<DeleteBankAccount>;
export type DeleteBankAccountMutationOptions = Apollo.BaseMutationOptions<
  DeleteBankAccount,
  DeleteBankAccountVariables
>;
export const FinishFlowActionDocument = gql`
  mutation finishFlowAction(
    $flowId: String!
    $actionId: String!
    $state: AWSJSON
    $requestId: String
  ) {
    finishFlowAction(flowId: $flowId, actionId: $actionId, state: $state, requestId: $requestId) {
      ...FlowDataFragment
      actions {
        ...ActionDataFragment
        ... on FlowData {
          ...FlowDataFragment
          actions {
            ...ActionDataFragment
            ... on FlowData {
              ...FlowDataFragment
              actions {
                ...ActionDataFragment
              }
            }
          }
        }
      }
    }
  }
  ${FlowDataFragment}
  ${ActionDataFragment}
`;
export type FinishFlowActionMutationFn = Apollo.MutationFunction<
  FinishFlowAction,
  FinishFlowActionVariables
>;

/**
 * __useFinishFlowAction__
 *
 * To run a mutation, you first call `useFinishFlowAction` within a React component and pass it any options that fit your needs.
 * When your component renders, `useFinishFlowAction` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [finishFlowAction, { data, loading, error }] = useFinishFlowAction({
 *   variables: {
 *      flowId: // value for 'flowId'
 *      actionId: // value for 'actionId'
 *      state: // value for 'state'
 *      requestId: // value for 'requestId'
 *   },
 * });
 */
export function useFinishFlowAction(
  baseOptions?: Apollo.MutationHookOptions<FinishFlowAction, FinishFlowActionVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<FinishFlowAction, FinishFlowActionVariables>(
    FinishFlowActionDocument,
    options,
  );
}
export type FinishFlowActionHookResult = ReturnType<typeof useFinishFlowAction>;
export type FinishFlowActionMutationResult = Apollo.MutationResult<FinishFlowAction>;
export type FinishFlowActionMutationOptions = Apollo.BaseMutationOptions<
  FinishFlowAction,
  FinishFlowActionVariables
>;
export const ProcessBankTransactionsDocument = gql`
  mutation processBankTransactions($processBankTransactionsArgs: ProcessBankTransactionsArgs!) {
    processBankTransactions(processBankTransactionsArgs: $processBankTransactionsArgs)
  }
`;
export type ProcessBankTransactionsMutationFn = Apollo.MutationFunction<
  ProcessBankTransactions,
  ProcessBankTransactionsVariables
>;

/**
 * __useProcessBankTransactions__
 *
 * To run a mutation, you first call `useProcessBankTransactions` within a React component and pass it any options that fit your needs.
 * When your component renders, `useProcessBankTransactions` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [processBankTransactions, { data, loading, error }] = useProcessBankTransactions({
 *   variables: {
 *      processBankTransactionsArgs: // value for 'processBankTransactionsArgs'
 *   },
 * });
 */
export function useProcessBankTransactions(
  baseOptions?: Apollo.MutationHookOptions<
    ProcessBankTransactions,
    ProcessBankTransactionsVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ProcessBankTransactions, ProcessBankTransactionsVariables>(
    ProcessBankTransactionsDocument,
    options,
  );
}
export type ProcessBankTransactionsHookResult = ReturnType<typeof useProcessBankTransactions>;
export type ProcessBankTransactionsMutationResult = Apollo.MutationResult<ProcessBankTransactions>;
export type ProcessBankTransactionsMutationOptions = Apollo.BaseMutationOptions<
  ProcessBankTransactions,
  ProcessBankTransactionsVariables
>;
export const PutFinancedInvoiceDocument = gql`
  mutation putFinancedInvoice(
    $putFinancedInvoiceArgs: FinancedInvoiceInput!
    $updateInvoiceFinanceStateArgs: InvoiceFinanceStateUpdateInput!
  ) {
    putFinancedInvoice(putFinancedInvoiceArgs: $putFinancedInvoiceArgs) {
      invoiceId
      administrationId
      partnerId
    }
    updateInvoiceFinanceState(invoiceFinanceState: $updateInvoiceFinanceStateArgs) {
      firstName
      lastName
      emailAddress
      phoneNumber
      completedAmount
      createdAt
      updatedAt
    }
  }
`;
export type PutFinancedInvoiceMutationFn = Apollo.MutationFunction<
  PutFinancedInvoice,
  PutFinancedInvoiceVariables
>;

/**
 * __usePutFinancedInvoice__
 *
 * To run a mutation, you first call `usePutFinancedInvoice` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePutFinancedInvoice` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [putFinancedInvoice, { data, loading, error }] = usePutFinancedInvoice({
 *   variables: {
 *      putFinancedInvoiceArgs: // value for 'putFinancedInvoiceArgs'
 *      updateInvoiceFinanceStateArgs: // value for 'updateInvoiceFinanceStateArgs'
 *   },
 * });
 */
export function usePutFinancedInvoice(
  baseOptions?: Apollo.MutationHookOptions<PutFinancedInvoice, PutFinancedInvoiceVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<PutFinancedInvoice, PutFinancedInvoiceVariables>(
    PutFinancedInvoiceDocument,
    options,
  );
}
export type PutFinancedInvoiceHookResult = ReturnType<typeof usePutFinancedInvoice>;
export type PutFinancedInvoiceMutationResult = Apollo.MutationResult<PutFinancedInvoice>;
export type PutFinancedInvoiceMutationOptions = Apollo.BaseMutationOptions<
  PutFinancedInvoice,
  PutFinancedInvoiceVariables
>;
export const PutInvoiceFinanceStateDocument = gql`
  mutation putInvoiceFinanceState {
    putInvoiceFinanceState {
      completedAmount
      createdAt
      updatedAt
    }
  }
`;
export type PutInvoiceFinanceStateMutationFn = Apollo.MutationFunction<
  PutInvoiceFinanceState,
  PutInvoiceFinanceStateVariables
>;

/**
 * __usePutInvoiceFinanceState__
 *
 * To run a mutation, you first call `usePutInvoiceFinanceState` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePutInvoiceFinanceState` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [putInvoiceFinanceState, { data, loading, error }] = usePutInvoiceFinanceState({
 *   variables: {
 *   },
 * });
 */
export function usePutInvoiceFinanceState(
  baseOptions?: Apollo.MutationHookOptions<PutInvoiceFinanceState, PutInvoiceFinanceStateVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<PutInvoiceFinanceState, PutInvoiceFinanceStateVariables>(
    PutInvoiceFinanceStateDocument,
    options,
  );
}
export type PutInvoiceFinanceStateHookResult = ReturnType<typeof usePutInvoiceFinanceState>;
export type PutInvoiceFinanceStateMutationResult = Apollo.MutationResult<PutInvoiceFinanceState>;
export type PutInvoiceFinanceStateMutationOptions = Apollo.BaseMutationOptions<
  PutInvoiceFinanceState,
  PutInvoiceFinanceStateVariables
>;
export const SendContactRequestDocument = gql`
  mutation sendContactRequest($sendContactRequestArgs: SendContactRequestArgs!) {
    sendContactRequest(sendContactRequestArgs: $sendContactRequestArgs) {
      status
    }
  }
`;
export type SendContactRequestMutationFn = Apollo.MutationFunction<
  SendContactRequest,
  SendContactRequestVariables
>;

/**
 * __useSendContactRequest__
 *
 * To run a mutation, you first call `useSendContactRequest` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendContactRequest` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendContactRequest, { data, loading, error }] = useSendContactRequest({
 *   variables: {
 *      sendContactRequestArgs: // value for 'sendContactRequestArgs'
 *   },
 * });
 */
export function useSendContactRequest(
  baseOptions?: Apollo.MutationHookOptions<SendContactRequest, SendContactRequestVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<SendContactRequest, SendContactRequestVariables>(
    SendContactRequestDocument,
    options,
  );
}
export type SendContactRequestHookResult = ReturnType<typeof useSendContactRequest>;
export type SendContactRequestMutationResult = Apollo.MutationResult<SendContactRequest>;
export type SendContactRequestMutationOptions = Apollo.BaseMutationOptions<
  SendContactRequest,
  SendContactRequestVariables
>;
export const SendFormDocument = gql`
  mutation sendForm($fields: AWSJSON) {
    sendForm(fields: $fields)
  }
`;
export type SendFormMutationFn = Apollo.MutationFunction<SendForm, SendFormVariables>;

/**
 * __useSendForm__
 *
 * To run a mutation, you first call `useSendForm` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendForm` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendForm, { data, loading, error }] = useSendForm({
 *   variables: {
 *      fields: // value for 'fields'
 *   },
 * });
 */
export function useSendForm(baseOptions?: Apollo.MutationHookOptions<SendForm, SendFormVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<SendForm, SendFormVariables>(SendFormDocument, options);
}
export type SendFormHookResult = ReturnType<typeof useSendForm>;
export type SendFormMutationResult = Apollo.MutationResult<SendForm>;
export type SendFormMutationOptions = Apollo.BaseMutationOptions<SendForm, SendFormVariables>;
export const SendPreliminaryOfferDocument = gql`
  mutation sendPreliminaryOffer($preliminaryOffer: SendPreliminaryOfferArgs!) {
    sendPreliminaryOffer(preliminaryOffer: $preliminaryOffer) {
      ... on SendPreliminaryOfferResponse {
        __typename
        message
        offer {
          duration
          interestCost
          interestRate
          invoiceAmount
          loanAmount
        }
      }
      ... on RaboValidationError {
        __typename
        code
        errorCode
        message
        invalidFields {
          code
          field
          rejectedValue
        }
      }
    }
  }
`;
export type SendPreliminaryOfferMutationFn = Apollo.MutationFunction<
  SendPreliminaryOffer,
  SendPreliminaryOfferVariables
>;

/**
 * __useSendPreliminaryOffer__
 *
 * To run a mutation, you first call `useSendPreliminaryOffer` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendPreliminaryOffer` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendPreliminaryOffer, { data, loading, error }] = useSendPreliminaryOffer({
 *   variables: {
 *      preliminaryOffer: // value for 'preliminaryOffer'
 *   },
 * });
 */
export function useSendPreliminaryOffer(
  baseOptions?: Apollo.MutationHookOptions<SendPreliminaryOffer, SendPreliminaryOfferVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<SendPreliminaryOffer, SendPreliminaryOfferVariables>(
    SendPreliminaryOfferDocument,
    options,
  );
}
export type SendPreliminaryOfferHookResult = ReturnType<typeof useSendPreliminaryOffer>;
export type SendPreliminaryOfferMutationResult = Apollo.MutationResult<SendPreliminaryOffer>;
export type SendPreliminaryOfferMutationOptions = Apollo.BaseMutationOptions<
  SendPreliminaryOffer,
  SendPreliminaryOfferVariables
>;
export const SendRequestDocument = gql`
  mutation SendRequest($requestId: String!) {
    sendRequest(requestId: $requestId)
  }
`;
export type SendRequestMutationFn = Apollo.MutationFunction<SendRequest, SendRequestVariables>;

/**
 * __useSendRequest__
 *
 * To run a mutation, you first call `useSendRequest` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendRequest` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendRequest, { data, loading, error }] = useSendRequest({
 *   variables: {
 *      requestId: // value for 'requestId'
 *   },
 * });
 */
export function useSendRequest(
  baseOptions?: Apollo.MutationHookOptions<SendRequest, SendRequestVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<SendRequest, SendRequestVariables>(SendRequestDocument, options);
}
export type SendRequestHookResult = ReturnType<typeof useSendRequest>;
export type SendRequestMutationResult = Apollo.MutationResult<SendRequest>;
export type SendRequestMutationOptions = Apollo.BaseMutationOptions<
  SendRequest,
  SendRequestVariables
>;
export const StartFlowDocument = gql`
  mutation startFlow($flowId: String!, $requestId: String, $redirectUrl: String) {
    startFlow(flowId: $flowId, requestId: $requestId, redirectUrl: $redirectUrl) {
      ...FlowDataFragment
      actions {
        ...ActionDataFragment
        ... on FlowData {
          ...FlowDataFragment
          actions {
            ...ActionDataFragment
            ... on FlowData {
              ...FlowDataFragment
              actions {
                ...ActionDataFragment
              }
            }
          }
        }
      }
    }
  }
  ${FlowDataFragment}
  ${ActionDataFragment}
`;
export type StartFlowMutationFn = Apollo.MutationFunction<StartFlow, StartFlowVariables>;

/**
 * __useStartFlow__
 *
 * To run a mutation, you first call `useStartFlow` within a React component and pass it any options that fit your needs.
 * When your component renders, `useStartFlow` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [startFlow, { data, loading, error }] = useStartFlow({
 *   variables: {
 *      flowId: // value for 'flowId'
 *      requestId: // value for 'requestId'
 *      redirectUrl: // value for 'redirectUrl'
 *   },
 * });
 */
export function useStartFlow(
  baseOptions?: Apollo.MutationHookOptions<StartFlow, StartFlowVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<StartFlow, StartFlowVariables>(StartFlowDocument, options);
}
export type StartFlowHookResult = ReturnType<typeof useStartFlow>;
export type StartFlowMutationResult = Apollo.MutationResult<StartFlow>;
export type StartFlowMutationOptions = Apollo.BaseMutationOptions<StartFlow, StartFlowVariables>;
export const UninstallPartnerAppDocument = gql`
  mutation uninstallPartnerApp($partnerId: String!, $requestId: String) {
    uninstallPartnerApp(partnerId: $partnerId, requestId: $requestId) {
      ... on UninstallPartnerAppBaseResponse {
        result
      }
      ... on PartnerBaseError {
        message
      }
    }
  }
`;
export type UninstallPartnerAppMutationFn = Apollo.MutationFunction<
  UninstallPartnerApp,
  UninstallPartnerAppVariables
>;

/**
 * __useUninstallPartnerApp__
 *
 * To run a mutation, you first call `useUninstallPartnerApp` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUninstallPartnerApp` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [uninstallPartnerApp, { data, loading, error }] = useUninstallPartnerApp({
 *   variables: {
 *      partnerId: // value for 'partnerId'
 *      requestId: // value for 'requestId'
 *   },
 * });
 */
export function useUninstallPartnerApp(
  baseOptions?: Apollo.MutationHookOptions<UninstallPartnerApp, UninstallPartnerAppVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UninstallPartnerApp, UninstallPartnerAppVariables>(
    UninstallPartnerAppDocument,
    options,
  );
}
export type UninstallPartnerAppHookResult = ReturnType<typeof useUninstallPartnerApp>;
export type UninstallPartnerAppMutationResult = Apollo.MutationResult<UninstallPartnerApp>;
export type UninstallPartnerAppMutationOptions = Apollo.BaseMutationOptions<
  UninstallPartnerApp,
  UninstallPartnerAppVariables
>;
export const UpdateActionStateDocument = gql`
  mutation updateActionState(
    $actionId: String!
    $flowId: String!
    $state: ActionState!
    $requestId: String
  ) {
    updateActionState(actionId: $actionId, flowId: $flowId, state: $state, requestId: $requestId) {
      result
      flowData {
        ...FlowDataFragment
        actions {
          ...ActionDataFragment
          ... on FlowData {
            ...FlowDataFragment
            actions {
              ...ActionDataFragment
              ... on FlowData {
                ...FlowDataFragment
                actions {
                  ...ActionDataFragment
                }
              }
            }
          }
        }
      }
    }
  }
  ${FlowDataFragment}
  ${ActionDataFragment}
`;
export type UpdateActionStateMutationFn = Apollo.MutationFunction<
  UpdateActionState,
  UpdateActionStateVariables
>;

/**
 * __useUpdateActionState__
 *
 * To run a mutation, you first call `useUpdateActionState` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateActionState` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateActionState, { data, loading, error }] = useUpdateActionState({
 *   variables: {
 *      actionId: // value for 'actionId'
 *      flowId: // value for 'flowId'
 *      state: // value for 'state'
 *      requestId: // value for 'requestId'
 *   },
 * });
 */
export function useUpdateActionState(
  baseOptions?: Apollo.MutationHookOptions<UpdateActionState, UpdateActionStateVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateActionState, UpdateActionStateVariables>(
    UpdateActionStateDocument,
    options,
  );
}
export type UpdateActionStateHookResult = ReturnType<typeof useUpdateActionState>;
export type UpdateActionStateMutationResult = Apollo.MutationResult<UpdateActionState>;
export type UpdateActionStateMutationOptions = Apollo.BaseMutationOptions<
  UpdateActionState,
  UpdateActionStateVariables
>;
export const UpdateAdministrationDocument = gql`
  mutation updateAdministration($updateAdministrationArgs: UpdateAdministrationArgs!) {
    updateAdministration(administration: $updateAdministrationArgs) {
      id
      deleted
      kvkNumber
      name
      newDocumentCount
      scanEmail
      sendUbl
    }
  }
`;
export type UpdateAdministrationMutationFn = Apollo.MutationFunction<
  UpdateAdministration,
  UpdateAdministrationVariables
>;

/**
 * __useUpdateAdministration__
 *
 * To run a mutation, you first call `useUpdateAdministration` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAdministration` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAdministration, { data, loading, error }] = useUpdateAdministration({
 *   variables: {
 *      updateAdministrationArgs: // value for 'updateAdministrationArgs'
 *   },
 * });
 */
export function useUpdateAdministration(
  baseOptions?: Apollo.MutationHookOptions<UpdateAdministration, UpdateAdministrationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateAdministration, UpdateAdministrationVariables>(
    UpdateAdministrationDocument,
    options,
  );
}
export type UpdateAdministrationHookResult = ReturnType<typeof useUpdateAdministration>;
export type UpdateAdministrationMutationResult = Apollo.MutationResult<UpdateAdministration>;
export type UpdateAdministrationMutationOptions = Apollo.BaseMutationOptions<
  UpdateAdministration,
  UpdateAdministrationVariables
>;
export const UpdateAdministrationsDocument = gql`
  mutation updateAdministrations($updateAdministrationsArgs: [UpdateAdministrationArgs!]) {
    updateAdministrations(administrations: $updateAdministrationsArgs) {
      id
      deleted
      kvkNumber
      name
      newDocumentCount
      scanEmail
      sendUbl
    }
  }
`;
export type UpdateAdministrationsMutationFn = Apollo.MutationFunction<
  UpdateAdministrations,
  UpdateAdministrationsVariables
>;

/**
 * __useUpdateAdministrations__
 *
 * To run a mutation, you first call `useUpdateAdministrations` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAdministrations` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAdministrations, { data, loading, error }] = useUpdateAdministrations({
 *   variables: {
 *      updateAdministrationsArgs: // value for 'updateAdministrationsArgs'
 *   },
 * });
 */
export function useUpdateAdministrations(
  baseOptions?: Apollo.MutationHookOptions<UpdateAdministrations, UpdateAdministrationsVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateAdministrations, UpdateAdministrationsVariables>(
    UpdateAdministrationsDocument,
    options,
  );
}
export type UpdateAdministrationsHookResult = ReturnType<typeof useUpdateAdministrations>;
export type UpdateAdministrationsMutationResult = Apollo.MutationResult<UpdateAdministrations>;
export type UpdateAdministrationsMutationOptions = Apollo.BaseMutationOptions<
  UpdateAdministrations,
  UpdateAdministrationsVariables
>;
export const UpdateRequestDocument = gql`
  mutation updateRequest(
    $requestId: String!
    $status: RequestStatus!
    $reminderDateTime: AWSTimestamp
  ) {
    updateRequest(requestId: $requestId, status: $status, reminderDateTime: $reminderDateTime) {
      result
      error {
        key
        message
      }
    }
  }
`;
export type UpdateRequestMutationFn = Apollo.MutationFunction<
  UpdateRequest,
  UpdateRequestVariables
>;

/**
 * __useUpdateRequest__
 *
 * To run a mutation, you first call `useUpdateRequest` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateRequest` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateRequest, { data, loading, error }] = useUpdateRequest({
 *   variables: {
 *      requestId: // value for 'requestId'
 *      status: // value for 'status'
 *      reminderDateTime: // value for 'reminderDateTime'
 *   },
 * });
 */
export function useUpdateRequest(
  baseOptions?: Apollo.MutationHookOptions<UpdateRequest, UpdateRequestVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateRequest, UpdateRequestVariables>(UpdateRequestDocument, options);
}
export type UpdateRequestHookResult = ReturnType<typeof useUpdateRequest>;
export type UpdateRequestMutationResult = Apollo.MutationResult<UpdateRequest>;
export type UpdateRequestMutationOptions = Apollo.BaseMutationOptions<
  UpdateRequest,
  UpdateRequestVariables
>;
export const UpdateUserDocument = gql`
  mutation updateUser($updateUserArgs: UpdateUserArgs!) {
    updateUser(updateUserArgs: $updateUserArgs) {
      ...GetUserFragment
    }
  }
  ${GetUserFragment}
`;
export type UpdateUserMutationFn = Apollo.MutationFunction<UpdateUser, UpdateUserVariables>;

/**
 * __useUpdateUser__
 *
 * To run a mutation, you first call `useUpdateUser` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUser` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUser, { data, loading, error }] = useUpdateUser({
 *   variables: {
 *      updateUserArgs: // value for 'updateUserArgs'
 *   },
 * });
 */
export function useUpdateUser(
  baseOptions?: Apollo.MutationHookOptions<UpdateUser, UpdateUserVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateUser, UpdateUserVariables>(UpdateUserDocument, options);
}
export type UpdateUserHookResult = ReturnType<typeof useUpdateUser>;
export type UpdateUserMutationResult = Apollo.MutationResult<UpdateUser>;
export type UpdateUserMutationOptions = Apollo.BaseMutationOptions<UpdateUser, UpdateUserVariables>;
export const AuthorizeAdminEmbeddedFlowsTesterDocument = gql`
  query authorizeAdminEmbeddedFlowsTester {
    authorizeAdminEmbeddedFlowsTester
  }
`;

/**
 * __useAuthorizeAdminEmbeddedFlowsTester__
 *
 * To run a query within a React component, call `useAuthorizeAdminEmbeddedFlowsTester` and pass it any options that fit your needs.
 * When your component renders, `useAuthorizeAdminEmbeddedFlowsTester` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAuthorizeAdminEmbeddedFlowsTester({
 *   variables: {
 *   },
 * });
 */
export function useAuthorizeAdminEmbeddedFlowsTester(
  baseOptions?: Apollo.QueryHookOptions<
    AuthorizeAdminEmbeddedFlowsTester,
    AuthorizeAdminEmbeddedFlowsTesterVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    AuthorizeAdminEmbeddedFlowsTester,
    AuthorizeAdminEmbeddedFlowsTesterVariables
  >(AuthorizeAdminEmbeddedFlowsTesterDocument, options);
}
export function useAuthorizeAdminEmbeddedFlowsTesterLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AuthorizeAdminEmbeddedFlowsTester,
    AuthorizeAdminEmbeddedFlowsTesterVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    AuthorizeAdminEmbeddedFlowsTester,
    AuthorizeAdminEmbeddedFlowsTesterVariables
  >(AuthorizeAdminEmbeddedFlowsTesterDocument, options);
}
export function useAuthorizeAdminEmbeddedFlowsTesterSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    AuthorizeAdminEmbeddedFlowsTester,
    AuthorizeAdminEmbeddedFlowsTesterVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    AuthorizeAdminEmbeddedFlowsTester,
    AuthorizeAdminEmbeddedFlowsTesterVariables
  >(AuthorizeAdminEmbeddedFlowsTesterDocument, options);
}
export type AuthorizeAdminEmbeddedFlowsTesterHookResult = ReturnType<
  typeof useAuthorizeAdminEmbeddedFlowsTester
>;
export type AuthorizeAdminEmbeddedFlowsTesterLazyQueryHookResult = ReturnType<
  typeof useAuthorizeAdminEmbeddedFlowsTesterLazyQuery
>;
export type AuthorizeAdminEmbeddedFlowsTesterSuspenseQueryHookResult = ReturnType<
  typeof useAuthorizeAdminEmbeddedFlowsTesterSuspenseQuery
>;
export type AuthorizeAdminEmbeddedFlowsTesterQueryResult = Apollo.QueryResult<
  AuthorizeAdminEmbeddedFlowsTester,
  AuthorizeAdminEmbeddedFlowsTesterVariables
>;
export const InitiateHixAccountConnectionDocument = gql`
  query initiateHixAccountConnection($setupToken: String!, $clientDomain: String!) {
    initiateHixAccountConnection(setupToken: $setupToken, clientDomain: $clientDomain) {
      isInitiationSuccessful
    }
  }
`;

/**
 * __useInitiateHixAccountConnection__
 *
 * To run a query within a React component, call `useInitiateHixAccountConnection` and pass it any options that fit your needs.
 * When your component renders, `useInitiateHixAccountConnection` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInitiateHixAccountConnection({
 *   variables: {
 *      setupToken: // value for 'setupToken'
 *      clientDomain: // value for 'clientDomain'
 *   },
 * });
 */
export function useInitiateHixAccountConnection(
  baseOptions: Apollo.QueryHookOptions<
    InitiateHixAccountConnection,
    InitiateHixAccountConnectionVariables
  > &
    ({ variables: InitiateHixAccountConnectionVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<InitiateHixAccountConnection, InitiateHixAccountConnectionVariables>(
    InitiateHixAccountConnectionDocument,
    options,
  );
}
export function useInitiateHixAccountConnectionLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    InitiateHixAccountConnection,
    InitiateHixAccountConnectionVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<InitiateHixAccountConnection, InitiateHixAccountConnectionVariables>(
    InitiateHixAccountConnectionDocument,
    options,
  );
}
export function useInitiateHixAccountConnectionSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    InitiateHixAccountConnection,
    InitiateHixAccountConnectionVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    InitiateHixAccountConnection,
    InitiateHixAccountConnectionVariables
  >(InitiateHixAccountConnectionDocument, options);
}
export type InitiateHixAccountConnectionHookResult = ReturnType<
  typeof useInitiateHixAccountConnection
>;
export type InitiateHixAccountConnectionLazyQueryHookResult = ReturnType<
  typeof useInitiateHixAccountConnectionLazyQuery
>;
export type InitiateHixAccountConnectionSuspenseQueryHookResult = ReturnType<
  typeof useInitiateHixAccountConnectionSuspenseQuery
>;
export type InitiateHixAccountConnectionQueryResult = Apollo.QueryResult<
  InitiateHixAccountConnection,
  InitiateHixAccountConnectionVariables
>;
export const FinishConnectingHixAccountDocument = gql`
  query finishConnectingHixAccount($otp: String!) {
    finishConnectingHixAccount(otp: $otp) {
      isAccountConnected
      frontendUrl
    }
  }
`;

/**
 * __useFinishConnectingHixAccount__
 *
 * To run a query within a React component, call `useFinishConnectingHixAccount` and pass it any options that fit your needs.
 * When your component renders, `useFinishConnectingHixAccount` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFinishConnectingHixAccount({
 *   variables: {
 *      otp: // value for 'otp'
 *   },
 * });
 */
export function useFinishConnectingHixAccount(
  baseOptions: Apollo.QueryHookOptions<
    FinishConnectingHixAccount,
    FinishConnectingHixAccountVariables
  > &
    ({ variables: FinishConnectingHixAccountVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<FinishConnectingHixAccount, FinishConnectingHixAccountVariables>(
    FinishConnectingHixAccountDocument,
    options,
  );
}
export function useFinishConnectingHixAccountLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FinishConnectingHixAccount,
    FinishConnectingHixAccountVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<FinishConnectingHixAccount, FinishConnectingHixAccountVariables>(
    FinishConnectingHixAccountDocument,
    options,
  );
}
export function useFinishConnectingHixAccountSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    FinishConnectingHixAccount,
    FinishConnectingHixAccountVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<FinishConnectingHixAccount, FinishConnectingHixAccountVariables>(
    FinishConnectingHixAccountDocument,
    options,
  );
}
export type FinishConnectingHixAccountHookResult = ReturnType<typeof useFinishConnectingHixAccount>;
export type FinishConnectingHixAccountLazyQueryHookResult = ReturnType<
  typeof useFinishConnectingHixAccountLazyQuery
>;
export type FinishConnectingHixAccountSuspenseQueryHookResult = ReturnType<
  typeof useFinishConnectingHixAccountSuspenseQuery
>;
export type FinishConnectingHixAccountQueryResult = Apollo.QueryResult<
  FinishConnectingHixAccount,
  FinishConnectingHixAccountVariables
>;
export const GetActiveBetaProgramsDocument = gql`
  query getActiveBetaPrograms {
    getActiveBetaPrograms {
      betaProgramId
      title
    }
  }
`;

/**
 * __useGetActiveBetaPrograms__
 *
 * To run a query within a React component, call `useGetActiveBetaPrograms` and pass it any options that fit your needs.
 * When your component renders, `useGetActiveBetaPrograms` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetActiveBetaPrograms({
 *   variables: {
 *   },
 * });
 */
export function useGetActiveBetaPrograms(
  baseOptions?: Apollo.QueryHookOptions<GetActiveBetaPrograms, GetActiveBetaProgramsVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetActiveBetaPrograms, GetActiveBetaProgramsVariables>(
    GetActiveBetaProgramsDocument,
    options,
  );
}
export function useGetActiveBetaProgramsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetActiveBetaPrograms, GetActiveBetaProgramsVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetActiveBetaPrograms, GetActiveBetaProgramsVariables>(
    GetActiveBetaProgramsDocument,
    options,
  );
}
export function useGetActiveBetaProgramsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetActiveBetaPrograms,
    GetActiveBetaProgramsVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<GetActiveBetaPrograms, GetActiveBetaProgramsVariables>(
    GetActiveBetaProgramsDocument,
    options,
  );
}
export type GetActiveBetaProgramsHookResult = ReturnType<typeof useGetActiveBetaPrograms>;
export type GetActiveBetaProgramsLazyQueryHookResult = ReturnType<
  typeof useGetActiveBetaProgramsLazyQuery
>;
export type GetActiveBetaProgramsSuspenseQueryHookResult = ReturnType<
  typeof useGetActiveBetaProgramsSuspenseQuery
>;
export type GetActiveBetaProgramsQueryResult = Apollo.QueryResult<
  GetActiveBetaPrograms,
  GetActiveBetaProgramsVariables
>;
export const GetActiveFlowDocument = gql`
  query getActiveFlow($requestId: String) {
    getActiveFlow(requestId: $requestId) {
      ...FlowDataFragment
      actions {
        ...ActionDataFragment
        ... on FlowData {
          ...FlowDataFragment
          actions {
            ...ActionDataFragment
            ... on FlowData {
              ...FlowDataFragment
              actions {
                ...ActionDataFragment
              }
            }
          }
        }
      }
    }
  }
  ${FlowDataFragment}
  ${ActionDataFragment}
`;

/**
 * __useGetActiveFlow__
 *
 * To run a query within a React component, call `useGetActiveFlow` and pass it any options that fit your needs.
 * When your component renders, `useGetActiveFlow` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetActiveFlow({
 *   variables: {
 *      requestId: // value for 'requestId'
 *   },
 * });
 */
export function useGetActiveFlow(
  baseOptions?: Apollo.QueryHookOptions<GetActiveFlow, GetActiveFlowVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetActiveFlow, GetActiveFlowVariables>(GetActiveFlowDocument, options);
}
export function useGetActiveFlowLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetActiveFlow, GetActiveFlowVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetActiveFlow, GetActiveFlowVariables>(GetActiveFlowDocument, options);
}
export function useGetActiveFlowSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<GetActiveFlow, GetActiveFlowVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<GetActiveFlow, GetActiveFlowVariables>(
    GetActiveFlowDocument,
    options,
  );
}
export type GetActiveFlowHookResult = ReturnType<typeof useGetActiveFlow>;
export type GetActiveFlowLazyQueryHookResult = ReturnType<typeof useGetActiveFlowLazyQuery>;
export type GetActiveFlowSuspenseQueryHookResult = ReturnType<typeof useGetActiveFlowSuspenseQuery>;
export type GetActiveFlowQueryResult = Apollo.QueryResult<GetActiveFlow, GetActiveFlowVariables>;
export const GetAppsDocument = gql`
  query getApps($getAppsQueryArgs: GetAppsQueryArgs!) {
    getApps(getAppsQueryArgs: $getAppsQueryArgs) {
      ... on AppsResponse {
        apps {
          id
          name
          connectionStatus
          featuredOrder
          internalName
          description
          icon
          trustedScopes
          futureScopes
          urlConfiguration {
            url {
              connection
              configure
              exportTransactions
            }
            config {
              flow {
                default
                connectAccountingSystem
              }
            }
          }
        }
      }
      ... on GetAppsError {
        message
      }
    }
  }
`;

/**
 * __useGetApps__
 *
 * To run a query within a React component, call `useGetApps` and pass it any options that fit your needs.
 * When your component renders, `useGetApps` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetApps({
 *   variables: {
 *      getAppsQueryArgs: // value for 'getAppsQueryArgs'
 *   },
 * });
 */
export function useGetApps(
  baseOptions: Apollo.QueryHookOptions<GetApps, GetAppsVariables> &
    ({ variables: GetAppsVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetApps, GetAppsVariables>(GetAppsDocument, options);
}
export function useGetAppsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetApps, GetAppsVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetApps, GetAppsVariables>(GetAppsDocument, options);
}
export function useGetAppsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<GetApps, GetAppsVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<GetApps, GetAppsVariables>(GetAppsDocument, options);
}
export type GetAppsHookResult = ReturnType<typeof useGetApps>;
export type GetAppsLazyQueryHookResult = ReturnType<typeof useGetAppsLazyQuery>;
export type GetAppsSuspenseQueryHookResult = ReturnType<typeof useGetAppsSuspenseQuery>;
export type GetAppsQueryResult = Apollo.QueryResult<GetApps, GetAppsVariables>;
export const GetAuthConsentListDocument = gql`
  query getAuthConsentList($requestId: String) {
    getBankAccounts(requestId: $requestId) {
      id
      iban
      administrationId
      displayName
      bank
      aisEnabled
      isZombie
      validUntil
      companyAccount
    }
    listAdministrations(requestId: $requestId) {
      id
      deleted
      kvkNumber
      name
      newDocumentCount
      partners {
        id
      }
    }
  }
`;

/**
 * __useGetAuthConsentList__
 *
 * To run a query within a React component, call `useGetAuthConsentList` and pass it any options that fit your needs.
 * When your component renders, `useGetAuthConsentList` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAuthConsentList({
 *   variables: {
 *      requestId: // value for 'requestId'
 *   },
 * });
 */
export function useGetAuthConsentList(
  baseOptions?: Apollo.QueryHookOptions<GetAuthConsentList, GetAuthConsentListVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetAuthConsentList, GetAuthConsentListVariables>(
    GetAuthConsentListDocument,
    options,
  );
}
export function useGetAuthConsentListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetAuthConsentList, GetAuthConsentListVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetAuthConsentList, GetAuthConsentListVariables>(
    GetAuthConsentListDocument,
    options,
  );
}
export function useGetAuthConsentListSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<GetAuthConsentList, GetAuthConsentListVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<GetAuthConsentList, GetAuthConsentListVariables>(
    GetAuthConsentListDocument,
    options,
  );
}
export type GetAuthConsentListHookResult = ReturnType<typeof useGetAuthConsentList>;
export type GetAuthConsentListLazyQueryHookResult = ReturnType<
  typeof useGetAuthConsentListLazyQuery
>;
export type GetAuthConsentListSuspenseQueryHookResult = ReturnType<
  typeof useGetAuthConsentListSuspenseQuery
>;
export type GetAuthConsentListQueryResult = Apollo.QueryResult<
  GetAuthConsentList,
  GetAuthConsentListVariables
>;
export const GetBankAccountsDocument = gql`
  query getBankAccounts(
    $aisEnabled: Boolean
    $bank: [String]
    $administrationId: String
    $includeUnassigned: Boolean
  ) {
    getBankAccounts(
      aisEnabled: $aisEnabled
      bank: $bank
      administrationId: $administrationId
      includeUnassigned: $includeUnassigned
    ) {
      id
      iban
      administrationId
      displayName
      bank
      aisEnabled
      verifiedName
      isZombie
    }
  }
`;

/**
 * __useGetBankAccounts__
 *
 * To run a query within a React component, call `useGetBankAccounts` and pass it any options that fit your needs.
 * When your component renders, `useGetBankAccounts` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBankAccounts({
 *   variables: {
 *      aisEnabled: // value for 'aisEnabled'
 *      bank: // value for 'bank'
 *      administrationId: // value for 'administrationId'
 *      includeUnassigned: // value for 'includeUnassigned'
 *   },
 * });
 */
export function useGetBankAccounts(
  baseOptions?: Apollo.QueryHookOptions<GetBankAccounts, GetBankAccountsVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetBankAccounts, GetBankAccountsVariables>(
    GetBankAccountsDocument,
    options,
  );
}
export function useGetBankAccountsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetBankAccounts, GetBankAccountsVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetBankAccounts, GetBankAccountsVariables>(
    GetBankAccountsDocument,
    options,
  );
}
export function useGetBankAccountsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<GetBankAccounts, GetBankAccountsVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<GetBankAccounts, GetBankAccountsVariables>(
    GetBankAccountsDocument,
    options,
  );
}
export type GetBankAccountsHookResult = ReturnType<typeof useGetBankAccounts>;
export type GetBankAccountsLazyQueryHookResult = ReturnType<typeof useGetBankAccountsLazyQuery>;
export type GetBankAccountsSuspenseQueryHookResult = ReturnType<
  typeof useGetBankAccountsSuspenseQuery
>;
export type GetBankAccountsQueryResult = Apollo.QueryResult<
  GetBankAccounts,
  GetBankAccountsVariables
>;
export const GetBankConnectorsDetailsDocument = gql`
  query getBankConnectorsDetails($bankIds: [String!], $filters: GetBankConnectorsFilterArgs) {
    getBankConnectorsDetails(bankIds: $bankIds, filters: $filters) {
      bankId
      bankName
      icon
      order
      connectors {
        connectorId
        connectorName
        bankId
        connectorType
        country
        tags
        icon
        recommended
        paymentTypes
        meteringPlan
        descriptionKey
      }
    }
  }
`;

/**
 * __useGetBankConnectorsDetails__
 *
 * To run a query within a React component, call `useGetBankConnectorsDetails` and pass it any options that fit your needs.
 * When your component renders, `useGetBankConnectorsDetails` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBankConnectorsDetails({
 *   variables: {
 *      bankIds: // value for 'bankIds'
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useGetBankConnectorsDetails(
  baseOptions?: Apollo.QueryHookOptions<
    GetBankConnectorsDetails,
    GetBankConnectorsDetailsVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetBankConnectorsDetails, GetBankConnectorsDetailsVariables>(
    GetBankConnectorsDetailsDocument,
    options,
  );
}
export function useGetBankConnectorsDetailsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetBankConnectorsDetails,
    GetBankConnectorsDetailsVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetBankConnectorsDetails, GetBankConnectorsDetailsVariables>(
    GetBankConnectorsDetailsDocument,
    options,
  );
}
export function useGetBankConnectorsDetailsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetBankConnectorsDetails,
    GetBankConnectorsDetailsVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<GetBankConnectorsDetails, GetBankConnectorsDetailsVariables>(
    GetBankConnectorsDetailsDocument,
    options,
  );
}
export type GetBankConnectorsDetailsHookResult = ReturnType<typeof useGetBankConnectorsDetails>;
export type GetBankConnectorsDetailsLazyQueryHookResult = ReturnType<
  typeof useGetBankConnectorsDetailsLazyQuery
>;
export type GetBankConnectorsDetailsSuspenseQueryHookResult = ReturnType<
  typeof useGetBankConnectorsDetailsSuspenseQuery
>;
export type GetBankConnectorsDetailsQueryResult = Apollo.QueryResult<
  GetBankConnectorsDetails,
  GetBankConnectorsDetailsVariables
>;
export const GetBankTransactionsDocument = gql`
  query getBankTransactions($getBankTransactionsArgs: GetBankTransactionsArgs!) {
    getBankTransactions(getBankTransactionsArgs: $getBankTransactionsArgs) {
      ... on BankTransactionsResponse {
        bankAccount {
          id
          iban
          displayName
        }
        transactions {
          id
          iban
          name
          amount
          currency
          date
          description
          paymentId
          documentId
        }
      }
      ... on BankAccountAccessError {
        message
        code
      }
    }
  }
`;

/**
 * __useGetBankTransactions__
 *
 * To run a query within a React component, call `useGetBankTransactions` and pass it any options that fit your needs.
 * When your component renders, `useGetBankTransactions` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBankTransactions({
 *   variables: {
 *      getBankTransactionsArgs: // value for 'getBankTransactionsArgs'
 *   },
 * });
 */
export function useGetBankTransactions(
  baseOptions: Apollo.QueryHookOptions<GetBankTransactions, GetBankTransactionsVariables> &
    ({ variables: GetBankTransactionsVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetBankTransactions, GetBankTransactionsVariables>(
    GetBankTransactionsDocument,
    options,
  );
}
export function useGetBankTransactionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetBankTransactions, GetBankTransactionsVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetBankTransactions, GetBankTransactionsVariables>(
    GetBankTransactionsDocument,
    options,
  );
}
export function useGetBankTransactionsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<GetBankTransactions, GetBankTransactionsVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<GetBankTransactions, GetBankTransactionsVariables>(
    GetBankTransactionsDocument,
    options,
  );
}
export type GetBankTransactionsHookResult = ReturnType<typeof useGetBankTransactions>;
export type GetBankTransactionsLazyQueryHookResult = ReturnType<
  typeof useGetBankTransactionsLazyQuery
>;
export type GetBankTransactionsSuspenseQueryHookResult = ReturnType<
  typeof useGetBankTransactionsSuspenseQuery
>;
export type GetBankTransactionsQueryResult = Apollo.QueryResult<
  GetBankTransactions,
  GetBankTransactionsVariables
>;
export const GetRequestDocument = gql`
  query getRequest($requestId: String!) {
    getRequest(requestId: $requestId) {
      ... on RequestData {
        requestId
        status
        message
        channel
        from
        to
        actions
        periodicalType
        expirationDateTime
        connectionType
        createdAt
        reminderDateTimes
        partner {
          id
          name
          supportEmail
          icon
          relation
          trustTermsAndConditions
          appName
          theme {
            name
            color
            icon
          }
        }
        approver {
          id
          displayName
          email
        }
        requester {
          id
          displayName
          email
        }
        flow {
          id
        }
      }
      ... on GetRequestError {
        key
        message
      }
    }
  }
`;

/**
 * __useGetRequest__
 *
 * To run a query within a React component, call `useGetRequest` and pass it any options that fit your needs.
 * When your component renders, `useGetRequest` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRequest({
 *   variables: {
 *      requestId: // value for 'requestId'
 *   },
 * });
 */
export function useGetRequest(
  baseOptions: Apollo.QueryHookOptions<GetRequest, GetRequestVariables> &
    ({ variables: GetRequestVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetRequest, GetRequestVariables>(GetRequestDocument, options);
}
export function useGetRequestLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetRequest, GetRequestVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetRequest, GetRequestVariables>(GetRequestDocument, options);
}
export function useGetRequestSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<GetRequest, GetRequestVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<GetRequest, GetRequestVariables>(GetRequestDocument, options);
}
export type GetRequestHookResult = ReturnType<typeof useGetRequest>;
export type GetRequestLazyQueryHookResult = ReturnType<typeof useGetRequestLazyQuery>;
export type GetRequestSuspenseQueryHookResult = ReturnType<typeof useGetRequestSuspenseQuery>;
export type GetRequestQueryResult = Apollo.QueryResult<GetRequest, GetRequestVariables>;
export const GetFinancedInvoiceDocument = gql`
  query getFinancedInvoice($partnerId: String, $administrationId: String!, $invoiceId: String!) {
    getFinancedInvoice(
      partnerId: $partnerId
      administrationId: $administrationId
      invoiceId: $invoiceId
    ) {
      invoiceId
      administrationId
      partnerId
    }
  }
`;

/**
 * __useGetFinancedInvoice__
 *
 * To run a query within a React component, call `useGetFinancedInvoice` and pass it any options that fit your needs.
 * When your component renders, `useGetFinancedInvoice` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFinancedInvoice({
 *   variables: {
 *      partnerId: // value for 'partnerId'
 *      administrationId: // value for 'administrationId'
 *      invoiceId: // value for 'invoiceId'
 *   },
 * });
 */
export function useGetFinancedInvoice(
  baseOptions: Apollo.QueryHookOptions<GetFinancedInvoice, GetFinancedInvoiceVariables> &
    ({ variables: GetFinancedInvoiceVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetFinancedInvoice, GetFinancedInvoiceVariables>(
    GetFinancedInvoiceDocument,
    options,
  );
}
export function useGetFinancedInvoiceLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetFinancedInvoice, GetFinancedInvoiceVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetFinancedInvoice, GetFinancedInvoiceVariables>(
    GetFinancedInvoiceDocument,
    options,
  );
}
export function useGetFinancedInvoiceSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<GetFinancedInvoice, GetFinancedInvoiceVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<GetFinancedInvoice, GetFinancedInvoiceVariables>(
    GetFinancedInvoiceDocument,
    options,
  );
}
export type GetFinancedInvoiceHookResult = ReturnType<typeof useGetFinancedInvoice>;
export type GetFinancedInvoiceLazyQueryHookResult = ReturnType<
  typeof useGetFinancedInvoiceLazyQuery
>;
export type GetFinancedInvoiceSuspenseQueryHookResult = ReturnType<
  typeof useGetFinancedInvoiceSuspenseQuery
>;
export type GetFinancedInvoiceQueryResult = Apollo.QueryResult<
  GetFinancedInvoice,
  GetFinancedInvoiceVariables
>;
export const GetFlowDocument = gql`
  query getFlow($flowId: String!, $requestId: String) {
    getFlow(flowId: $flowId, requestId: $requestId) {
      ...FlowDataFragment
      actions {
        ...ActionDataFragment
        ... on FlowData {
          ...FlowDataFragment
          actions {
            ...ActionDataFragment
            ... on FlowData {
              ...FlowDataFragment
              actions {
                ...ActionDataFragment
              }
            }
          }
        }
      }
    }
  }
  ${FlowDataFragment}
  ${ActionDataFragment}
`;

/**
 * __useGetFlow__
 *
 * To run a query within a React component, call `useGetFlow` and pass it any options that fit your needs.
 * When your component renders, `useGetFlow` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFlow({
 *   variables: {
 *      flowId: // value for 'flowId'
 *      requestId: // value for 'requestId'
 *   },
 * });
 */
export function useGetFlow(
  baseOptions: Apollo.QueryHookOptions<GetFlow, GetFlowVariables> &
    ({ variables: GetFlowVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetFlow, GetFlowVariables>(GetFlowDocument, options);
}
export function useGetFlowLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetFlow, GetFlowVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetFlow, GetFlowVariables>(GetFlowDocument, options);
}
export function useGetFlowSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<GetFlow, GetFlowVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<GetFlow, GetFlowVariables>(GetFlowDocument, options);
}
export type GetFlowHookResult = ReturnType<typeof useGetFlow>;
export type GetFlowLazyQueryHookResult = ReturnType<typeof useGetFlowLazyQuery>;
export type GetFlowSuspenseQueryHookResult = ReturnType<typeof useGetFlowSuspenseQuery>;
export type GetFlowQueryResult = Apollo.QueryResult<GetFlow, GetFlowVariables>;
export const GetInvoiceDocument = gql`
  query getInvoice($invoiceId: String!, $administrationId: String!, $partnerId: String) {
    getInvoice(invoiceId: $invoiceId, administrationId: $administrationId, partnerId: $partnerId) {
      ... on Invoice {
        __typename
        id
        status
        name
        filename
        docType
        amount
        date
        dueDate
        invoiceNumber
        administrationId
        administration {
          id
          name
          kvkNumber
        }
        partner {
          partnerId
          name
          logo
        }
      }
      ... on InvoiceAccessError {
        __typename
        message
      }
      ... on AdministrationAccessError {
        __typename
        message
      }
    }
  }
`;

/**
 * __useGetInvoice__
 *
 * To run a query within a React component, call `useGetInvoice` and pass it any options that fit your needs.
 * When your component renders, `useGetInvoice` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetInvoice({
 *   variables: {
 *      invoiceId: // value for 'invoiceId'
 *      administrationId: // value for 'administrationId'
 *      partnerId: // value for 'partnerId'
 *   },
 * });
 */
export function useGetInvoice(
  baseOptions: Apollo.QueryHookOptions<GetInvoice, GetInvoiceVariables> &
    ({ variables: GetInvoiceVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetInvoice, GetInvoiceVariables>(GetInvoiceDocument, options);
}
export function useGetInvoiceLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetInvoice, GetInvoiceVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetInvoice, GetInvoiceVariables>(GetInvoiceDocument, options);
}
export function useGetInvoiceSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<GetInvoice, GetInvoiceVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<GetInvoice, GetInvoiceVariables>(GetInvoiceDocument, options);
}
export type GetInvoiceHookResult = ReturnType<typeof useGetInvoice>;
export type GetInvoiceLazyQueryHookResult = ReturnType<typeof useGetInvoiceLazyQuery>;
export type GetInvoiceSuspenseQueryHookResult = ReturnType<typeof useGetInvoiceSuspenseQuery>;
export type GetInvoiceQueryResult = Apollo.QueryResult<GetInvoice, GetInvoiceVariables>;
export const GetInvoiceDocumentDocument = gql`
  query getInvoiceDocument(
    $invoiceId: String!
    $administrationId: String!
    $partnerId: String
    $isNewFile: Boolean
  ) {
    getInvoiceDocument(
      invoiceId: $invoiceId
      administrationId: $administrationId
      isNewFile: $isNewFile
      partnerId: $partnerId
    ) {
      documentUrl
      filename
      mimeType
    }
  }
`;

/**
 * __useGetInvoiceDocument__
 *
 * To run a query within a React component, call `useGetInvoiceDocument` and pass it any options that fit your needs.
 * When your component renders, `useGetInvoiceDocument` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetInvoiceDocument({
 *   variables: {
 *      invoiceId: // value for 'invoiceId'
 *      administrationId: // value for 'administrationId'
 *      partnerId: // value for 'partnerId'
 *      isNewFile: // value for 'isNewFile'
 *   },
 * });
 */
export function useGetInvoiceDocument(
  baseOptions: Apollo.QueryHookOptions<GetInvoiceDocument, GetInvoiceDocumentVariables> &
    ({ variables: GetInvoiceDocumentVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetInvoiceDocument, GetInvoiceDocumentVariables>(
    GetInvoiceDocumentDocument,
    options,
  );
}
export function useGetInvoiceDocumentLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetInvoiceDocument, GetInvoiceDocumentVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetInvoiceDocument, GetInvoiceDocumentVariables>(
    GetInvoiceDocumentDocument,
    options,
  );
}
export function useGetInvoiceDocumentSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<GetInvoiceDocument, GetInvoiceDocumentVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<GetInvoiceDocument, GetInvoiceDocumentVariables>(
    GetInvoiceDocumentDocument,
    options,
  );
}
export type GetInvoiceDocumentHookResult = ReturnType<typeof useGetInvoiceDocument>;
export type GetInvoiceDocumentLazyQueryHookResult = ReturnType<
  typeof useGetInvoiceDocumentLazyQuery
>;
export type GetInvoiceDocumentSuspenseQueryHookResult = ReturnType<
  typeof useGetInvoiceDocumentSuspenseQuery
>;
export type GetInvoiceDocumentQueryResult = Apollo.QueryResult<
  GetInvoiceDocument,
  GetInvoiceDocumentVariables
>;
export const GetInvoiceFinanceStateDocument = gql`
  query getInvoiceFinanceState {
    getInvoiceFinanceState {
      firstName
      lastName
      emailAddress
      phoneNumber
      completedAmount
      createdAt
      updatedAt
    }
  }
`;

/**
 * __useGetInvoiceFinanceState__
 *
 * To run a query within a React component, call `useGetInvoiceFinanceState` and pass it any options that fit your needs.
 * When your component renders, `useGetInvoiceFinanceState` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetInvoiceFinanceState({
 *   variables: {
 *   },
 * });
 */
export function useGetInvoiceFinanceState(
  baseOptions?: Apollo.QueryHookOptions<GetInvoiceFinanceState, GetInvoiceFinanceStateVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetInvoiceFinanceState, GetInvoiceFinanceStateVariables>(
    GetInvoiceFinanceStateDocument,
    options,
  );
}
export function useGetInvoiceFinanceStateLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetInvoiceFinanceState,
    GetInvoiceFinanceStateVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetInvoiceFinanceState, GetInvoiceFinanceStateVariables>(
    GetInvoiceFinanceStateDocument,
    options,
  );
}
export function useGetInvoiceFinanceStateSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetInvoiceFinanceState,
    GetInvoiceFinanceStateVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<GetInvoiceFinanceState, GetInvoiceFinanceStateVariables>(
    GetInvoiceFinanceStateDocument,
    options,
  );
}
export type GetInvoiceFinanceStateHookResult = ReturnType<typeof useGetInvoiceFinanceState>;
export type GetInvoiceFinanceStateLazyQueryHookResult = ReturnType<
  typeof useGetInvoiceFinanceStateLazyQuery
>;
export type GetInvoiceFinanceStateSuspenseQueryHookResult = ReturnType<
  typeof useGetInvoiceFinanceStateSuspenseQuery
>;
export type GetInvoiceFinanceStateQueryResult = Apollo.QueryResult<
  GetInvoiceFinanceState,
  GetInvoiceFinanceStateVariables
>;
export const GetInvoiceFinanceTokenDocument = gql`
  query getInvoiceFinanceToken {
    getInvoiceFinanceToken {
      ... on InvoiceFinanceToken {
        __typename
        accessToken
        caseId
      }
      ... on RaboValidationError {
        __typename
        code
        errorCode
        message
        invalidFields {
          code
          field
          rejectedValue
        }
      }
    }
  }
`;

/**
 * __useGetInvoiceFinanceToken__
 *
 * To run a query within a React component, call `useGetInvoiceFinanceToken` and pass it any options that fit your needs.
 * When your component renders, `useGetInvoiceFinanceToken` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetInvoiceFinanceToken({
 *   variables: {
 *   },
 * });
 */
export function useGetInvoiceFinanceToken(
  baseOptions?: Apollo.QueryHookOptions<GetInvoiceFinanceToken, GetInvoiceFinanceTokenVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetInvoiceFinanceToken, GetInvoiceFinanceTokenVariables>(
    GetInvoiceFinanceTokenDocument,
    options,
  );
}
export function useGetInvoiceFinanceTokenLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetInvoiceFinanceToken,
    GetInvoiceFinanceTokenVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetInvoiceFinanceToken, GetInvoiceFinanceTokenVariables>(
    GetInvoiceFinanceTokenDocument,
    options,
  );
}
export function useGetInvoiceFinanceTokenSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetInvoiceFinanceToken,
    GetInvoiceFinanceTokenVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<GetInvoiceFinanceToken, GetInvoiceFinanceTokenVariables>(
    GetInvoiceFinanceTokenDocument,
    options,
  );
}
export type GetInvoiceFinanceTokenHookResult = ReturnType<typeof useGetInvoiceFinanceToken>;
export type GetInvoiceFinanceTokenLazyQueryHookResult = ReturnType<
  typeof useGetInvoiceFinanceTokenLazyQuery
>;
export type GetInvoiceFinanceTokenSuspenseQueryHookResult = ReturnType<
  typeof useGetInvoiceFinanceTokenSuspenseQuery
>;
export type GetInvoiceFinanceTokenQueryResult = Apollo.QueryResult<
  GetInvoiceFinanceToken,
  GetInvoiceFinanceTokenVariables
>;
export const GetPartnerDocument = gql`
  query GetPartner($partnerId: String, $brandingPartnerId: String) {
    getPartner(partnerId: $partnerId, brandingPartnerId: $brandingPartnerId) {
      partner {
        ... on NonExistingPartner {
          id
          isValid
        }
        ... on Partner {
          id
          name
          appName
          billingType
          appScopes
          trustedScopes
          futureScopes
          defaultAccountType
          supportEmail
          icon
          relation
          isValid
          urlConfiguration {
            url {
              configure
              connection
              exportTransactions
            }
            config {
              flow {
                default
                connectAccountingSystem
              }
            }
          }
          trustTermsAndConditions
          internalName
          theme {
            icon
            name
            color
          }
        }
      }
    }
  }
`;

/**
 * __useGetPartner__
 *
 * To run a query within a React component, call `useGetPartner` and pass it any options that fit your needs.
 * When your component renders, `useGetPartner` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPartner({
 *   variables: {
 *      partnerId: // value for 'partnerId'
 *      brandingPartnerId: // value for 'brandingPartnerId'
 *   },
 * });
 */
export function useGetPartner(
  baseOptions?: Apollo.QueryHookOptions<GetPartner, GetPartnerVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetPartner, GetPartnerVariables>(GetPartnerDocument, options);
}
export function useGetPartnerLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetPartner, GetPartnerVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetPartner, GetPartnerVariables>(GetPartnerDocument, options);
}
export function useGetPartnerSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<GetPartner, GetPartnerVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<GetPartner, GetPartnerVariables>(GetPartnerDocument, options);
}
export type GetPartnerHookResult = ReturnType<typeof useGetPartner>;
export type GetPartnerLazyQueryHookResult = ReturnType<typeof useGetPartnerLazyQuery>;
export type GetPartnerSuspenseQueryHookResult = ReturnType<typeof useGetPartnerSuspenseQuery>;
export type GetPartnerQueryResult = Apollo.QueryResult<GetPartner, GetPartnerVariables>;
export const GetPartnerAuthorizationRefreshTokenDocument = gql`
  query getPartnerAuthorizationRefreshToken($partnerId: String!) {
    getPartnerAuthorizationRefreshToken(partnerId: $partnerId) {
      ... on PartnerAuthorizationRefreshTokenBaseResponse {
        refreshToken
      }
      ... on PartnerBaseError {
        message
      }
    }
  }
`;

/**
 * __useGetPartnerAuthorizationRefreshToken__
 *
 * To run a query within a React component, call `useGetPartnerAuthorizationRefreshToken` and pass it any options that fit your needs.
 * When your component renders, `useGetPartnerAuthorizationRefreshToken` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPartnerAuthorizationRefreshToken({
 *   variables: {
 *      partnerId: // value for 'partnerId'
 *   },
 * });
 */
export function useGetPartnerAuthorizationRefreshToken(
  baseOptions: Apollo.QueryHookOptions<
    GetPartnerAuthorizationRefreshToken,
    GetPartnerAuthorizationRefreshTokenVariables
  > &
    (
      | { variables: GetPartnerAuthorizationRefreshTokenVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetPartnerAuthorizationRefreshToken,
    GetPartnerAuthorizationRefreshTokenVariables
  >(GetPartnerAuthorizationRefreshTokenDocument, options);
}
export function useGetPartnerAuthorizationRefreshTokenLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetPartnerAuthorizationRefreshToken,
    GetPartnerAuthorizationRefreshTokenVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetPartnerAuthorizationRefreshToken,
    GetPartnerAuthorizationRefreshTokenVariables
  >(GetPartnerAuthorizationRefreshTokenDocument, options);
}
export function useGetPartnerAuthorizationRefreshTokenSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetPartnerAuthorizationRefreshToken,
    GetPartnerAuthorizationRefreshTokenVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GetPartnerAuthorizationRefreshToken,
    GetPartnerAuthorizationRefreshTokenVariables
  >(GetPartnerAuthorizationRefreshTokenDocument, options);
}
export type GetPartnerAuthorizationRefreshTokenHookResult = ReturnType<
  typeof useGetPartnerAuthorizationRefreshToken
>;
export type GetPartnerAuthorizationRefreshTokenLazyQueryHookResult = ReturnType<
  typeof useGetPartnerAuthorizationRefreshTokenLazyQuery
>;
export type GetPartnerAuthorizationRefreshTokenSuspenseQueryHookResult = ReturnType<
  typeof useGetPartnerAuthorizationRefreshTokenSuspenseQuery
>;
export type GetPartnerAuthorizationRefreshTokenQueryResult = Apollo.QueryResult<
  GetPartnerAuthorizationRefreshToken,
  GetPartnerAuthorizationRefreshTokenVariables
>;
export const GetPaymentsDocument = gql`
  query getPayments($offset: Int!, $limit: Int!, $filter: GetPaymentsFilter) {
    getPayments(offset: $offset, limit: $limit, filter: $filter) {
      id
      status
    }
  }
`;

/**
 * __useGetPayments__
 *
 * To run a query within a React component, call `useGetPayments` and pass it any options that fit your needs.
 * When your component renders, `useGetPayments` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPayments({
 *   variables: {
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useGetPayments(
  baseOptions: Apollo.QueryHookOptions<GetPayments, GetPaymentsVariables> &
    ({ variables: GetPaymentsVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetPayments, GetPaymentsVariables>(GetPaymentsDocument, options);
}
export function useGetPaymentsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetPayments, GetPaymentsVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetPayments, GetPaymentsVariables>(GetPaymentsDocument, options);
}
export function useGetPaymentsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<GetPayments, GetPaymentsVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<GetPayments, GetPaymentsVariables>(GetPaymentsDocument, options);
}
export type GetPaymentsHookResult = ReturnType<typeof useGetPayments>;
export type GetPaymentsLazyQueryHookResult = ReturnType<typeof useGetPaymentsLazyQuery>;
export type GetPaymentsSuspenseQueryHookResult = ReturnType<typeof useGetPaymentsSuspenseQuery>;
export type GetPaymentsQueryResult = Apollo.QueryResult<GetPayments, GetPaymentsVariables>;
export const GetHixAccountsDocument = gql`
  query getHixAccounts($email: String!) {
    getHixAccounts(email: $email) {
      accounts {
        email
        name
        setupToken
        clientDomain
        error
      }
    }
  }
`;

/**
 * __useGetHixAccounts__
 *
 * To run a query within a React component, call `useGetHixAccounts` and pass it any options that fit your needs.
 * When your component renders, `useGetHixAccounts` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetHixAccounts({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useGetHixAccounts(
  baseOptions: Apollo.QueryHookOptions<GetHixAccounts, GetHixAccountsVariables> &
    ({ variables: GetHixAccountsVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetHixAccounts, GetHixAccountsVariables>(GetHixAccountsDocument, options);
}
export function useGetHixAccountsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetHixAccounts, GetHixAccountsVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetHixAccounts, GetHixAccountsVariables>(
    GetHixAccountsDocument,
    options,
  );
}
export function useGetHixAccountsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<GetHixAccounts, GetHixAccountsVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<GetHixAccounts, GetHixAccountsVariables>(
    GetHixAccountsDocument,
    options,
  );
}
export type GetHixAccountsHookResult = ReturnType<typeof useGetHixAccounts>;
export type GetHixAccountsLazyQueryHookResult = ReturnType<typeof useGetHixAccountsLazyQuery>;
export type GetHixAccountsSuspenseQueryHookResult = ReturnType<
  typeof useGetHixAccountsSuspenseQuery
>;
export type GetHixAccountsQueryResult = Apollo.QueryResult<GetHixAccounts, GetHixAccountsVariables>;
export const GetHixUserDocument = gql`
  query getHixUser {
    getHixUser {
      email
      isSmsSent
      isVerificationCodeSent
    }
  }
`;

/**
 * __useGetHixUser__
 *
 * To run a query within a React component, call `useGetHixUser` and pass it any options that fit your needs.
 * When your component renders, `useGetHixUser` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetHixUser({
 *   variables: {
 *   },
 * });
 */
export function useGetHixUser(
  baseOptions?: Apollo.QueryHookOptions<GetHixUser, GetHixUserVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetHixUser, GetHixUserVariables>(GetHixUserDocument, options);
}
export function useGetHixUserLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetHixUser, GetHixUserVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetHixUser, GetHixUserVariables>(GetHixUserDocument, options);
}
export function useGetHixUserSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<GetHixUser, GetHixUserVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<GetHixUser, GetHixUserVariables>(GetHixUserDocument, options);
}
export type GetHixUserHookResult = ReturnType<typeof useGetHixUser>;
export type GetHixUserLazyQueryHookResult = ReturnType<typeof useGetHixUserLazyQuery>;
export type GetHixUserSuspenseQueryHookResult = ReturnType<typeof useGetHixUserSuspenseQuery>;
export type GetHixUserQueryResult = Apollo.QueryResult<GetHixUser, GetHixUserVariables>;
export const GetHixUserStatusDocument = gql`
  query getHixUserStatus {
    getHixUserStatus {
      isAccountConnected
      isHixEmailVerified
      frontendUrl
    }
  }
`;

/**
 * __useGetHixUserStatus__
 *
 * To run a query within a React component, call `useGetHixUserStatus` and pass it any options that fit your needs.
 * When your component renders, `useGetHixUserStatus` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetHixUserStatus({
 *   variables: {
 *   },
 * });
 */
export function useGetHixUserStatus(
  baseOptions?: Apollo.QueryHookOptions<GetHixUserStatus, GetHixUserStatusVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetHixUserStatus, GetHixUserStatusVariables>(
    GetHixUserStatusDocument,
    options,
  );
}
export function useGetHixUserStatusLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetHixUserStatus, GetHixUserStatusVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetHixUserStatus, GetHixUserStatusVariables>(
    GetHixUserStatusDocument,
    options,
  );
}
export function useGetHixUserStatusSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<GetHixUserStatus, GetHixUserStatusVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<GetHixUserStatus, GetHixUserStatusVariables>(
    GetHixUserStatusDocument,
    options,
  );
}
export type GetHixUserStatusHookResult = ReturnType<typeof useGetHixUserStatus>;
export type GetHixUserStatusLazyQueryHookResult = ReturnType<typeof useGetHixUserStatusLazyQuery>;
export type GetHixUserStatusSuspenseQueryHookResult = ReturnType<
  typeof useGetHixUserStatusSuspenseQuery
>;
export type GetHixUserStatusQueryResult = Apollo.QueryResult<
  GetHixUserStatus,
  GetHixUserStatusVariables
>;
export const GetSingleBankAccountDocument = gql`
  query getSingleBankAccount(
    $id: String!
    $aisEnabled: Boolean
    $bank: [String]
    $administrationId: String
    $includeUnassigned: Boolean
  ) {
    getSingleBankAccount(
      id: $id
      aisEnabled: $aisEnabled
      bank: $bank
      administrationId: $administrationId
      includeUnassigned: $includeUnassigned
    ) {
      id
      iban
      administrationId
      displayName
      bank
      aisEnabled
      verifiedName
      balanceAmount
      lastBankUpdateAt
    }
  }
`;

/**
 * __useGetSingleBankAccount__
 *
 * To run a query within a React component, call `useGetSingleBankAccount` and pass it any options that fit your needs.
 * When your component renders, `useGetSingleBankAccount` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSingleBankAccount({
 *   variables: {
 *      id: // value for 'id'
 *      aisEnabled: // value for 'aisEnabled'
 *      bank: // value for 'bank'
 *      administrationId: // value for 'administrationId'
 *      includeUnassigned: // value for 'includeUnassigned'
 *   },
 * });
 */
export function useGetSingleBankAccount(
  baseOptions: Apollo.QueryHookOptions<GetSingleBankAccount, GetSingleBankAccountVariables> &
    ({ variables: GetSingleBankAccountVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetSingleBankAccount, GetSingleBankAccountVariables>(
    GetSingleBankAccountDocument,
    options,
  );
}
export function useGetSingleBankAccountLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetSingleBankAccount, GetSingleBankAccountVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetSingleBankAccount, GetSingleBankAccountVariables>(
    GetSingleBankAccountDocument,
    options,
  );
}
export function useGetSingleBankAccountSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetSingleBankAccount,
    GetSingleBankAccountVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<GetSingleBankAccount, GetSingleBankAccountVariables>(
    GetSingleBankAccountDocument,
    options,
  );
}
export type GetSingleBankAccountHookResult = ReturnType<typeof useGetSingleBankAccount>;
export type GetSingleBankAccountLazyQueryHookResult = ReturnType<
  typeof useGetSingleBankAccountLazyQuery
>;
export type GetSingleBankAccountSuspenseQueryHookResult = ReturnType<
  typeof useGetSingleBankAccountSuspenseQuery
>;
export type GetSingleBankAccountQueryResult = Apollo.QueryResult<
  GetSingleBankAccount,
  GetSingleBankAccountVariables
>;
export const GetUserDocument = gql`
  query getUser {
    getUser {
      ...GetUserFragment
    }
  }
  ${GetUserFragment}
`;

/**
 * __useGetUser__
 *
 * To run a query within a React component, call `useGetUser` and pass it any options that fit your needs.
 * When your component renders, `useGetUser` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUser({
 *   variables: {
 *   },
 * });
 */
export function useGetUser(baseOptions?: Apollo.QueryHookOptions<GetUser, GetUserVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetUser, GetUserVariables>(GetUserDocument, options);
}
export function useGetUserLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetUser, GetUserVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetUser, GetUserVariables>(GetUserDocument, options);
}
export function useGetUserSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<GetUser, GetUserVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<GetUser, GetUserVariables>(GetUserDocument, options);
}
export type GetUserHookResult = ReturnType<typeof useGetUser>;
export type GetUserLazyQueryHookResult = ReturnType<typeof useGetUserLazyQuery>;
export type GetUserSuspenseQueryHookResult = ReturnType<typeof useGetUserSuspenseQuery>;
export type GetUserQueryResult = Apollo.QueryResult<GetUser, GetUserVariables>;
export const ListUserAppsDocument = gql`
  query listUserApps {
    listUserApps {
      id
      name
      icon
      appUrl
    }
  }
`;

/**
 * __useListUserApps__
 *
 * To run a query within a React component, call `useListUserApps` and pass it any options that fit your needs.
 * When your component renders, `useListUserApps` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListUserApps({
 *   variables: {
 *   },
 * });
 */
export function useListUserApps(
  baseOptions?: Apollo.QueryHookOptions<ListUserApps, ListUserAppsVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ListUserApps, ListUserAppsVariables>(ListUserAppsDocument, options);
}
export function useListUserAppsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ListUserApps, ListUserAppsVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ListUserApps, ListUserAppsVariables>(ListUserAppsDocument, options);
}
export function useListUserAppsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<ListUserApps, ListUserAppsVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<ListUserApps, ListUserAppsVariables>(
    ListUserAppsDocument,
    options,
  );
}
export type ListUserAppsHookResult = ReturnType<typeof useListUserApps>;
export type ListUserAppsLazyQueryHookResult = ReturnType<typeof useListUserAppsLazyQuery>;
export type ListUserAppsSuspenseQueryHookResult = ReturnType<typeof useListUserAppsSuspenseQuery>;
export type ListUserAppsQueryResult = Apollo.QueryResult<ListUserApps, ListUserAppsVariables>;
export const GetUserSessionDocument = gql`
  query getUserSession {
    getUserSession {
      id
      lastUsedAt
      method
    }
  }
`;

/**
 * __useGetUserSession__
 *
 * To run a query within a React component, call `useGetUserSession` and pass it any options that fit your needs.
 * When your component renders, `useGetUserSession` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserSession({
 *   variables: {
 *   },
 * });
 */
export function useGetUserSession(
  baseOptions?: Apollo.QueryHookOptions<GetUserSession, GetUserSessionVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetUserSession, GetUserSessionVariables>(GetUserSessionDocument, options);
}
export function useGetUserSessionLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetUserSession, GetUserSessionVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetUserSession, GetUserSessionVariables>(
    GetUserSessionDocument,
    options,
  );
}
export function useGetUserSessionSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<GetUserSession, GetUserSessionVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<GetUserSession, GetUserSessionVariables>(
    GetUserSessionDocument,
    options,
  );
}
export type GetUserSessionHookResult = ReturnType<typeof useGetUserSession>;
export type GetUserSessionLazyQueryHookResult = ReturnType<typeof useGetUserSessionLazyQuery>;
export type GetUserSessionSuspenseQueryHookResult = ReturnType<
  typeof useGetUserSessionSuspenseQuery
>;
export type GetUserSessionQueryResult = Apollo.QueryResult<GetUserSession, GetUserSessionVariables>;
export const HasHixAccountDocument = gql`
  query hasHixAccount($email: String!) {
    hasHixAccount(email: $email) {
      hasValidAccount
    }
  }
`;

/**
 * __useHasHixAccount__
 *
 * To run a query within a React component, call `useHasHixAccount` and pass it any options that fit your needs.
 * When your component renders, `useHasHixAccount` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHasHixAccount({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useHasHixAccount(
  baseOptions: Apollo.QueryHookOptions<HasHixAccount, HasHixAccountVariables> &
    ({ variables: HasHixAccountVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<HasHixAccount, HasHixAccountVariables>(HasHixAccountDocument, options);
}
export function useHasHixAccountLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<HasHixAccount, HasHixAccountVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<HasHixAccount, HasHixAccountVariables>(HasHixAccountDocument, options);
}
export function useHasHixAccountSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<HasHixAccount, HasHixAccountVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<HasHixAccount, HasHixAccountVariables>(
    HasHixAccountDocument,
    options,
  );
}
export type HasHixAccountHookResult = ReturnType<typeof useHasHixAccount>;
export type HasHixAccountLazyQueryHookResult = ReturnType<typeof useHasHixAccountLazyQuery>;
export type HasHixAccountSuspenseQueryHookResult = ReturnType<typeof useHasHixAccountSuspenseQuery>;
export type HasHixAccountQueryResult = Apollo.QueryResult<HasHixAccount, HasHixAccountVariables>;
export const ListInvoicesDocument = gql`
  query listInvoices($limit: Int!, $offset: Int!, $order: OrderBy, $filter: ListInvoicesFilter) {
    listInvoices(limit: $limit, offset: $offset, orderBy: $order, filter: $filter) {
      id
      status
      name
      amount
      invoiceNumber
      date
      dueDate
      administrationId
      docType
      partner {
        logo
        name
        partnerId
      }
    }
  }
`;

/**
 * __useListInvoices__
 *
 * To run a query within a React component, call `useListInvoices` and pass it any options that fit your needs.
 * When your component renders, `useListInvoices` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListInvoices({
 *   variables: {
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      order: // value for 'order'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useListInvoices(
  baseOptions: Apollo.QueryHookOptions<ListInvoices, ListInvoicesVariables> &
    ({ variables: ListInvoicesVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ListInvoices, ListInvoicesVariables>(ListInvoicesDocument, options);
}
export function useListInvoicesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ListInvoices, ListInvoicesVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ListInvoices, ListInvoicesVariables>(ListInvoicesDocument, options);
}
export function useListInvoicesSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<ListInvoices, ListInvoicesVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<ListInvoices, ListInvoicesVariables>(
    ListInvoicesDocument,
    options,
  );
}
export type ListInvoicesHookResult = ReturnType<typeof useListInvoices>;
export type ListInvoicesLazyQueryHookResult = ReturnType<typeof useListInvoicesLazyQuery>;
export type ListInvoicesSuspenseQueryHookResult = ReturnType<typeof useListInvoicesSuspenseQuery>;
export type ListInvoicesQueryResult = Apollo.QueryResult<ListInvoices, ListInvoicesVariables>;
export const ListPartnerAdministrationsDocument = gql`
  query listPartnerAdministrations($partnerId: String!) {
    listPartnerAdministrations(partnerId: $partnerId) {
      id
      deleted
      kvkNumber
      name
      scanEmail
      sendUbl
      mapped
    }
  }
`;

/**
 * __useListPartnerAdministrations__
 *
 * To run a query within a React component, call `useListPartnerAdministrations` and pass it any options that fit your needs.
 * When your component renders, `useListPartnerAdministrations` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListPartnerAdministrations({
 *   variables: {
 *      partnerId: // value for 'partnerId'
 *   },
 * });
 */
export function useListPartnerAdministrations(
  baseOptions: Apollo.QueryHookOptions<
    ListPartnerAdministrations,
    ListPartnerAdministrationsVariables
  > &
    ({ variables: ListPartnerAdministrationsVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ListPartnerAdministrations, ListPartnerAdministrationsVariables>(
    ListPartnerAdministrationsDocument,
    options,
  );
}
export function useListPartnerAdministrationsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ListPartnerAdministrations,
    ListPartnerAdministrationsVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ListPartnerAdministrations, ListPartnerAdministrationsVariables>(
    ListPartnerAdministrationsDocument,
    options,
  );
}
export function useListPartnerAdministrationsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    ListPartnerAdministrations,
    ListPartnerAdministrationsVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<ListPartnerAdministrations, ListPartnerAdministrationsVariables>(
    ListPartnerAdministrationsDocument,
    options,
  );
}
export type ListPartnerAdministrationsHookResult = ReturnType<typeof useListPartnerAdministrations>;
export type ListPartnerAdministrationsLazyQueryHookResult = ReturnType<
  typeof useListPartnerAdministrationsLazyQuery
>;
export type ListPartnerAdministrationsSuspenseQueryHookResult = ReturnType<
  typeof useListPartnerAdministrationsSuspenseQuery
>;
export type ListPartnerAdministrationsQueryResult = Apollo.QueryResult<
  ListPartnerAdministrations,
  ListPartnerAdministrationsVariables
>;
export const ListPartnerInvoicesDocument = gql`
  query listPartnerInvoices(
    $order: OrderBy
    $limit: Int!
    $offset: Int!
    $filter: ListPartnerInvoicesFilter
  ) {
    listPartnerInvoices(orderBy: $order, limit: $limit, offset: $offset, filter: $filter) {
      id
      status
      name
      amount
      invoiceNumber
      date
      dueDate
      administrationId
      docType
      partner {
        logo
        name
        partnerId
      }
    }
  }
`;

/**
 * __useListPartnerInvoices__
 *
 * To run a query within a React component, call `useListPartnerInvoices` and pass it any options that fit your needs.
 * When your component renders, `useListPartnerInvoices` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListPartnerInvoices({
 *   variables: {
 *      order: // value for 'order'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useListPartnerInvoices(
  baseOptions: Apollo.QueryHookOptions<ListPartnerInvoices, ListPartnerInvoicesVariables> &
    ({ variables: ListPartnerInvoicesVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ListPartnerInvoices, ListPartnerInvoicesVariables>(
    ListPartnerInvoicesDocument,
    options,
  );
}
export function useListPartnerInvoicesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ListPartnerInvoices, ListPartnerInvoicesVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ListPartnerInvoices, ListPartnerInvoicesVariables>(
    ListPartnerInvoicesDocument,
    options,
  );
}
export function useListPartnerInvoicesSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<ListPartnerInvoices, ListPartnerInvoicesVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<ListPartnerInvoices, ListPartnerInvoicesVariables>(
    ListPartnerInvoicesDocument,
    options,
  );
}
export type ListPartnerInvoicesHookResult = ReturnType<typeof useListPartnerInvoices>;
export type ListPartnerInvoicesLazyQueryHookResult = ReturnType<
  typeof useListPartnerInvoicesLazyQuery
>;
export type ListPartnerInvoicesSuspenseQueryHookResult = ReturnType<
  typeof useListPartnerInvoicesSuspenseQuery
>;
export type ListPartnerInvoicesQueryResult = Apollo.QueryResult<
  ListPartnerInvoices,
  ListPartnerInvoicesVariables
>;
export const ListAdministrationsDocument = gql`
  query listAdministrations {
    listAdministrations {
      id
      deleted
      kvkNumber
      name
      scanEmail
      sendUbl
      newDocumentCount
      partners {
        id
      }
    }
  }
`;

/**
 * __useListAdministrations__
 *
 * To run a query within a React component, call `useListAdministrations` and pass it any options that fit your needs.
 * When your component renders, `useListAdministrations` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListAdministrations({
 *   variables: {
 *   },
 * });
 */
export function useListAdministrations(
  baseOptions?: Apollo.QueryHookOptions<ListAdministrations, ListAdministrationsVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ListAdministrations, ListAdministrationsVariables>(
    ListAdministrationsDocument,
    options,
  );
}
export function useListAdministrationsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ListAdministrations, ListAdministrationsVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ListAdministrations, ListAdministrationsVariables>(
    ListAdministrationsDocument,
    options,
  );
}
export function useListAdministrationsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<ListAdministrations, ListAdministrationsVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<ListAdministrations, ListAdministrationsVariables>(
    ListAdministrationsDocument,
    options,
  );
}
export type ListAdministrationsHookResult = ReturnType<typeof useListAdministrations>;
export type ListAdministrationsLazyQueryHookResult = ReturnType<
  typeof useListAdministrationsLazyQuery
>;
export type ListAdministrationsSuspenseQueryHookResult = ReturnType<
  typeof useListAdministrationsSuspenseQuery
>;
export type ListAdministrationsQueryResult = Apollo.QueryResult<
  ListAdministrations,
  ListAdministrationsVariables
>;
export const SearchCompaniesDocument = gql`
  query searchCompanies($search: String, $kvkNumber: String) {
    searchCompanies(tradeName: $search, kvkNumber: $kvkNumber) {
      items {
        kvkNumber
        branchNumber
        tradeName
        street
        houseNumber
        houseNumberAddition
        postalCode
        city
        type
        links {
          href
          rel
        }
      }
    }
  }
`;

/**
 * __useSearchCompanies__
 *
 * To run a query within a React component, call `useSearchCompanies` and pass it any options that fit your needs.
 * When your component renders, `useSearchCompanies` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchCompanies({
 *   variables: {
 *      search: // value for 'search'
 *      kvkNumber: // value for 'kvkNumber'
 *   },
 * });
 */
export function useSearchCompanies(
  baseOptions?: Apollo.QueryHookOptions<SearchCompanies, SearchCompaniesVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<SearchCompanies, SearchCompaniesVariables>(
    SearchCompaniesDocument,
    options,
  );
}
export function useSearchCompaniesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<SearchCompanies, SearchCompaniesVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<SearchCompanies, SearchCompaniesVariables>(
    SearchCompaniesDocument,
    options,
  );
}
export function useSearchCompaniesSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<SearchCompanies, SearchCompaniesVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<SearchCompanies, SearchCompaniesVariables>(
    SearchCompaniesDocument,
    options,
  );
}
export type SearchCompaniesHookResult = ReturnType<typeof useSearchCompanies>;
export type SearchCompaniesLazyQueryHookResult = ReturnType<typeof useSearchCompaniesLazyQuery>;
export type SearchCompaniesSuspenseQueryHookResult = ReturnType<
  typeof useSearchCompaniesSuspenseQuery
>;
export type SearchCompaniesQueryResult = Apollo.QueryResult<
  SearchCompanies,
  SearchCompaniesVariables
>;
export const SendHixAccountVerificationEmailDocument = gql`
  query sendHixAccountVerificationEmail($email: String!) {
    sendHixAccountVerificationEmail(email: $email) {
      isEmailSent
    }
  }
`;

/**
 * __useSendHixAccountVerificationEmail__
 *
 * To run a query within a React component, call `useSendHixAccountVerificationEmail` and pass it any options that fit your needs.
 * When your component renders, `useSendHixAccountVerificationEmail` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSendHixAccountVerificationEmail({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useSendHixAccountVerificationEmail(
  baseOptions: Apollo.QueryHookOptions<
    SendHixAccountVerificationEmail,
    SendHixAccountVerificationEmailVariables
  > &
    ({ variables: SendHixAccountVerificationEmailVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<SendHixAccountVerificationEmail, SendHixAccountVerificationEmailVariables>(
    SendHixAccountVerificationEmailDocument,
    options,
  );
}
export function useSendHixAccountVerificationEmailLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SendHixAccountVerificationEmail,
    SendHixAccountVerificationEmailVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    SendHixAccountVerificationEmail,
    SendHixAccountVerificationEmailVariables
  >(SendHixAccountVerificationEmailDocument, options);
}
export function useSendHixAccountVerificationEmailSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    SendHixAccountVerificationEmail,
    SendHixAccountVerificationEmailVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    SendHixAccountVerificationEmail,
    SendHixAccountVerificationEmailVariables
  >(SendHixAccountVerificationEmailDocument, options);
}
export type SendHixAccountVerificationEmailHookResult = ReturnType<
  typeof useSendHixAccountVerificationEmail
>;
export type SendHixAccountVerificationEmailLazyQueryHookResult = ReturnType<
  typeof useSendHixAccountVerificationEmailLazyQuery
>;
export type SendHixAccountVerificationEmailSuspenseQueryHookResult = ReturnType<
  typeof useSendHixAccountVerificationEmailSuspenseQuery
>;
export type SendHixAccountVerificationEmailQueryResult = Apollo.QueryResult<
  SendHixAccountVerificationEmail,
  SendHixAccountVerificationEmailVariables
>;
export const SendHixAccountVerificationSmsDocument = gql`
  query sendHixAccountVerificationSms {
    sendHixAccountVerificationSms {
      isSmsSent
    }
  }
`;

/**
 * __useSendHixAccountVerificationSms__
 *
 * To run a query within a React component, call `useSendHixAccountVerificationSms` and pass it any options that fit your needs.
 * When your component renders, `useSendHixAccountVerificationSms` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSendHixAccountVerificationSms({
 *   variables: {
 *   },
 * });
 */
export function useSendHixAccountVerificationSms(
  baseOptions?: Apollo.QueryHookOptions<
    SendHixAccountVerificationSms,
    SendHixAccountVerificationSmsVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<SendHixAccountVerificationSms, SendHixAccountVerificationSmsVariables>(
    SendHixAccountVerificationSmsDocument,
    options,
  );
}
export function useSendHixAccountVerificationSmsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SendHixAccountVerificationSms,
    SendHixAccountVerificationSmsVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<SendHixAccountVerificationSms, SendHixAccountVerificationSmsVariables>(
    SendHixAccountVerificationSmsDocument,
    options,
  );
}
export function useSendHixAccountVerificationSmsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    SendHixAccountVerificationSms,
    SendHixAccountVerificationSmsVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    SendHixAccountVerificationSms,
    SendHixAccountVerificationSmsVariables
  >(SendHixAccountVerificationSmsDocument, options);
}
export type SendHixAccountVerificationSmsHookResult = ReturnType<
  typeof useSendHixAccountVerificationSms
>;
export type SendHixAccountVerificationSmsLazyQueryHookResult = ReturnType<
  typeof useSendHixAccountVerificationSmsLazyQuery
>;
export type SendHixAccountVerificationSmsSuspenseQueryHookResult = ReturnType<
  typeof useSendHixAccountVerificationSmsSuspenseQuery
>;
export type SendHixAccountVerificationSmsQueryResult = Apollo.QueryResult<
  SendHixAccountVerificationSms,
  SendHixAccountVerificationSmsVariables
>;
export const VerifyHixEmailDocument = gql`
  query verifyHixEmail($verificationCode: String!) {
    verifyHixEmail(verificationCode: $verificationCode) {
      isEmailVerified
    }
  }
`;

/**
 * __useVerifyHixEmail__
 *
 * To run a query within a React component, call `useVerifyHixEmail` and pass it any options that fit your needs.
 * When your component renders, `useVerifyHixEmail` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVerifyHixEmail({
 *   variables: {
 *      verificationCode: // value for 'verificationCode'
 *   },
 * });
 */
export function useVerifyHixEmail(
  baseOptions: Apollo.QueryHookOptions<VerifyHixEmail, VerifyHixEmailVariables> &
    ({ variables: VerifyHixEmailVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<VerifyHixEmail, VerifyHixEmailVariables>(VerifyHixEmailDocument, options);
}
export function useVerifyHixEmailLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<VerifyHixEmail, VerifyHixEmailVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<VerifyHixEmail, VerifyHixEmailVariables>(
    VerifyHixEmailDocument,
    options,
  );
}
export function useVerifyHixEmailSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<VerifyHixEmail, VerifyHixEmailVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<VerifyHixEmail, VerifyHixEmailVariables>(
    VerifyHixEmailDocument,
    options,
  );
}
export type VerifyHixEmailHookResult = ReturnType<typeof useVerifyHixEmail>;
export type VerifyHixEmailLazyQueryHookResult = ReturnType<typeof useVerifyHixEmailLazyQuery>;
export type VerifyHixEmailSuspenseQueryHookResult = ReturnType<
  typeof useVerifyHixEmailSuspenseQuery
>;
export type VerifyHixEmailQueryResult = Apollo.QueryResult<VerifyHixEmail, VerifyHixEmailVariables>;
