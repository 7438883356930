import { useEffect, useState } from 'react';
import useOrchestratorFlowDemo from './useOrchestratorFlowDemo';
import TextInput from 'src/atoms/inputs/text-input/TextInput';
import { FlowLayout } from 'src/atoms/layout/menu-page';
import SkeletonEmbeddedFlowsTestPageLoader from 'src/atoms/loaders/skeleton-loader/SkeletonEmbeddedFlowsTestPageLoader';
import { useAuthorizeAdminEmbeddedFlowsTester } from 'src/types/bizcuitApi';
import CollapsibleContainer from './CollapsibleContainer';
import { useErrorDialog, useErrorTranslation } from 'src/hooks';
import createRedirectUrl from './api/createRedirectUrl';
import PrimaryButton from 'src/atoms/buttons/primary-button/PrimaryButton';
import CopyToClipboardButton from './CopyToClipboardButton';
import { filterJwtTokenMetadata, getJwtPayload } from 'src/utils';
import { FlowName, JwtPayload, SelectFlowOption } from './OrchestratorFlowDemo.types';
import SecondaryButton from 'src/atoms/buttons/secondary-button/SecondaryButton';
import { useNavigate } from 'react-router-dom';
import { Paths } from 'src/tokens';
import Select from 'src/atoms/inputs/select/Select';
import { RenewBankConsentFlows } from './MockData';

const RenewBankConsentDemo = () => {
  const navigate = useNavigate();
  const mainDemoPath = `${Paths.orchestrator.root}${Paths.orchestrator.segments.orchestratorFlowDemo}`;

  const [flowId, setFlowId] = useState<FlowName>('renew-bank-consent');
  const [iban, setIban] = useState('');
  const [redirectUrl, setRedirectUrl] = useState('');
  const [jwtExpiresSeconds, setJwtExpiresSeconds] = useState(0);
  const errorDialog = useErrorDialog();
  const errorTranslations = useErrorTranslation('somethingWentWrong');
  const { orchestratorFlowDemoState, setOrchestratorFlowDemoState } = useOrchestratorFlowDemo();
  const { partnerId, isBusy, userId } = orchestratorFlowDemoState;
  const {
    data: authorizeAdminEmbeddedFlowsTesterData,
    loading: authorizeAdminEmbeddedFlowsTesterLoading,
    error: authorizeAdminEmbeddedFlowsTesterError,
  } = useAuthorizeAdminEmbeddedFlowsTester({
    variables: {},
    skip: process.env.NODE_ENV === 'development',
  });
  const isFetchingSecurityRoles =
    process.env.NODE_ENV !== 'development' &&
    (authorizeAdminEmbeddedFlowsTesterLoading ||
      !authorizeAdminEmbeddedFlowsTesterData?.authorizeAdminEmbeddedFlowsTester);
  if (authorizeAdminEmbeddedFlowsTesterError) {
    errorDialog.open(errorTranslations);
  }
  const flowOptions: SelectFlowOption[] = Object.entries(RenewBankConsentFlows).map(([k, v]) => ({
    label: v,
    value: k as FlowName,
    selected: k === flowId,
  }));

  useEffect(() => {
    const interval = setInterval(() => {
      if (redirectUrl?.includes('?code=')) {
        const payload = getJwtPayload<JwtPayload>(redirectUrl.split('?code=')[1]);
        if (payload) {
          setJwtExpiresSeconds(payload.exp - Math.floor(Date.now() / 1000));
        }
      }
    }, 1000);
    return () => clearInterval(interval);
  }, [redirectUrl, setJwtExpiresSeconds]);

  const handleCreateRedirectUrl = async () => {
    if (!userId) {
      return errorDialog.open({
        ...errorTranslations,
        message: 'Invalid User ID',
      });
    }

    setOrchestratorFlowDemoState({
      isBusy: true,
    });

    const response = await createRedirectUrl({
      flowId,
      partnerId,
      userId,
      flowParams: {
        iban,
      },
    });
    const responseData = await response.json();
    if (response.status !== 200 || !responseData?.bizcuit_redirect_url) {
      errorDialog.open({
        ...errorTranslations,
        message: responseData?.error ?? 'Failed to create redirect url',
      });
      setOrchestratorFlowDemoState({
        isBusy: false,
      });
      return;
    }
    const url = new URL(responseData.bizcuit_redirect_url);
    url.hostname = window.location.hostname;
    setRedirectUrl(url.toString());
    setOrchestratorFlowDemoState({
      isBusy: false,
    });
  };

  const isRedirectUrlValid = redirectUrl && jwtExpiresSeconds > 0;

  return (
    <FlowLayout
      pageHeaderText={isFetchingSecurityRoles ? 'Loading...' : 'Bizcuit Demo'}
      showBackButton={false}
      spaceBetween
      showLanguagePicker={false}
    >
      {isFetchingSecurityRoles ? (
        <SkeletonEmbeddedFlowsTestPageLoader />
      ) : (
        <div style={{ padding: '20px', fontFamily: 'Arial, sans-serif' }}>
          <h1 className="heading-100 my-6 text-center text-warm-grey-800 dark:text-grey-100">
            Renew Bank Consent Demo
          </h1>
          <div className="flex flex-col gap-5 mb-5">
            <label>Select flow:</label>
            <Select
              key="select-flow"
              name="select-flow"
              label="Flow"
              placeHolder=""
              options={flowOptions}
              onChange={(value) => setFlowId(value as FlowName)}
            />
          </div>
          <div className="flex flex-col gap-5 mb-5">
            <label htmlFor="iban-input">Enter IBAN:</label>
            <TextInput
              value={iban}
              onChange={(value) => {
                setIban(value);
                setRedirectUrl('');
              }}
              placeHolder="NL99 BANK 0123 4567 89"
              label="IBAN"
              name="iban-input"
            />
          </div>
          <div className="flex flex-col items-center">
            <PrimaryButton
              className="w-full sm:w-[343px] mb-5"
              onClick={handleCreateRedirectUrl}
              isDisabled={isBusy || !isIbanValid(iban)}
              showSpinner={isBusy}
            >
              {isRedirectUrlValid ? 'Refresh Redirect Url' : 'Create Redirect Url'}
            </PrimaryButton>
            {isRedirectUrlValid && (
              <>
                <div className="flex flex-col items-center w-full my-5">
                  <CopyToClipboardButton
                    clipboardText={redirectUrl}
                    copyActionText="Copy URL to Clipboard"
                    copySuccessText="Copied!"
                    className="w-full sm:w-[343px]"
                    successDisplayTimeMs={2000}
                  />
                </div>
              </>
            )}
            {orchestratorFlowDemoState.userId && (
              <SecondaryButton underline onClick={() => navigate(mainDemoPath)}>
                Back to main demo
              </SecondaryButton>
            )}
          </div>
          <CollapsibleContainer title="Tech details">
            <div className="my-4">
              <p>Details</p>
              <div className="whitespace-pre font-mono text-xs">
                {JSON.stringify(
                  {
                    flowId,
                    userId,
                    partnerId,
                    ...(isIbanValid(iban) && {
                      params: { iban: formatIban(iban) },
                    }),
                  },
                  null,
                  2,
                )}
              </div>
            </div>
            {isRedirectUrlValid && (
              <div className="flex flex-col gap-4">
                <div>
                  <p>Bizcuit Redirect URL (expires in {jwtExpiresSeconds} seconds):</p>
                  <div
                    className="text-xs text-blue-600 dark:text-blue-400 cursor-pointer underline whitespace-pre-line break-all font-monospace"
                    onClick={() => window.open(redirectUrl, '_bizcuit')}
                  >
                    {redirectUrl}
                  </div>
                </div>
                <div>
                  <p>Payload:</p>
                  <div className="whitespace-pre font-mono text-xs">
                    {JSON.stringify(
                      filterJwtTokenMetadata(
                        getJwtPayload<JwtPayload>(redirectUrl.split('?code=')[1]),
                        ['iat', 'exp'],
                      ),
                      null,
                      '  ',
                    )}
                  </div>
                </div>
              </div>
            )}
          </CollapsibleContainer>
        </div>
      )}
    </FlowLayout>
  );
};

const ibanRegex = /^[A-Z]{2}\d{2} ?\w{4} ?\d{4} ?\d{4} ?\d{2,}$/;
const isIbanValid = (iban: string): boolean => ibanRegex.test(iban);

const formatIban = (iban: string): string =>
  iban
    .replace(/\s+/g, '')
    .replace(/(.{4})/g, '$1 ')
    .trim();

export default RenewBankConsentDemo;
